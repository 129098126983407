.login {
  background: #d7ecf8;
  min-height: 100vh;
}

.login-section {
  padding: 80px 0px;
}

.login-form {
  padding: 40px 37px 20px 37px;
  max-width: 486px;
  margin: 0px auto;
  background: #fff;
  border-radius: 20px;
  /* box-shadow: rgba(16, 25, 40, 0.08) 0px 4px 8px 0px; */
  box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
}

.head-logo img {
  max-width: 100px;
  margin: 0px auto;
  display: block;
  border: 2px solid#018bcf;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}

.login-form h1 {
  font-size: 27px;
  text-align: center;
  font-weight: 600;
  color: #00adff;
  margin-bottom: 0;
}

.login-form h1 span {
  color: #018bcf;
}

.login-box {
  padding-top: 45px;
  /* max-width: 400px;
  margin: 0px auto; */
}

.login-box .form-group .form-control {
  height: auto;
  padding: 7px 20px;
  border: 1px solid #dadada;
  border-radius: 10px;
  font-size: 12px !important;
  /* color: #a2a2a2; */
  color: #000;
  font-family: "AvenirLight";
}

.login-box .form-group .form-control:focus {
  box-shadow: none;
  background: transparent;
}

.login-box input:-internal-autofill-selected {
  background: transparent !important;
}

.form-label {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  font-size: 22px;
  color: #000;
  font-family: "AvenirHeavy";
  font-weight: inherit;
  text-transform: capitalize;
}

.form-group {
  margin-bottom: 35px;
}

.l-btn.btn.btn-primary {
  padding: 9px 40px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  border-radius: 10px;
  background: #0cbfff;
  background: -moz-linear-gradient(top, #0cbfff 0%, #627cff 100%);
  background: -webkit-linear-gradient(top, #0cbfff 0%, #627cff 100%);
  background: linear-gradient(to bottom, #0cbfff 0%, #627cff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0cbfff', endColorstr='#627cff', GradientType=0);
  border: none;
  /* transition: all 0.5s; */
  width: 100%;
  /* margin-top: 20px; */
}

.l-btn.btn.btn-primary:hover,
.l-btn.btn.btn-primary:focus,
.l-btn.btn.btn-primary:active {
  background: #627cff;
  background: -moz-linear-gradient(top, #627cff 0%, #0cbfff 100%);
  background: -webkit-linear-gradient(top, #627cff 0%, #0cbfff 100%);
  background: linear-gradient(to bottom, #627cff 0%, #0cbfff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#627cff', endColorstr='#0cbfff', GradientType=0);

  box-shadow: none;
  border: none;
}

.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: #627cff;
  background: -moz-linear-gradient(top, #627cff 0%, #0cbfff 100%) !important;
  background: -webkit-linear-gradient(top, #627cff 0%, #0cbfff 100%);
  background: linear-gradient(to bottom, #627cff 0%, #0cbfff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#627cff', endColorstr='#0cbfff', GradientType=0);
  color: #fff !important;
  border: none !important;
}

.login-button {
  text-align: center;
}

.login-box p {
  text-align: center;
  padding-top: 10px;
  font-size: 12px;
  color: #000;
  margin: 0;
  font-family: "AvenirLight";
}

.signup-link {
  color: #018bcf;
  text-decoration: underline;
}

.signup-link:hover {
  color: #c5c5c5;
}

.forget-password {
  text-align: right;
}

.forget-link {
  color: #018bcf;
  font-size: 13px;
  font-family: "AvenirMedium";
}

.forget-link:hover {
  color: #c5c5c5;
  text-decoration: none;
}

.forget-password {
  padding-bottom: 10px;
  margin-top: -10px;
}

.login-popup .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.password-show {
  position: relative;
}

.password-show i {
  position: absolute;
  right: 12px;
  bottom: 16px;
  top: 10px;
  cursor: pointer;
}

.login-popup .form-label {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}

.login-popup .modal-header .close:focus {
  outline: none;
}

.login-popup .close {
  color: #999;
  text-shadow: none;
  font-weight: 400;
  font-size: 30px;
}

.login-popup .close:hover {
  color: #777;
}

.modal-backdrop {
  background-color: #352929 !important;
}

.login-box .react-tel-input {
  width: 30%;
}

.login-box .react-tel-input .selected-flag .arrow {
  right: -11px;
}

input[type="email"] {
  text-transform: lowercase;
}

.login-box .form-group {
  margin-bottom: 19px;
}

.login-form .form-label {
  font-size: 15px;
  color: #000;
  font-weight: 900;
  margin-bottom: 1px;
  font-family: "AvenirMedium";
}
