footer {
  position: relative;
}

.light-layout-white {
  background-color: #ffffff;
}

.light-layout {
  background-color: #f9f9f9;
}

.border-section {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.small-section {
  padding-top: 35px;
  padding-bottom: 35px;
}

.border-top-0 {
  border-top: 0 !important;
}

.footer-light .subscribe {
  display: flex;
  height: 100%;
  align-items: center;
  border-right: 1px solid #ddd;
  text-align: left;
}

.subscribe h4 {
  color: #222;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
}

.footer-light .subscribe p {
  line-height: 1;
  letter-spacing: 0.03em;
  margin-bottom: 0;
  font-size: 14px;
  color: #bdbdbd;
}

.footer-light .subscribe-form {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}

.subscribe-form .form-group {
  margin-bottom: 0;
}

.subscribe-form .form-control {
  padding: 13px;
  width: 215px;
  border-radius: 0;
  border: 1px solid #eee;
  vertical-align: middle;
  display: inline-block;
}

.btn-solid {
  padding: 13px 29px;
  color: #fff !important;
  letter-spacing: 0.05em;
  border: 2px solid #f1454f;
  background-image: -webkit-linear-gradient(30deg, #f1454f 50%, transparent 0);
  background-image: -webkit-linear-gradient(60deg, #f1454f 50%, transparent 0);
  background-image: linear-gradient(30deg, #f1454f 50%, transparent 0);
  background-size: 540px;
  background-repeat: no-repeat;
  background-position: 0;
}

.section-b-space {
  padding-bottom: 20px;
  padding-top: 50px;
}

.footer-theme .footer-logo {
  margin-bottom: 18px;
}

footer p {
  line-height: 32px;
  letter-spacing: 0.03em;
  margin-bottom: 0;
  color: #bdbdbd;
}

.footer-social,
.social-white {
  padding-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo img {
  max-width: 180px;
}

.footer-social li,
.social-white li {
  display: inline-block;
  padding-right: 15px;
  padding-left: 15px;
}

.footer-social ul,
.social-white ul {
  padding-left: 0;
  margin-bottom: 0;
}

.footer-social i,
.social-white i {
  font-size: 20px;
  color: #bdbdbd;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.footer-theme .sub-title h4 {
  color: #222;
  margin-bottom: 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
}

.footer-theme .sub-title li,
.footer-theme .sub-title li a {
  position: relative;
  color: #bdbdbd;
}

.footer-theme .sub-title li {
  display: inherit;
  padding-top: 13px;
  text-transform: capitalize;
  font-size: 14px;
}

.footer-theme .sub-title li a:before {
  position: absolute;
  top: 20px;
  content: "";
  height: 2px;
  width: 0;
  background-color: #ff4c3b;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.footer-theme .sub-title .contact-list li {
  position: relative;
  padding-left: 25px;
  line-height: 20px;
}

.footer-theme .sub-title .contact-list i {
  top: 17px;
}

.footer-theme .sub-title .contact-list i {
  position: absolute;
  left: 0;
  top: 17px;
}

.sub-footer ul li a:hover {
  text-decoration: none;
  color: #018ccf;
}

.sub-footer p {
  color: #bdbdbd;
  margin-bottom: 0;
  padding: 10px 0;
  text-align: center;
  font-size: 14px;
}

.sub-footer .payment-card-bottom {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sub-footer .payment-card-bottom li {
  padding-left: 7px;
  padding-right: 7px;
  display: inline-block;
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer-theme .sub-title li a:hover {
  color: #018ccf;
}

.footer-social li a:hover i {
  color: #018ccf;
}

.sub-title {
  text-align: center;
}

.footer-title h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 25px;
  color: #fff;
}

.footer-contant p {
  line-height: 22px;
  margin-bottom: 0;
  color: #bdbdbd;
  font-size: 14px;
  max-width: 200px;
  margin: 0px auto;
}

ul.contact-list li a,
ul.contact-list li {
  font-size: 14px;
  color: #bdbdbd;
}

ul.contact-list li {
  padding-bottom: 3px;
}

.sub-footer ul {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-footer ul li {
  padding: 0px 15px;
}

.sub-footer ul li a {
  color: #bdbdbd;
  font-size: 14px;
}

.footer-dark {
  background: #131111;
}

.footer-nav-list {
  margin-bottom: 0;
}

.footer-box {
  position: relative;
  color: #969696;
  padding-top: 15px;
  font-size: 22px;
  font-family: "avenirromanopentype_bigfontsite_com";
}

.footer-box::before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #54c3fc;
}

.bg-color {
  background: #fff;
}

a.footer-nav-link {
  color: #969696;
  padding: 0 10px;
  cursor: pointer;
}

a.footer-nav-link:hover {
  text-decoration: none;
  color: #0098ff;
}

.footer-nav-item:last-child .footer-nav-link {
  padding-right: 0;
}

.footer-upper {
  padding: 35px 0;
  padding-bottom: 20px;
}

.footer-upper-right {
  width: 50%;
  display: grid;
  justify-content: flex-start;
  align-items: center;
}

.footer-upper-left {
  width: 50%;
}

.footer-upper-list {
  margin-top: 25px;
  margin-bottom: 0;
}

a.footer-upper-link {
  color: #000;
  font-size: 16px;
}

a.footer-upper-link:hover {
  text-decoration: none;
}

.footer {
  box-shadow: 0px 0px 20px #58bbd4;
  margin-top: 200px;
}

.footer-login {
  display: none;
}

ul.footer-upper-list li a {
  font-family: "AvenirMedium";
}

ul.footer-upper-list li {
  margin: 10px 0px;
}

.fur-box-img {
  position: absolute;
  top: 0%;
  transform: translate(0%, -55%);
  right: 0px;
}

.fur-box-left h2 {
  color: #00afff;
  font-size: 40px;
  font-family: "AvenirHeavy";
}

.store-box a img {
  height: 50px;
}

.footer-upper-content {
  border-bottom: 1px solid #4fb6ff;
  padding-bottom: 25px;
}

ul.footer-nav-list li a {
  font-size: 15px !important;
}

.copyright {
  font-size: 15px !important;
}
.login-view-body .footer-login {
  display: block;
}

.footer-login .bg-color {
  background: #d7ecf8;
}

.footer-login .footer-upper {
  padding-top: 0px !important;
  padding-bottom: 16px;
}

.social-icon {
  display: grid;
  position: fixed !important;
  right: 10px;
  transform: translate(-50%, -50%);
  top: 50%;
  text-align: center;
}
