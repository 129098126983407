.customer-list {
  position: relative;
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
}

.add-customer-page h4 {
  font-weight: 700;
  font-size: 17px;
  padding: 10px 0px;
  color: #8147dd;
  font-family: "AvenirBook";
}

/*************** Customer Profile ***************/

.customer-upper {
  position: relative;
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
}

.customer-bottom {
  position: relative;
  padding: 0px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
}

.custommer-info {
  width: 30%;
  padding: 20px;
  text-align: center;
}

.customer-pic {
  width: 120px;
  margin: 0px auto;
}

.customer-pic img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 100%;
}

.custommer-info h3 {
  font-weight: 700;
  font-size: 30px;
  padding-top: 15px;
  margin: 0;
}

.custommer-info span {
  display: block;
  padding-bottom: 20px;
  color: #999;
}

.custommer-info .appointment-btn {
  background-color: #262f3e;
  color: #fff;
  border-color: #262f3e;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
  max-width: 250px;
  width: 100%;
  border: 1px solid #262f3e;
  border-radius: 4px;
}

.custommer-info .appointment-btn:hover,
.custommer-info .appointment-btn:focus {
  border: 1px solid #262f3e;
  color: #fff;
  text-decoration: none;
}

.customer-activity {
  width: 70%;
  text-align: center;
  padding: 20px;
}

.activity-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.activity-box {
  width: 25%;
  padding: 15px 20px;
}

.activity-box h5 {
  font-weight: 700;
  margin: 0;
}

.activity-box span {
  color: #000;
}

.completed-badge {
  background: #262f3e;
  border-radius: 30px;
  color: #fff;
  font-size: 12px;
  padding: 3px 10px;
  min-width: 100px;
  text-align: center;
  display: inline-block;
}

.pending-badge {
  background: #ec152a;
  border-radius: 30px;
  color: #fff;
  font-size: 12px;
  padding: 3px 10px;
  min-width: 100px;
  text-align: center;
  display: inline-block;
}
.cancelled-badge {
  background: #ea370f;
  border-radius: 30px;
  color: #fff;
  font-size: 12px;
  padding: 3px 10px;
  min-width: 100px;
  text-align: center;
  display: inline-block;
}

.customer-appoint-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 0px;
}

.appoint-box-time {
  width: 150px;
  padding: 0px 20px;
}

.appoint-box-time span {
  display: block;
  color: #999;
  font-weight: 600;
  font-size: 12px;
}

.appoint-box-name {
  width: calc(100% - 250px);
  padding: 0px 20px;
}

.appoint-box-name h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  color: #333;
}

.appoint-box-name span {
  display: block;
  color: #000;
  font-size: 12px;
}

.appoint-box-price {
  width: 100px;
  text-align: center;
  padding: 0px 20px;
}

.appoint-box-price h6 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  color: #333;
}
.customer-profile .appoint-box-price {
  white-space: nowrap;
}
.customer-list .partner-table table thead th:last-child {
  width: 250px;
}
.customer-list .partner-table table td .btn-primary {
  margin: 0px 5px;
  border: 1px solid transparent;
  min-width: 86px;
}
.customer-list .partner-table .service-save .l-btn.btn.btn-default {
  border: 1px solid #ddd;
  color: #999;
  margin-right: 10px;
  padding: 0.375rem 0.75rem;
  min-width: 86px;
}

.add-customer-page .react-tel-input .form-control {
  width: 100px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none;
  padding-left: 35px;
}

.add-customer-page .number-fields {
  flex-wrap: wrap;
}

.add-customer-page .number-fields .react-tel-input {
  width: 100px;
}

.add-customer-page .number-fields input {
  width: calc(100% - 100px);
}

.add-customer-page .react-tel-input .selected-flag .arrow {
  right: -50px;
}
.add-customer-page .error {
  position: absolute;
}
.appointment-action.dropdown {
  position: absolute;
  right: 0;
  top: 0;
}

.appointment-action.dropdown .btn-primary {
  background: transparent;
  border-color: transparent;
  color: #000;
  font-size: 20px;
  font-weight: 700;
}

.appointment-action.dropdown .btn-primary::after {
  display: none;
}

.appointment-action.dropdown .btn-primary:hover,
.appointment-action.dropdown .btn-primary:focus {
  border: 1px solid transparent;
  box-shadow: none;
}

.appointment-action.show > .btn-primary.dropdown-toggle {
  background: transparent;
  border: 1px solid transparent !important;
  color: #000 !important;
  font-size: 20px;
  font-weight: 700;
}
.appointment-action .dropdown-item a,
.appointment-action .dropdown-item {
  font-size: 15px;
}
.appointment-action .dropdown-item a {
  display: block;
}
.partner-table {
  text-align: center;
}
.customer-list .partner-table > p {
  text-align: center;
  font-size: 20px;
  padding: 30px 0 0;
}
.customer-appointment p,
.customer-invoices .partner-table > p {
  text-align: center;
  width: 100%;
  font-size: 20px;
}
.customer-profile .tab-content {
  max-height: 400px;
  overflow: auto;
}
.customer-appointment h4 {
  font-size: 18px;
  font-weight: 600;
}
.customer-appointment span {
  font-size: 12px;
}

/* .customer-profile .appoint-box-name {
  text-align: center;
}.customer-profile .appoint-box-time,
.customer-profile .appoint-box-name,
.customer-profile .appoint-box-price {
  margin-bottom: 20px;
}

.customer-profile .appoint-box-time span {
  font-size: 16px;
} */
/* .customer-list .filter-right-block {
  width: 100%;
  margin-bottom: 10px;
} */
.customer-list-dropdown {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}
.customer-list-dropdown .filter-right-block .dropdown-toggle {
  padding: 5px 20px;
}
.customer-list-dropdown h2.admin-title {
  width: 30%;
  padding-bottom: 0;
}

.customer-list-dropdown .filter-right-block {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
  width: 70%;
}
.customer-list-dropdown .header-buttons {
  margin-right: 10px;
}
.customer-list-dropdown .dark-btn .dropdown-menu.show button,
.customer-list-dropdown .dark-btn .dropdown-menu.show a {
  font-size: 16px;
}
.customer-list-dropdown .add-voucher-btn {
  min-width: 132px;
}

.service-save .btn-primary {
  background: #262f3e;
  border-color: #262f3e;
}
.service-save .btn-primary:hover {
  background: #262f3e;
  border-color: #262f3e;
}
.partner-table table tbody td.service-save > a {
  margin-right: 15px;
}
.customer-list-dropdown .filter-right-block {
  margin-bottom: 30px;
}
.dark-btn .btn {
  font-weight: 700 !important;
  font-size: 14px;
}
