/* @import 'https://maxst.icons8.com/vue-static/landings/line-awesome/font-awesome-line-awesome/css/all.min.css'; */
/* @import "https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap"; */

.admin-view .header,
.admin-view .footer {
  display: none;
}

.admin-wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
  /* font-family: "Nunito", sans-serif !important; */
  font-family: "AvenirBook" !important;
}

.admin-view .main_content {
  padding: 0;
}

.header-view {
  text-align: center;
  position: relative;
  z-index: 99;
}

.header-top-box h1 {
  font-size: 30px;
  padding: 5px 0px;
}

.admin-content-view {
  display: flex;
  position: relative;
  min-height: 100vh;
}

/* .admin-menu {
  min-height: 100vh;
  height: 100vh;
  border-right: 1px solid#262f3e;
  width: 300px;
  background: #262f3e;
  transition: all 0.5s;
  position: fixed;
  left: -300px;
  top: 0;
  z-index: 999;
} */
.admin-menu {
  min-height: 100vh;
  border-right: 1px solid #000000;
  width: 75px;
  background: #000000;
  transition: all 0.5s;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

.admin-content {
  padding: 30px 20px;
}

.admin-container {
  padding-left: 75px;
  width: 100%;
  background: rgb(233, 233, 255);
  background: -moz-linear-gradient(
    top,
    rgba(233, 233, 255, 1) 0%,
    rgba(223, 241, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(233, 233, 255, 1) 0%,
    rgba(223, 241, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(233, 233, 255, 1) 0%,
    rgba(223, 241, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9e9ff', endColorstr='#dff1ff',GradientType=0 );

  transition: all 0.5s;
}
.admin-container {
  width: 100%;
  left: 75px;
  background: #f6f7fb;
  transition: all 0.5s;
}
.admin-menu.menu-hide {
  /* width: 0;
  opacity: 0; */
  /* left: -1px; */
}
.admin-content {
  width: 100%;
  height: 100%;
  background: rgb(233, 233, 255);
  background: -moz-linear-gradient(
    top,
    rgba(233, 233, 255, 1) 0%,
    rgba(223, 241, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(233, 233, 255, 1) 0%,
    rgba(223, 241, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(233, 233, 255, 1) 0%,
    rgba(223, 241, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9e9ff', endColorstr='#dff1ff',GradientType=0 );
  transition: all 0.5s;
}

.admin-menu.menu-hide {
  width: 0;
  opacity: 0;
}
/* 
.admin-container.wrapper-expand {
  width: 100%;
} */

.menu-list ul {
  margin: 0;
  padding: 0;
}

.menu-list ul a {
  padding: 18px 20px;
  display: block;
  color: #fff;
  text-decoration: none;
  transition: all 0.5s;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}
.admin-menu.menu-show .menu-list ul a {
  padding: 18px 20px 18px 53px;
}
.admin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  background: #fff;
  min-height: 60px;
  box-shadow: 0px 0px 15px #33333312;
}

.menu-logo {
  width: 250px;
  text-align: center;
  background: #000000;
  padding: 11px 10px;
  border-bottom: 1px solid #000000;
}

.menu-logo h2 {
  font-weight: 600;
  font-size: 25px;
  color: #fff;
  margin: 0;
}

.admin-info-img {
  width: 35px;
  height: 35px;
}
.admin-info img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
  margin-right: 0px;
  border: 2px solid #9553ff;
}

.admin-info {
  display: flex;
  align-items: center;
}

.menu-logo img {
  max-width: 140px;
  width: 100%;
}

.menu-toggle img {
  width: 32px;
  opacity: 0.9;
  transition: all 0.5s;
  cursor: pointer;
}

.menu-toggle img:hover {
  opacity: 1;
}

.menu-list {
  padding: 40px 0;
}

.menu-list ul a:hover {
  color: #fff;
  background: #8148dd;
}

.menu-list ul a.active {
  color: #fff;
}

.admin-info h3 {
  margin: 0;
  font-size: 14px;
  color: #212529;
  font-weight: 600;
}

.menu-list ul a i {
  /* margin-right: 10px; */
  /* font-size: 16px; */
  font-size: 22px;
  vertical-align: middle;
}

.dashboard .card {
  margin-bottom: 15px;
  margin-top: 15px;
  border-color: #edf2f9;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-radius: 25px;
}

.dashboard .h4 {
  color: #000;
}
.dashboard span.h4 {
  display: inline-block;
  font-size: 30px !important;
}
.dashboard .text-muted {
  color: #000 !important;
  font-size: 20px;
}

.menu-toggle a {
  color: #333;
  font-size: 20px;
}

.admin-title {
  /* margin: 0;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: #333; */
  margin: 0;
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-size: 30px;
  color: #8147dd;
  margin-bottom: 30px;
  font-family: "AvenirBlack";
}

.admin-title a {
  font-size: 22px;
  font-weight: 500;
  color: #8147dd;
  padding: 10px 12px;
}

.admin-title a:hover {
  text-decoration: none;
  color: #262f3e;
}

.font-weight-600 {
  font-weight: 600;
}

.dashboard .fa {
  font-size: 40px;
  opacity: 0.2;
}

.dashboard .card-body {
  cursor: pointer;
  padding: 30px 20px;
}

.dashboard .card-body .fa {
  transition: all 0.5s;
}

.dashboard .card-body:hover .fa {
  opacity: 1;
  color: #262f3e !important;
}

.user-list .table-responsive > .table-bordered .btn {
  background: #373373;
  border: none;
  padding: 5px 20px;
  font-size: 14px;
  min-width: 90px;
}

.user-list .table td,
.table th {
  padding: 15px 20px;
  vertical-align: middle;
}

.user-list .table td p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  margin: 0;
}

.user-list .table-responsive > .table-bordered {
  border: 0;
  background: #fff;
}

.user-list .table td {
  font-size: 14px;
}

.user-list .table td a {
  color: #478adc;
}

.action-buttons .btn {
  margin: 0px 5px;
}

.block-btn {
  background: #f1454f !important;
}

.approve-btn {
  background: #37a864 !important;
}

.edit-btn {
  background: #6d6d6d !important;
}

.admin-search {
  text-align: right;
  padding-bottom: 25px;
}

.admin-search .form-inline {
  justify-content: flex-end;
}

.admin-search .btn {
  background: #555555;
  border: none;
  padding: 7px 15px;
  font-size: 14px;
}

.admin-search .form-control {
  font-size: 13px;
  padding: 8px 10px;
  height: auto;
}

.admin-login {
  background: #f9f9f9;
  padding: 100px 0px;
  min-height: 100vh;
}

.admin-login-box {
  padding: 50px 40px;
  max-width: 500px;
  margin: 0px auto;
  background: #fff;
}

.admin-login-box .form-group .form-control {
  height: auto;
  padding: 10px 20px;
  background: transparent;
  border: 1px solid #dadada;
  border-radius: 4px;
  font-size: 14px;
}

.admin-login-box .form-group .form-control:focus {
  box-shadow: none;
  background: transparent;
}
.admin-login-box input:-internal-autofill-selected {
  background: transparent !important;
}

.admin-login-box h2 {
  font-size: 35px;
  text-align: center;
  font-weight: 600;
  color: #333;
  padding-bottom: 20px;
}

.admin-detail {
  display: flex;
  align-items: flex-start;
  padding: 20px 15px;
}

.admin-pic img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 3px solid #fff;
}

.admin-name span {
  font-size: 12px;
  color: #d0cdcd;
}

.admin-name h4 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-top: 5px;
}

.admin-name {
  padding-left: 20px;
  padding-top: 5px;
}

.menu-list ul h5 {
  font-size: 12px;
  text-transform: uppercase;
  padding: 0px 25px;
  margin-top: 20px;
  color: #fff;
  margin-bottom: 10px;
}

ul.sub-menu {
  background: #5f5f5f;
  display: none;
}

ul.sub-menu li a {
  font-size: 13px;
  padding-left: 50px;
}

ul.sub-menu li a i {
  margin-right: 2px;
}

.menu-list ul li {
  position: relative;
}

.menu-list ul > li.has-menu > a:after {
  content: "\f107";
  font-family: FontAwesome;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: all 0.5s;
}

ul.sub-menu.menu-open {
  display: block;
}

.menu-list ul > li.has-menu.menu-expand > a:after {
  transform: rotate(180deg);
}

.admin-info .dropdown-toggle {
  background: transparent;
  font-size: 12px;
  color: #333;
  border: none;
}

.admin-info .dropdown-toggle:hover,
.admin-info .btn-primary:not(:disabled):not(.disabled):active:focus,
.admin-info .dropdown-toggle:focus,
.admin-info .show > .btn-primary.dropdown-toggle:focus {
  background: transparent;
  font-size: 12px;
  color: #333;
  border: none;
  box-shadow: none;
}

.admin-info .btn-primary:not(:disabled):not(.disabled):active {
  background-color: transparent;
  border-color: transparent;
  color: #333;
}

.admin-info .show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
  color: #333;
}

.admin-info .dropdown-item {
  font-size: 13px;
  padding: 8px 20px;
  font-weight: 600;
  color: #333;
}

.admin-info .dropdown-menu {
  min-width: 6rem;
  left: -5px !important;
}

.admin-search .form-label {
  padding-right: 20px;
}

.admin-search select.form-control {
  max-width: 150px;
  width: 100%;
}

.add-distributor {
  padding: 50px;
  background: #fff;
  border: 1px solid #ddd;
  margin-bottom: 50px;
}

.add-distributor .form-group {
  margin-bottom: 20px;
}

.add-distributor .admin-title {
  padding-bottom: 30px;
}

.distributor-button .d-btn {
  background: #37a864;
  border: none;
  padding: 10px 30px;
  font-size: 15px;
}

.distributor-form label {
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
}

.user-list .tab-content {
  padding: 30px;
  background: #fff;
  border: 1px solid #dee2e6;
  margin-top: -1px;
  padding-top: 50px;
}

.user-list .nav-tabs .nav-link {
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  width: 50%;
  text-align: center;
}

.user-list .nav-tabs .nav-link:focus,
.user-list .nav-tabs .nav-link:hover {
  outline: none;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.category-popup {
  /* font-family: "Nunito", sans-serif !important;*/
  font-family: "AvenirBook" !important;
}

.admin-content-view .form-control,
.admin-content-view .form-control::placeholder {
  color: #000;
}

.service-available {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.service-available label {
  width: 100%;
}

.service-available .form-check {
  margin-right: 20px;
  color: #000;
}

/************ Services List ****************/

.partner-services-content {
  padding: 20px 0px;
}

.header-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-buttons .btn {
  margin-left: 10px;
  border: 1px solid transparent;
  min-width: 100px;
  font-weight: 500;
  font-size: 22px;
  background: #9551fd;
  border-radius: 8px;
  padding: 5px 12px;
}

.header-buttons .btn:hover {
  border: 1px solid transparent;
  background: #9551fd;
}

.header-buttons .dropdown-toggle::after {
  display: none;
}

.light-btn .btn {
  background: #ffffff;
  color: #333;
  box-shadow: 0 3px 5px 0 rgba(164, 173, 186, 0.25);
}

.header-buttons .dropdown-item {
  font-size: 14px;
  font-weight: 500;
  color: #555;
}

.header-buttons .dropdown a {
  display: block;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  color: #555;
}

.dropdown a:hover {
  text-decoration: none;
}

.dark-btn .btn-primary:not(:disabled):not(.disabled).active,
.dark-btn .btn-primary:not(:disabled):not(.disabled):active,
.dark-btn.show > .btn-primary.dropdown-toggle {
  background: #8147dd !important;
  color: #fff !important;
  border: 1px solid #8147dd !important;
  box-shadow: inherit !important;
}

.dark-btn .btn-primary:not(:disabled):not(.disabled).active:focus,
.dark-btn .btn-primary:not(:disabled):not(.disabled):active:focus,
.dark-btn.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 3px 5px 0 rgba(164, 173, 186, 0.25);
}

.light-btn .btn-primary:not(:disabled):not(.disabled).active:focus,
.light-btn .btn-primary:not(:disabled):not(.disabled):active:focus,
.light-btn.show > .btn-primary.dropdown-toggle:focus {
  /* box-shadow: none; */
  background: #ffffff;
  color: #333;
  box-shadow: 0 3px 5px 0 rgba(164, 173, 186, 0.25);
}

.service-list {
  position: relative;
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
}

.service-list h4 {
  font-size: 20px;
  font-weight: inherit;
  /* margin: 0; */
  padding-bottom: 0;
  text-transform: uppercase;
  color: #9551fd;
  letter-spacing: 0.5px;
  font-family: "AvenirBlack" !important;
}

.service-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
}

.service-list-header [class*="service-"] {
  padding: 10px;
  text-align: left;
  font-size: 14px;
  color: black;
  font-weight: 700;
}

.service-list-header .service-discount {
  text-decoration: inherit;
}

.service-list-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
  padding: 15px 10px;
  border: 1px solid #eaeaea;
  margin-top: -1px;
  cursor: pointer;
}

.service-list-items [class*="service-"] {
  padding: 10px;
  font-weight: 500;
  text-align: left;
}

.service-name {
  font-weight: 700 !important;
  font-size: 15px;
  width: 31%;
  text-align: left !important;
}

.service-name span {
  display: block;
  font-size: 12px;
  color: #999;
  font-weight: 500 !important;
}

.service-discount {
  text-decoration: line-through;
  color: #999;
  width: 10%;
}

.service-hour {
  width: 15%;
}

.service-price {
  font-size: 16px;
  font-weight: 700 !important;
  width: 10%;
}

.service-extra {
  width: 17%;
}

.add-category textarea {
  height: 120px !important;
}

.category-popup .modal-header .close {
  position: absolute;
  right: 15px;
  top: 13px;
  font-size: 35px;
  font-weight: 300;
  padding: 15px;
}

.category-popup .modal-title {
  font-weight: inherit;
  font-size: 30px;
  color: #8147dd;
  font-family: "AvenirBlack";
}

.category-popup .modal-header {
  justify-content: center;
}

.category-popup .modal-body {
  padding: 30px;
}

.category-popup .modal-header .close:focus {
  outline: none;
  box-shadow: none;
}

.add-category .l-btn.btn.btn-primary {
  background: #262f3e;
  padding: 10px 40px;
  font-size: 15px;
}

.add-category .btn-primary:not(:disabled):not(.disabled):active {
  background: #262f3e !important;
  color: #fff !important;
}

.service-list-items-multiple-service .service-list-items {
  border: none;
  padding: 0px 0px;
}

.service-list-items-multiple-service {
  position: relative;
  background: #fff;
  cursor: pointer;
  border: none;
  padding: 10px 0px;
  margin: 0;
  border-top: 1px solid #f7f7f7;
}

.service-list-items-multiple-service h4 {
  font-weight: 600 !important;
  font-size: 15px;
  width: 55%;
  text-align: left !important;
  padding: 10px;
}

.action-icon-img .btn {
  background: transparent;
  border: none;
  color: #777;
  font-size: 20px;
}

.action-icon-img .btn::after {
  display: none;
}

.action-icon-img {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.action-icon-img .btn:hover,
.action-icon-img .btn:focus {
  background: transparent;
  color: #777;
  border: none;
  box-shadow: none;
  outline: none;
}

.no-result {
  background: #fff;
  padding: 50px;
  text-align: center;
  box-shadow: 0px 0px 20px #0000000d;
  border-radius: 5px;
}

.no-result h4 {
  margin: 0;
  font-size: 20px;
}

/* .header-buttons .btn:before {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 4px;
} */

/************ Add Services ****************/

.partner-add-services-content {
  padding: 20px 0px;
  background: #fff;
  box-shadow: 0px 0px 10px #00000014;
  border-radius: 25px;
}

.add-service-box {
  background: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 50px;
  cursor: pointer;
}

.add-service-box.bdr-right {
  border-right: 1px dashed #666;
}

.add-service-box img {
  width: 30px;
  /* opacity: 0.5; */
}

.add-service-box h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 5px;
  padding-top: 10px;
  color: #000;
}

.add-service-box p {
  color: #777;
  max-width: 250px;
  margin: 0px auto;
}

.add-service-box a {
  color: #333;
  display: block;
}

.add-service-box a:hover {
  text-decoration: none;
  color: #333;
}

/* .single-services .admin-title,
.package-services .admin-title,
.add-services .admin-title {
  justify-content: center;
} */

.single-services .admin-title a,
.package-services .admin-title a,
.add-services .admin-title a {
  position: absolute;
  right: 0;
}
.package-services .package-services-content .h-100 {
  padding-bottom: 20px;
}
/************** Single Service  ****************/
.single-services .error {
  position: absolute;
}
.single-services .staff-list {
  justify-content: flex-start;
}
.single-services .staff-item {
  margin: 0.6%;
}

.service-card {
  background: #fff;
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
  position: relative;
  margin-bottom: 20px;
}

.service-header {
  padding: 25px 25px;
  padding-bottom: 10px;
}

.service-body {
  padding: 15px 25px;
}

.service-header h2 {
  font-size: 14px !important;
  font-weight: 600;
  margin: 0;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #9552fe;
  letter-spacing: 0.5px;
}

.service-header p {
  margin: 0;
  color: #000;
}

.treatment-info {
  margin: 0;
  color: #000;
  font-size: 12px;
  padding-top: 8px;
}

.single-services-content textarea {
  height: 120px !important;
}

.package-services-content textarea {
  height: 100px !important;
}
.package-services .package-services-content .service-card {
  height: 100%;
}
.package-services .package-services-content .service-body {
  padding-bottom: 0;
}
.online-booking {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  color: #000;
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  margin: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #007bff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #007bff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}

.sales-setting h5 {
  padding-top: 10px;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.5px;
}

.sales-setting {
  padding-bottom: 10px;
}

.sales-setting p {
  color: #000;
}

.staff-item {
  display: flex;
  align-items: center;
  padding: 15px 15px;
  width: 32%;
  font-weight: 600;
  color: #333;
  flex-wrap: wrap;
  position: relative;
  border: 1px dashed #ddd;
  border-radius: 5px;
  text-align: center;
  flex-flow: column;
  font-size: 16px;
  margin-bottom: 5px;
}

.staff-item img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 0px 10px;
  margin-bottom: 10px;
}

.staff-item .form-check {
  position: absolute;
  right: 6px;
  top: 6px;
}

.staff-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.select-all-staff {
  padding-bottom: 12px;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.staff-copmmision {
  padding-top: 20px;
}

.staff-copmmision h5 {
  padding-top: 10px;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.5px;
}

.staff-copmmision p {
  color: #000;
}

.extra-time h5 {
  font-weight: 600;
  font-size: 17px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.pricing-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pricing-input .form-group {
  width: 49%;
}

.pricing-input .form-group.full {
  width: 100%;
}

.pricing-option {
  background: #ffffff;
  padding: 0;
  border-radius: 8px;
  margin: 0;
  position: relative;
}

.remove-pricing {
  position: absolute;
  right: 10px;
  color: #fb3333;
  font-size: 20px;
  cursor: pointer;
}

.pricing-option h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.5px;
}

.add-pricing-option span {
  cursor: pointer;
  font-weight: 600;
  color: #2577ff;
  font-size: 15px;
  background: #ffffff;
  padding: 10px 0px;
  border-radius: 4px;
}

.single-services-content .online-booking {
  position: absolute;
  right: 20px;
  bottom: 30px;
}
.single-services-content .out-service {
  position: relative;
}
.single-services-content .voucher-sales {
  position: relative;
}

.single-services-content .voucher-sales .online-booking {
  top: 8px;
  right: 0;
}
.single-services-content .online-booking .switch {
  margin-right: 10px;
}
/* .single-services-content .out-service .online-booking  {
  top: 10px;
  right: 20px;
} */
.single-services-content .out-service .online-booking {
  top: 30px;
  right: 20px;
}

.add-pricing-option {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-button.service-save {
  padding: 20px 0px;
  display: flex;
  justify-content: flex-end;
}

.service-save .l-btn.btn.btn-primary {
  background: #262f3e;
}
.service-save .l-btn.btn.btn-default {
  border: 1px solid #ddd !important;
  color: #999;
  margin-right: 10px;
  padding: 12px 40px;
}

.service-save .btn {
  max-width: 150px;
  margin-right: 10px;
}

.service-category-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f1f1f1;
  padding: 12px 15px;
  border-radius: 5px;
  flex-wrap: wrap;
}

.service-category-list-items ul {
  margin: 0;
}

.service-category-list-items ul li {
  font-weight: 500;
  font-size: 16px;
}

.category-edit span {
  color: #018bcf;
  font-weight: 600;
  cursor: pointer;
}

.service-category-list-items {
  width: 80%;
}

.category-edit {
  width: 20%;
  text-align: right;
}

.select-categories .select-list {
  padding: 15px 0px;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  position: relative;
}

.select-categories .select-list h5 {
  margin: 0;
  font-size: 18px;
  max-width: 350px;
  width: 100%;
}

.select-categories .select-list p {
  margin: 0;
  font-size: 12px;
  color: #999;
}

.select-categories .select-list:last-child {
  border: none;
}

.select-categories + .login-button {
  margin-top: 20px;
}

.add-service-price i {
  margin-left: 10px;
  color: #f34949;
}

.extra-option h5 {
  padding-top: 15px;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 15px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.5px;
}

.select-services .select-categories {
  padding-bottom: 20px;
}

.select-service-popup .modal-dialog {
  max-width: 800px;
}

.accordion .btn {
  width: 100%;
  text-align: left;
  color: #333;
  text-decoration: none;
  padding: 10px 0px;
  font-size: 20px;
  font-weight: 500;
}

.accordion .btn:focus {
  box-shadow: none;
}

.accordion .card-header {
  background: #fff;
  border: none;
  padding: 0;
}

.accordion .card {
  border: none;
  border-bottom: 1px solid #ddd !important;
}

.accordion .btn:hover,
.accordion .btn:focus {
  color: #333;
  text-decoration: none;
}

.accordion .btn i {
  position: absolute;
  right: 0;
  top: 15px;
}

.accordion .card:last-child {
  border-bottom: none !important;
}

.accordion .card-body {
  padding: 0px 20px;
}

.add-service-price {
  position: absolute;
  right: 0;
  top: 20px;
  font-weight: 600;
  font-size: 18px;
}

.service-pricing .input-group-prepend {
  padding: 0px 10px;
  border: 1px solid #ced4da;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

button.l-btn.delete-btn.btn.btn-primary {
  background-color: #ea3333 !important;
}

/* .pricing-input .input-group-prepend {
  margin-left: 10px;
} */
.pricing-input .input-group-prepend {
  padding: 0px 10px;
  border: 1px solid #ced4da;
  border-left: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #eee;
  font-family: "AvenirHeavy";
}
.partner-header h2 {
  font-size: 22px !important;
  font-weight: 700;
  color: #9553ff !important;
  font-family: "AvenirBlack";
}

.partner-header {
  padding-bottom: 25px;
}
/* .partner-table {
  max-height: 500px;
  overflow: auto;
  height: 100%;
} */

.partner-table table thead th {
  color: #000;
  font-weight: inherit;
  border: none;
  padding: 15px 10px;
  border-top: 1px solid #f1f1f1;
  letter-spacing: 0.2px;
  border-bottom: 1px solid #f1f1f1;
  font-family: "AvenirBlack";
}

.partner-table table tbody td {
  color: #000;
  padding: 25px 10px;
  font-size: 15px;
  border-top: 1px solid #f1f1f1;
  font-family: "AvenirBook";
}
.partner-table .login-button.service-save {
  padding: 20px 0px;
  display: flex;
  justify-content: unset;
}

.package-services .l-btn.btn.btn-primary {
  width: auto !important;
}
.package-services .delete-btn {
  background-color: #ea3333 !important;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.3;
  background: #ccc;
}
.partner-table table .disabletable tr:first-child {
  opacity: 0.3;
  background: #ccc;
  cursor: not-allowed;
}
.disable-block {
  opacity: 0.5;
  cursor: not-allowed;
}

.disable-block input {
  pointer-events: none;
}

.disabled input {
  pointer-events: none;
}

.pdd-bt-89 {
  padding-bottom: 89px;
}

.advance-pricing-popup {
  /* font-family: "Nunito", sans-serif !important; */
  font-family: "AvenirBook" !important;
}
.advance-pricing-box {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  background: #f7f7f7;
  border-radius: 5px;
}

.advance-pricing-box .form-group {
  width: 24%;
}

.special-price-associate {
  padding: 20px 0px;
}

.associate-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 10px;
}

.associate-user img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.associate-user {
  display: flex;
  align-items: center;
  width: 40%;
}

.associate-user p {
  margin: 0;
  padding-left: 15px;
  font-weight: 600;
  color: #000;
}

.price-associate {
  width: 25%;
  padding-right: 10px;
}

.duratiion-associate {
  width: 25%;
}

.special-price-associate h4 {
  font-weight: 600;
  color: #555;
  font-size: 20px;
}
.advance-pricing-popup .modal-title {
  font-weight: 700;
  color: #555;
}

.associate-pricing-buttons {
  text-align: right;
}

.associate-pricing-buttons .btn.btn-default {
  border: 1px solid #ddd;
  color: #999;
  margin-right: 10px;
}

.associate-pricing-buttons .btn.btn-primary {
  background: #262f3e;
  border-color: #262f3e;
}

.associate-pricing-buttons .btn.btn-primary:hover {
  border: 1px solid #262f3e !important;
}
.react-tel-input .special-label {
  display: none;
}

.register-form .form-group .react-tel-input .form-control {
  border-radius: 0;
  padding: 12px 40px;
  padding-right: 15px;
  border-right: none;
}

.react-tel-input .flag-dropdown {
  right: 0;
  left: 0;
}

.react-tel-input .selected-flag {
  width: 100%;
}

.react-tel-input .selected-flag .arrow {
  position: absolute;
  right: -70px;
  left: inherit;
}
.react-tel-input .selected-flag:focus .arrow {
  border-left-width: 3px;
  border-right-width: 3px;
  border-top: 4px solid #555;
}
.search-client {
  height: 59vh;
  position: sticky;
  overflow: hidden;
}
.client-img img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  margin-right: 10px;
}
.client-sec {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
}
.clientt p {
  text-decoration: underline;
  cursor: pointer;
  color: #018bcf;
  text-align: center;
  font-size: 15px;
}

input.form-control.form-control-lg::placeholder {
  color: #000 !important;
}

input.form-control::placeholder {
  color: #000 !important;
}

.partner-login .reset-box .form-group {
  position: relative;
}

.partner-login .reset-box .form-group i {
  position: absolute;
  right: 15px;
  top: 12px;
}
input[type="email"] {
  text-transform: lowercase;
}

.admin-info .dropdown-menu a {
  font-size: 13px;
  padding: 8px 20px;
  font-weight: 600;
  color: #333;
  display: block;
  width: 100%;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.partner-table p.placeholder-text {
  font-size: 22px;
}
/* notification-popup */
.admin-notify {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 175px;
  /* position: relative; */
}

.admin-header .notification-section {
  position: absolute;
  top: 64px;
  right: -4px;
  z-index: 999;
  background-color: white;
  box-shadow: 0px 0px 5px #ced4ce;
  max-width: 350px;
  width: 100%;
  border-radius: 10px;
}
.admin-header .notify {
  overflow: auto;
  max-height: 395px;
  margin-top: 5px;
  height: auto;
}
.header-buttons h6 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.header-buttons h6 span button {
  width: 100%;
  text-align: left;
  padding: 8px 10px !important;
  font-size: 14px;
}

.close-acount {
  background-color: #262f3e;
  color: #fff !important;
  border-color: #262f3e;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
}

.admin-access {
  color: #ff3d3d;
  font-weight: 700;
  margin-right: 20px;
  font-size: 18px;
}
.dis-show.new-form-group + span.error {
  margin-left: -8px !important;
}
.error::first-letter {
  text-transform: uppercase !important;
}
.error {
  text-transform: lowercase;
}
.patners-logo h3 {
  text-transform: capitalize;
  margin-bottom: 0;
  font-weight: 900;
  padding-left: 10px;
  font-family: "AvenirBlack";
  font-size: 27px;
}
.dashboard .card-body .fa {
  transition: all 0.5s;
  color: #9553ff !important;
  opacity: 1;
  margin-bottom: 80px;
}

.dashboard .card-body span.card-icon {
  margin-bottom: 80px;
  display: inline-block;
}

.dashboard .card-body span.card-icon > img {
  width: 100%;
  object-fit: cover;
}
.admin-menu .menu-logo {
  padding: 10px 20px;
  text-align: center;
  min-height: 60px;
}
.admin-menu.menu-show {
  width: 290px;
}
.admin-menu.menu-show .menu-list ul a > span {
  display: block;
  padding-left: 15px;
}
.admin-menu.menu-show .menu-list ul a {
  display: flex;
  align-items: center;
}
.menu-list ul a > img {
  width: 22px;
}
.admin-menu.menu-show .menu-list ul a > img {
  width: 20px;
}
.admin-menu .menu-logo img.sidebar-cross-icon {
  display: none;
}

.admin-menu .menu-logo img.sidebar-full-logo {
  display: none;
}
.admin-menu.menu-show .menu-logo img.sidebar-logo-icon {
  display: none;
}
.admin-menu.menu-show .menu-logo img.sidebar-cross-icon {
  display: block;
}

.admin-menu.menu-show .menu-logo img.sidebar-full-logo {
  display: block;
}
.admin-menu .menu-logo > span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.admin-menu .menu-logo > span img.sidebar-full-logo {
  max-width: 140px;
}

.admin-menu .menu-logo > span img.sidebar-cross-icon {
  max-width: 18px;
  margin-right: 12px;
}
.menu-toggle > span {
  display: none !important;
}

/* ******************** Sidebar new design *********************** */

.admin-menu .menu-logo {
  padding: 15px 20px;
  text-align: center;
}
.admin-menu.menu-show {
  width: 290px;
}
.admin-container.wrapper-expand {
  /* width: calc(100% - 397px); */
  width: 100%;
  padding-left: 290px;
}
.admin-menu.menu-show .menu-list ul a > span {
  display: block;
  padding-left: 15px;
}
.admin-menu.menu-show .menu-list ul a {
  display: flex;
  align-items: center;
}
.admin-menu .menu-logo img.sidebar-cross-icon {
  display: none;
}
.admin-menu .menu-logo img.sidebar-full-logo {
  display: none;
}
.admin-menu.menu-show .menu-logo img.sidebar-logo-icon {
  display: none;
}
.admin-menu.menu-show .menu-logo img.sidebar-cross-icon {
  display: block;
}

.admin-menu.menu-show .menu-logo img.sidebar-full-logo {
  display: block;
}
.admin-menu .menu-logo > span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.admin-menu .menu-logo > span img.sidebar-full-logo {
  max-width: 140px;
}

.admin-menu .menu-logo > span img.sidebar-cross-icon {
  max-width: 18px;
  margin-right: 12px;
}
.menu-toggle > span {
  display: none !important;
}
/* .menu-logo img.sidebar-logo-icon {
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  max-width: 30px;
} */

.menu-logo {
  position: relative;
}
.menu-logo:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 10px;
  /* background: red; */
  background-image: url("../../assets/arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.admin-menu.menu-show .menu-logo:before {
  content: none;
}
.menu-list ul a > span {
  display: none;
}
.admin-menu.menu-show .menu-logo > span {
  justify-content: flex-start;
}
.admin-menu.menu-show .menu-logo {
  border-bottom: 1px solid #fff;
}
.admin-menu-box {
  overflow-y: auto;
  height: calc(100% - 73px);
}
/* width */
.admin-menu-box::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.admin-menu-box::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}

/* Handle */
.admin-menu-box::-webkit-scrollbar-thumb {
  background: #222;
  border-radius: 10px;
}

/* Handle on hover */
.admin-menu-box::-webkit-scrollbar-thumb:hover {
  background: #2e2e2e;
}
.admin-menu-box::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.admin-menu-box {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card {
  border-radius: 25px !important;
}
/******************************* Media SCreen ******************************/
@media screen and (max-width: 1600px) {
  /* .admin-menu {
    width: 75px;
  } */
  /* .admin-container {
    left: 75px;
  }
  .admin-container {
    padding-left: 75px;
  } */
  /* .admin-menu.menu-show {
    width: 290px;
  } */
  /* .admin-container.wrapper-expand {
    padding-left: 290px;
  }
  .admin-menu .menu-logo {
    min-height: 60px;
  }
  .admin-menu .menu-logo > span img.sidebar-full-logo {
    max-width: 130px;
  } */
  .menu-logo img.sidebar-logo-icon {
    padding-bottom: 0;
  }
  .dashboard .card-body .fa {
    margin-bottom: 50px;
    font-size: 50px;
  }
  .dashboard .card-body span.card-icon {
    margin-bottom: 50px;
  }
  .dashboard .text-muted {
    font-size: 17px;
  }
  .dashboard span.h4 {
    font-size: 22px !important;
  }
  .partner-header h2 {
    font-size: 18px !important;
  }
  .badge {
    padding: 6px 13px !important;
    font-size: 14px !important;
  }
  .dashboard .card {
    border-radius: 15px;
  }
  .admin-title {
    font-size: 22px !important;
    margin-bottom: 0;
  }
  .card {
    border-radius: 15px !important;
  }
  .menu-list ul a i {
    font-size: 24px;
  }
}
@media screen and (max-width: 991px) {
  .admin-menu {
    /* width: 0; */
    margin-left: -1px;
  }
  .admin-menu.menu-hide {
    /* width: 250px; */
    opacity: 1;
  }
  .admin-container {
    width: 100%;
  }
  .admin-content {
    padding: 25px 10px;
  }
  .menu-toggle > span {
    display: block !important;
  }
}
@media screen and (max-width: 767px) {
  .admin-content {
    padding: 30px 10px;
  }

  .admin-container {
    width: 100%;
  }

  .admin-search .form-inline {
    justify-content: center;
  }

  .admin-search .btn {
    margin-top: 10px;
  }

  .user-list .table-responsive > .table-bordered .btn {
    min-width: 100px;
    margin-bottom: 5px;
  }
}

/* .menu-logo img {
  max-width: 40px;
}
.menu-logo h2 {
  text-align: center;
  position: relative;
}

.menu-logo img.sidebar-logo-icon:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 60%;
  background: #fff;
  top: auto;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
} */

/* ******************** Sidebar new design *********************** */
/* .admin-side-panel {
  width: 397px;
  position: fixed;
  left: 102px;
  background: #000;
  top: 0;
  z-index: 9999;
  height: 100vh;
  display: flex;
  /* align-items: center; *
  justify-content: center;
  flex-flow: column;
  overflow-y: auto;
}

.menu-list.wrapper-menu-list {
  padding-top: 64px;
}

.menu-list.wrapper-menu-list ul a > span {
  display: block;
  padding-left: 15px;
}
.menu-list.wrapper-menu-list ul a {
  display: flex;
  align-items: center;
}
.admin-side-panel .close-icon {
  position: absolute;
  right: auto;
  left: 26px;
  top: 30px;
  cursor: pointer;
}

.admin-side-panel .close-icon img {
  width: 22px;
  opacity: 1;
  transition: all 0.5s;
}
.admin-side-header a.navbar-brand > img {
  width: 178px;
}

.admin-side-header {
  display: flex;
  align-items: center;
} */

/* .admin-side-header a.navbar-brand {
  margin-left: 70px;
} */
/* .admin-side-panel {
  left: -1px;
}
.admin-menu {
  width: 397px;
}
.admin-container {
  width: calc(100% - 397px);
}  */
.remove-sidebar .menu-toggle {
  opacity: 0;
}
.signup-left table td {
  padding: 10px 20px;
}
.shift-filter .form-inline .form-group {
  border-right: none;
}

/* .admin-menu.menu-show .menu-list ul a > span.sep {
  display: flex !important;
  position: relative;
  margin-bottom: 20px;
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
} */

.nav span.sep {
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  display: inline-block !important;
  margin: 30px 0 10px;
}
.p-name {
  padding-left: 5px;
  color: black;
}
.single-services .service-header h2.admin-inner-title {
  font-size: 18px !important;
}
.admin-content-view .staff-page .nav-tabs .nav-link.active {
  color: #9550fc !important;
  border-bottom: 2px solid #9550fc !important;
}
.admin-content-view .admin-menu .menu-logo {
  padding: 10px 0px;
}
.admin-content-view .partner-table .table thead th,
.partner-table .table tbody td {
  font-size: 17px;
}
.admin-content-view .card .card-body .text-Primary {
  font-size: 17px;
}
.admin-content-view .signup-head h3 {
  font-size: 25px;
}
.admin-content-view .partner-header h2 {
  text-transform: capitalize;
}
.admin-content-view .service-list h4 {
  text-transform: capitalize;
}
.new-sidebar .title {
  font-size: 17px;
}
.single-store .rate-number {
  border-radius: 0px;
}
.store-listing .venues-rating h6 {
  border-radius: 0px;
}
.store-listing .pagination-section .pagination-chk .pagination__link--active a {
  color: #00c2fe !important;
}
.admin-content-view .title-right .admin-title {
  float: right;
}
.admin-content-view .admin-inner-title {
  text-transform: capitalize !important;
}
.modal-content .modal-footer {
  justify-content: center;
  border-top: 0px !important;
}
.modal-content .text-right {
  text-align: center !important;
}
.modal-content .modal-header .modal-title.h4 {
  font-size: 30px;
  font-weight: inherit;
  font-family: "AvenirBlack";
}
/* .modal-content .modal-body {
  padding: 30px;
} */
.modal-content .modal-body p {
  text-align: center !important;
}
.admin-content-view .calendar-days a {
  width: 30%;
}
/* .admin-content-view .admin-title {
  
} */
