.signup {
  background: #d7ecf8;
}

.register-section {
  padding: 55px 0px 50px 0;
}

.register-form {
  padding: 40px 40px 20px 40px;
  max-width: 486px;
  margin: 0px auto;
  background: #fff;
  border-radius: 20px;
  /* box-shadow: rgba(16, 25, 40, 0.08) 0px 4px 8px 0px; */
  box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
}

.head-logo img {
  max-width: 100px;
  margin: 0px auto;
  display: block;
  border: 2px solid #018bcf;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}

.signup-link {
  color: #0098ff;
  text-decoration: underline;
}

.register-form h1 {
  font-size: 27px;
  font-weight: 900;
  text-align: center;
  max-width: 500px;
  margin: 0px auto;
  color: #00adff;
}

.register-form h1 span {
  color: #018bcf;
}

.register-form .rigister-tab {
  margin-top: 45px;
}

.rigister-tab p {
  text-align: center;
  padding-top: 22px;
  font-size: 12px;
  color: #000;
  margin: 0;
  font-family: "AvenirLight";
}

.terms-link {
  color: #0098ff;
  text-decoration: underline;
}

.terms-link:hover {
  color: #c5c5c5;
}

.dis-show .form-check {
  display: inline-block;
  padding-right: 3px;
}

.dis-show.form-group {
  margin-bottom: 0px !important;
}

.dis-show p {
  font-size: 12px;
  color: #000;
  margin: 0;
  font-weight: 500;
  padding: 0 !important;
}

.dis-show {
  display: flex;
  align-items: center;
}

.s-btn.btn.btn-primary {
  padding: 9px 40px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  border-radius: 10px;
  background: #0cbfff;
  background: -moz-linear-gradient(top, #0cbfff 0%, #627cff 100%);
  background: -webkit-linear-gradient(top, #0cbfff 0%, #627cff 100%);
  background: linear-gradient(to bottom, #0cbfff 0%, #627cff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0cbfff', endColorstr='#627cff', GradientType=0);
  border: none;
  /* transition: all 0.5s; */
  width: 100%;
  margin-top: 20px;
}

.s-btn.btn.btn-primary:hover,
.s-btn.btn.btn-primary:focus,
.s-btn.btn.btn-primary:active {
  background: #627cff;
  background: -moz-linear-gradient(top, #627cff 0%, #0cbfff 100%);
  background: -webkit-linear-gradient(top, #627cff 0%, #0cbfff 100%);
  background: linear-gradient(to bottom, #627cff 0%, #0cbfff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#627cff', endColorstr='#0cbfff', GradientType=0);

  box-shadow: none;
  border: none;
}

.register-form .form-group .form-control {
  height: auto;
  padding: 7px 10px;

  border: 1px solid #dadada;
  border-radius: 8px;
  font-size: 12px !important;
  /* color: #a2a2a2; */
  color: #000;
  font-family: "AvenirLight";
}
.register-form .form-group .number-fields .form-control {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.register-form .form-group .form-control:focus {
  box-shadow: none;
}

.register-form input::-internal-autofill-selected {
  background: transparent !important;
}

.signup-popup .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.form-label {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}

.signup-popup .modal-header .close:focus {
  outline: none;
}

.signup-popup .close {
  color: #999;
  text-shadow: none;
  font-weight: 400;
  font-size: 30px;
}

.signup-popup .close:hover {
  color: #777;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  border: 1px solid #dadada;
  -webkit-text-fill-color: #a2a2a2;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.error {
  position: relative;
  font-weight: 400 !important;
  font-size: 10px !important;
  color: #f91c1c !important;
  text-align: left !important;
  padding: 0 !important;
  line-height: 12px;
  padding-top: 5px !important;
  margin: 0px !important;
  font-family: "AvenirBlack" !important;
}

.number-fields {
  display: flex;
  align-items: center;
}

.number-fields .dropdown .btn {
  height: auto;
  padding: 11px 20px;
  background: transparent;
  border: 1px solid #a58d8d !important;
  border-radius: 4px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  line-height: 22px;
  color: #000;
}

.number-fields .form-control {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.number-fields .dropdown .btn:focus {
  border: 1px solid #dadada;
  border-right: 0;
  box-shadow: none;
}

.number-fields .btn-primary:not(:disabled):not(.disabled):active {
  border: 1px solid #dadada !important;
  border-right: 0 !important;
  box-shadow: none;
}

.number-fields .show > .btn-primary.dropdown-toggle {
  border: 1px solid #dadada !important;
  border-right: 0 !important;
  box-shadow: none !important;
}

.number-fields .dropdown-item {
  font-size: 14px;
  color: #777;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.rigister-tab .react-tel-input {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  position: relative;
  width: 30% !important;
}

.register-form .form-group .react-tel-input .form-control {
  height: auto;
  padding: 7px 40px !important;
  padding-right: 0;
  background: transparent;
  border: 1px solid #dadada;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  cursor: not-allowed;
  pointer-events: none;
  color: #a2a2a2;
}

.register-form .login-btn,
.login-form .login-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.register-form .login-btn > div,
.login-form .login-btn > div {
  width: 49%;
  position: relative;
  /* margin-top: 20px; */
}

.register-form .login-btn > div .btn,
.login-form .login-btn > div .btn {
  font-size: 14px;
  padding: 11px;
  padding-left: 45px;
  margin: 0;
}

.register-form .login-btn,
.login-form .login-btn {
  height: 100%;
  padding-top: 10px;
}

.google-btn button {
  width: 100%;
  height: 100%;
  padding: 5px 12px;
  font-size: 12px;
  margin-bottom: 0;
  line-height: 12px;
  box-shadow: none !important;
  border: 1px solid #ddd !important;
}

.google-btn button > div {
  border-right: 1px solid #ddd;
  border-radius: 0 !important;
}

.facebook-btn button.kep-login-facebook.metro {
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  font-size: 12px;
  margin-bottom: 0;
  line-height: 12px;
  border-radius: 3px;
}

.google-btn button span {
  font-size: 12px;
  /* font-weight: 700 !important; */
  display: inline-block;
  /* text-transform: uppercase; */
}

/* .login-form .login-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
} */

/* .login-form .login-btn > div {
  width: 49%;
  position: relative;
  /* margin-top: 20px; *
} */

/* .login-form .login-btn > div .btn {
  font-size: 14px;
  padding: 11px;
  padding-left: 45px;
  margin: 0;
} */

/* .login-form .login-btn {
  height: 100%;
  padding-top: 20px;
} */
.facebook-btn:hover button.kep-login-facebook.metro {
  color: #2e5488;
  background-color: transparent;
}

.facebook-btn button.kep-login-facebook.metro {
  text-transform: none;
  /* font-family: "Nunito", sans-serif !important; */
  font-family: "AvenirBook" !important;
  font-weight: 400;
  font-size: 12px;
  display: block !important;
  background: #2e5488;
  text-align: center;
  padding: 5px 15px !important;
  border: 1px solid #2e5488 !important;
  border-radius: 10px !important;
}

.google-btn:hover button {
  background-color: transparent !important;
  color: #d34646 !important;
}

.google-btn button {
  font-family: "AvenirLight" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  background: #d34646 !important;
  opacity: 1 !important;
  color: #fff !important;
  line-height: 6px;
  border-radius: 10px !important;
  display: block !important;
  text-align: center;
  padding: 5px 15px !important;
  border: 1px solid #d34646 !important;
  cursor: pointer;
}

.google-btn button > div {
  display: none;
}

input[type="email"] {
  text-transform: lowercase;
}

.Toastify__toast-container {
  z-index: 9999999999 !important;
}

.register-form .form-label {
  font-size: 15px;
  color: #000;
  font-weight: 900;
  margin-bottom: 1px;
  font-family: "AvenirMedium";
}

.signup-box p {
  padding-top: 10px;
}

.search-container .form-inline {
  border: 1px solid #abd9e5;
  box-shadow: none;
  border-radius: 10px;
}

.register-form .form-group {
  margin-bottom: 19px;
}

.dis-show .form-check-input {
  height: 15px;
  width: 15px;
}

.react-tel-input .selected-flag .arrow {
  right: -11px !important;
}
.login-view-body .footer {
  display: none;
}

.register-form .form-group .form-control::placeholder {
  /* color: #a2a2a2 !important; */
  color: #000 !important;
  text-transform: capitalize !important;
}

.register-form.new-register-form .new-form-group {
  width: calc(100% + 20px);
  margin-left: -10px;
}

.rigister-tab .react-tel-input {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  position: relative;
  width: 30% !important;
  border-right: 0 !important;
  border: 1px solid #dadada;
  border-radius: 10px 0 0 10px;
}

.register-form .form-group .react-tel-input .form-control {
  border-radius: 0;
  padding: 12px 40px;
  padding-right: 15px;
  border-right: none;
  border: 0;
}

.react-tel-input .selected-flag {
  border-radius: 0;
}

.number-fields .form-control {
  /* border-left: 0 !important; */
}

.number-fields .btn-primary:not(:disabled):not(.disabled):active {
  border: 1px solid #dadada !important;
  border-right: 0 !important;
  box-shadow: none;
  color: #000 !important;
}

.number-fields .dropdown .btn {
  height: auto;
  padding: 5px 20px;
  font-family: "avenirromanopentype_bigfontsite_com";
  font-size: 12px;
}
/* .form-control {
  border-radius: 8px !important;
} */

.number-fields .dropdown .btn {
  height: auto;
  /* padding: 8px 12px 8px 12px; */
  font-family: "avenirromanopentype_bigfontsite_com";
  font-size: 12px;
  border-radius: 10px 0px 0px 10px;
  border-right: 0px !important;
  border-color: #dadada !important;
  height: 53px;
}

.number-fields .dropdown-menu.show {
  height: 200px;
  overflow: auto;
}

.number-fields a.dropdown-item {
  padding: 5px 10px;
  font-weight: 400 !important;
  font-family: "avenirromanopentype_bigfontsite_com";
  font-size: 12px;
  min-width: auto;
}
