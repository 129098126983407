@import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css";

.store-listing {
  position: relative;
}

.store-listing-cover {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 80px 0px;
  width: 100%;
}

.store-listing-cover:before {
  content: "";
  background: #000;
  opacity: 0.7;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.store-listing-cover h2 {
  position: relative;
  font-weight: 700;
  font-size: 35px;
  color: #fff;
  text-align: center;
}

.store-listing-filter {
  margin: 50px 0px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 18px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}

.store-filter-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.store-search-result p {
  margin: 0;
  color: #999;
  font-size: 16px;
  font-weight: 500;
}

.store-filter-box .switch {
  margin-left: 10px;
}

.store-filter-box .dropdown {
  margin-left: 10px;
}

.store-filter-box .dropdown .btn {
  background: transparent;
  border: 1px solid #cac9c9;
  color: #777;
  font-size: 15px;
}

.store-filter-box .dropdown .btn:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.store-filter-box .dropdown .btn-primary:not(:disabled):not(.disabled):active {
  border: 1px solid #cac9c9 !important;
}

.store-filter-box .show > .btn-primary.dropdown-toggle {
  border: 1px solid #cac9c9 !important;
}

.store-filter-box .dropdown-item {
  font-size: 14px;
}

.store-listing-view {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 50px;
}

.store-list {
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

/* .store-list.store-list-new > a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
} */
/* .store-list.store-list-new > a:hover {
  text-decoration: none;
}
.store-list-new .store-detail {
  color: #000;
}
.store-list-new > a:hover .store-detail {
  color: #0056b3;
} */
.store-image img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}

.store-image {
  width: 40%;
  position: relative;
}

.store-detail {
  width: 60%;
  padding: 10px 20px;
}

.store-favorite {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #ffffffb8;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 38px;
  border-radius: 100%;
  box-shadow: 0px 0px 5px 0px #0000001f;
}

.store-favorite i {
  color: #ff2424;
  font-size: 16px;
  cursor: pointer;
}

.store-detail h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 10px;
}

.store-detail ul li {
  padding: 5px 0px;
  color: #777;
  font-size: 13px;
}

.store-rating i {
  color: #ffd437;
  font-size: 16px;
  margin-right: 2px;
}

.store-detail ul {
  margin: 0;
}

.store-rating {
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.store-rating span {
  color: #fff;
  padding: 5px 2px;
  margin-left: 0px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}

.store-rating i {
  font-style: normal;
  background: #4caf50;
  color: #fff;
  padding: 5px 7px;
  margin-left: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}

.store-pagination {
  text-align: center;
}

.store-pagination .pagination {
  justify-content: center;
  padding-bottom: 30px;
  padding-top: 20px;
}

.store-pagination .page-link {
  color: #777;
  background-color: #dee2e6;
  border: 1px solid #dee2e6;
  width: 40px;
  height: 40px;
  line-height: 24px;
  margin: 0px 5px;
  border-radius: 100%;
}

.store-pagination .page-item.active .page-link {
  width: 40px;
  height: 40px;
  line-height: 24px;
  margin: 0px 5px;
  border-radius: 100%;
}

.store-pagination .page-item:first-child .page-link {
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
}

.store-pagination .page-item:last-child .page-link {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
}

.store-pagination .page-link:hover {
  color: #777;
}

.store-detail ul li i {
  color: #ff2424;
  font-size: 16px;
  margin-right: 5px;
  vertical-align: middle;
}

.store-location {
  margin-bottom: 50px;
  display: none;
}

.store-location.show {
  display: block;
}

/******************************** Store Detail Page *******************************/

.store-slider-container {
  overflow: hidden;
  position: relative;
}

.store-slider img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.store-slider img:focus {
  outline: none;
}

.opening-hours {
  overflow: hidden;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 30px;
  transform: translate3d(0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 1;
  margin-top: 35px;
}

.opening-hours h3 {
  width: 100%;
  font-size: 18px;
  line-height: 40px;
  padding: 0 0 20px;
  margin: 5px 0 20px 0;
  display: block;
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
}

.opening-hours h3 i {
  margin-right: 10px;
  color: #fff;
  width: 40px;
  height: 40px;
  background: #018cce;
  border-radius: 50%;
  position: relative;
  top: 1px;
  text-align: center;
  line-height: 40px;
}

.opening-hours ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.opening-hours ul li {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #66676b;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin: 0;
  padding: 6px 0;
  transition: 0.2s;
  cursor: default;
}

.opening-hours ul li span {
  float: right;
  font-weight: 400;
  font-size: 12px;
}

.store-detail-info {
  background: #f9f9f9;
  padding: 20px 25px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.store-about {
  padding: 50px 0px;
}

.about-store {
  padding-top: 50px;
}

.store-detail-info h2 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #323232;
}

.store-detail-info .store-detail {
  padding: 0;
  width: 100%;
}

.store-detail-info .store-detail ul li {
  font-size: 14px;
  font-weight: 500;
}

.book-now .btn {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 20px;
  background: #262f3e;
  border: none;
  color: #fff;
}

.book-now .btn:hover {
  color: #fff;
  text-decoration: none;
}

.heading-title {
  font-size: 20px;
  font-weight: 600;
  position: relative;
  display: block;
  padding-bottom: 30px;
  margin-bottom: 10px;
}

.heading-title::after {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 20px;
  height: 2px;
  width: 40px;
  background-color: #018cce;
}

.about-store p {
  font-size: 15px;
  line-height: 27px;
  color: #777;
}

.about-store ul li {
  border: 1px solid #e0e0e0;
  color: #555;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 8px;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
}

.near-venue {
  padding: 50px 0px;
}

.opening-hours .form-control {
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  outline: none;
  font-size: 15px;
  /* color: #808080; */
  color: #000;
  margin: 0 0 16px 0;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.03);
  font-weight: 400;
  opacity: 1;
  border-radius: 4px;
}

.opening-hours textarea {
  height: auto;
  line-height: 24px;
  padding: 15px;
  min-height: 130px;
  transition: none !important;
  min-width: 100%;
}

.opening-hours .btn.btn-primary {
  text-align: center;
  margin: 0 auto;
  display: block;
  border: 0;
  line-height: 28px;
  height: auto;
  padding: 10px 20px;
}

.store-review .opening-hours {
  margin-top: 0;
}

.store-review {
  padding: 50px 0px;
}

.comments ul {
  padding-left: 0;
}

.comments ul li {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 20px;
  border-radius: 4px;
  display: block;
  width: 100%;
  margin: 30px 0 0 0px;
}

.comments .avatar {
  display: inline-block;
  padding: 0 20px 0 20px;
  float: left;
  position: absolute;
  left: 0px;
}

.comments .avatar img {
  width: 80px;
  height: 80px;
  border: 5px solid rgba(0, 0, 0, 0.06);
  border-radius: 50px;
  object-fit: cover;
}

.comments .utf_comment_content {
  color: #666;
  padding: 0 0 0px 100px;
}

.utf_star_rating_section {
  display: block;
  font-size: 15px;
  float: left;
  position: absolute;
  right: 10px;
  top: 30px;
}

.utf_star_rating_section .star {
  display: inline-block;
  margin: 0;
  padding: 0;
  float: left;
  margin-right: 4px;
  position: relative;
  color: #888;
}

.utf_star_rating_section .star:before {
  font-family: "FontAwesome";
  content: "\f005";
  display: block;
  color: #ffc600;
}

.utf_by_comment {
  width: 100%;
  padding-bottom: 0;
  padding-top: 0px;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  display: inline-block;
  padding-right: 100px;
}

.utf_by_comment span.date {
  color: #888;
  font-size: 12px;
  font-weight: 400;
  margin: 0px 0 10px 0;
  float: none;
  display: block;
  text-align: left;
}

.utf_by_comment span.date i {
  color: #007bff;
  font-size: 14px;
  margin-right: 2px;
}

.utf_comment_content p {
  padding: 5px 0;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  line-height: 25px;
}

.utf_leave_rating {
  height: 48px;
  float: left;
  background: #fff;
  padding: 12px 15px;
  line-height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  text-align: center;
  vertical-align: middle;
  margin: 0 auto;
  margin-bottom: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.03);
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.utf_leave_rating input[type="radio"] {
  display: none;
}

.utf_leave_rating input[type="radio"] {
  width: 15px;
  height: 15px;
  cursor: pointer;
  box-shadow: none;
  padding: 0;
}

.utf_leave_rating label {
  font-size: 24px;
  letter-spacing: 6px;
  color: #dadada;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 0;
}

.utf_leave_rating span {
  margin-right: 10px;
  color: #808080;
  font-size: 14px;
}

.utf_leave_rating label:hover,
.utf_leave_rating label:hover ~ label {
  color: #ffc600 !important;
}

.utf_leave_rating input[type="radio"]:checked ~ label {
  color: #ffc600;
}

.store-services {
  padding: 50px 0px;
}

.store-service-tab {
  padding-top: 35px;
}

.store-service-tab-content {
  padding: 40px 0px;
}

.store-service-tab .nav-tabs {
  border: none;
  justify-content: flex-start;
  border-bottom: 2px solid rgb(0 0 0 / 0.1);
  margin-bottom: 0px;
  counter-reset: a;
}

.store-service-tab .nav-tabs .nav-link::before {
  counter-increment: a;
  content: counter(a, decimal-leading-zero);
  position: absolute;
  left: 0;
  top: 0px;
  font-size: 10px;
}

.store-service-tab .nav-tabs .nav-link {
  background: transparent;
  color: #161c23;
  border: none;
  padding: 10px 20px;
  margin: 0px 0px;
  position: relative;
  top: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 33px;
}

.store-service-tab .tab-content {
  border: none;
  margin: 0;
  padding: 0;
}

.store-service-tab .nav-tabs .nav-link.active {
  background: transparent !important;
  border: none;
  color: #007bff !important;
  border-bottom: 4px solid #007bff !important;
}

.store-service-tab .nav-tabs .nav-link.active:hover,
.store-service-tab .nav-tabs .nav-link.active:focus {
  border-bottom: 4px solid #007bff !important;
}

.store-service-tab .nav-tabs .nav-link:hover {
  border-bottom: 4px solid transparent;
}

.store-service-tab .nav-tabs .nav-link:hover,
.store-service-tab .nav-tabs .nav-link:focus {
  border: none;
  outline: none;
}

.service-selection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.service-list-view {
  width: 48%;
  display: flex;
  align-items: flex-start;
  padding: 30px 0px;
  border-bottom: 1px solid #ddd;
}

.service-checkbox .form-check {
  padding: 0;
}

.service-detail {
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.service-info h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.store-service-tab .service-info h3 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: capitalize;
}

.store-service-tab .service-info span {
  color: #aba9aa;
}

.store-service-tab .service-selection .service-list-view:nth-last-child(2) {
  border-bottom: 1px solid #ebebeb;
}

.store-service-tab .store-service-tab-content {
  padding: 0;
}

.store-service-tab .service-selection .service-list-view:last-child {
  border: none;
}

.store-service-tab .select-service-tab nav.nav.nav-tabs {
  width: 300px;
  border-radius: 5px;
}

.store-service-tab .select-service-tab .tab-content {
  width: calc(100% - 300px);
  padding-left: 30px;
}

.service-info p {
  color: #999;
  margin: 0;
  padding-top: 2px;
  font-size: 14px;
}

.service-cost h5 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.service-selection .service-list-view:nth-last-child(1),
.service-selection .service-list-view:nth-last-child(2) {
  border: none;
}

.service-checkbox input[type="checkbox"] + label {
  display: block;
  margin: 0;
  cursor: pointer;
  padding: 0;
}

.service-checkbox input[type="checkbox"] {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 2em;
  height: 2em;
  margin: 0;
  cursor: pointer;
}

.service-checkbox input[type="checkbox"]:focus {
  outline: none;
}

.service-checkbox input[type="checkbox"] + label:before {
  content: "\2714";
  border: 1px solid #cecece;
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
}

.service-checkbox input[type="checkbox"] + label:active:before {
  transform: scale(0);
}

.service-checkbox input[type="checkbox"]:checked + label:before {
  background-color: #ffffff;
  border-color: #000;
  color: #000;
  -webkit-text-fill-color: #000;
}

.service-checkbox input[type="checkbox"]:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}

.service-checkbox input[type="checkbox"]:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}

.slick-prev {
  left: 10px;
  background: #000000a3;
  z-index: 8;
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.slick-next {
  right: 10px;
  background: #000000a3;
  z-index: 8;
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: #fff;
  outline: 0;
  background: #000000a3;
}

.slick-prev:before {
  background: url("../../assets/partner/prev-icon.png");
  width: 10px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  margin: 0px auto;
}

.slick-next:before {
  background: url("../../assets/partner/next-icon.png");
  width: 10px;
  height: 19px;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  margin: 0px auto;
}

.store-featured-service {
  padding-bottom: 50px;
}

.featured-service-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 20px;
}

.featured-service-box {
  border: 1px dashed #e8e8e8;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 18%;
  cursor: pointer;
}

.service-icon {
  width: 75px;
  height: 75px;
  border-radius: 100px;
  background: #f7f7f7;
  line-height: 75px;
  color: #2089fe;
  font-size: 20px;
  font-weight: 600;
  margin: 0px auto;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.featured-service-box h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.featured-service-box span {
  color: #a2a2a2;
  font-size: 12px;
  display: block;
  padding-top: 5px;
}

.wishlist-page .store-listing-content {
  padding-top: 50px;
}

.store-detail a {
  color: #333;
  text-transform: capitalize;
}

.store-detail a:hover {
  text-decoration: none;
}

.store-about .book-now {
  padding: 0;
}

.error-404 .header,
.error-404 .footer {
  display: none;
}

.booknow-fix-btn .btn {
  width: 170px;
  height: 50px;
  font-size: 20px;
  transition: 0.3s all ease-in;
  box-shadow: none;
}

.booknow-fix-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  background: #fff;
  padding: 15px 120px;
  border-top: 1px solid #e0dada;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booknow-fix-btn .book-now {
  padding: 0;
}

.booknow-fix-btn h2 {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  margin-bottom: 0;
}

.page-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f7f7f7;
}

.error-content {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  max-width: 700px;
  width: 100%;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06274509803921569);
  font-size: 16px;
}

.error-content img {
  max-width: 450px;
  width: 100%;
}

.error-content h3 {
  margin-top: 20px;
}

.btn-404 > a {
  max-width: 200px;
  display: block;
  background: #262f3e;
  text-align: center;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin: 30px auto 0;
  transition: all 0.5s;
}

.btn-404 > a:hover {
  color: #fff;
  text-decoration: none;
}

.error-content > p {
  max-width: 550px;
  margin: 0 auto;
}

.error-content h1 {
  font-size: 150px;
  font-weight: 700;
  color: #262f3e;
  line-height: 130px;
}

.favorites-pagination .store-listing-view {
  padding-bottom: 0;
}

.favorites-pagination ul.pagination {
  margin-bottom: 0;
}

.signup-email {
  text-align: center;
  position: relative;
}

.signup-email i {
  position: absolute;
  left: 15px;
  top: 15px;
}

.store-services .google-btn,
.store-services .facebook-btn {
  margin-bottom: 28px;
}

div#bodyContent {
  display: flex;
  flex-wrap: wrap;
}

div#popup-img {
  width: 50%;
}

div#popup-img img {
  width: 100%;
  height: 120px;
  object-fit: cover;
}

.gm-style-iw.gm-style-iw-c {
  width: 300px;
  height: 150px;
  max-height: 150px !important;
}

div#popup-content {
  width: 50%;
  padding-left: 10px;
}

div#popup-content h1 {
  font-size: 14px;
  padding-top: 10px;
  word-break: break-all;
}

div#popup-content p {
  font-size: 12px;
  margin-bottom: 5px;
}

.gm-style-iw-d {
  max-height: 150px !important;
  height: 150px;
}

h1.placeholder-text {
  font-size: 30px;
  text-align: center;
}

div#content > a {
  color: #000;
  text-decoration: none;
}

div#popup-content p {
  /* font-family: "Nunito"; */
  font-family: "AvenirBook" !important;
}

.service-list-view {
  width: 100%;
}

/* tabs Css Start */
/* .store-service-tab .nav {
  border: 1px solid #ebebeb;
  border-radius: 5px;
  overflow: hidden;
  position: sticky; */
/* max-height: 500px;
  overflow-y: auto;
  height: auto; */
/* }
.store-service-tab .nav-pills .nav-link.active,
.store-service-tab .nav-pills .show > .nav-link {
  color: #000;
  background-color: #ebebeb;
  border-radius: 0; */
/* border-bottom: 1px solid #333; */
/* border-top: 1px solid #333; */
/* }
.store-service-tab .nav-item {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: -1px;
  width: 100%;
}
.store-service-tab .service-list-view {
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px solid #ebebeb;
}
.store-service-tab .service-info h3 {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}
.store-service-tab .nav-pills .nav-link {
  padding: 25px 20px;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.store-service-tab .nav-pills .nav-link {
  position: relative;
}
.store-service-tab .nav-pills .nav-link.active:before {
  content: "";
  position: absolute;
  height: 100%;
  background: #007aff;
  width: 4px;
  top: 0;
  left: 0;
}
.store-service-tab .service-selection .service-list-view:nth-last-child(2) {
  border-bottom: 1px solid #ebebeb;
}

.store-service-tab .service-selection .service-list-view:last-child {
  border: none;
}
.store-service-tab .tab-content {
  padding-left: 25px;
  padding-right: 10px;
  max-height: 500px;
  overflow-y: auto;
  height: auto;
}
.store-service-tab .service-info span {
  color: #aba9aa;
} */
/* width */
.store-service-tab ::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  overflow: hidden;
}

/* Track */
.store-service-tab ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.store-service-tab ::-webkit-scrollbar-thumb {
  background: #dedede;
  border-radius: 10px;
}

/* Handle on hover */
.store-service-tab ::-webkit-scrollbar-thumb:hover {
  background: #aba9aa;
}

.select-service-tab nav.nav.nav-tabs {
  border: 1px solid #f1f1f1 !important;
  padding-top: 0 !important;
}

.store-service-tab .nav-tabs .nav-link.active {
  color: #000 !important;
}

.store-service-tab .select-service-tab .nav-tabs .nav-link.active:after {
  content: "";
  width: 4px !important;
  height: 100% !important;
  position: absolute;
  border: none !important;
  left: 0;
  right: 100% !important;
  top: 0;
  background: #007bff;
}

.store-service-tab .nav-tabs .nav-link {
  top: 0 !important;
}

.store-service-tab .select-service-tab .nav-tabs .nav-link.active {
  border-bottom: 1px solid #ebebeb !important;
}

.store-service-tab .select-service-tab .nav-tabs .nav-item.nav-link {
  border-bottom: 1px solid #ebebeb !important;
  margin-bottom: -1px;
}

.custom-tabs {
  width: 100%;
  display: flex;
  align-items: center;
}

.custom-tabs .custom-tabs-left {
  width: 400px;
}

.custom-tabs .custom-tabs-right {
  width: calc(100% - 400px);
  padding-left: 30px;
}

/* For pgination Css */

ul.pagination-chk {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

ul.pagination-chk li {
  padding: 0 10px;
}

ul.pagination-chk a.pagination__link {
  width: 100px;
  border: 1px solid #262f3e;
  border-radius: 4px;
  background: #262f3e;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
}

ul.pagination-chk li > a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #333;
  border-radius: 4px;
  outline: none;
  display: inline-block;
}

li.pagination__link--active > a {
  background: #262f3e;
  color: #fff !important;
  border-color: #262f3e !important;
}

li.pagination__link--disabled a.pagination__link {
  background: #f5f5f5;
  color: #777;
  border-color: #f5f5f5;
}

.store-slider-box {
  width: 100%;
  height: 522px;
}

.slider-right-box {
  width: 33.3%;
  position: relative;
}

.slider-right-box:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+21,ffffff+100&1+0,0+100 */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.79) 21%,
    rgba(255, 255, 255, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.79) 21%,
    rgba(255, 255, 255, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.79) 21%,
    rgba(255, 255, 255, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */

  /* background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0.95) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.95) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.95) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d9ffffff', endColorstr='#8affffff', GradientType=0); */
}

.slider-right-content {
  padding: 0 32px 25px 30px;
  position: relative;
  padding-top: 130px;
  height: 100%;
}

.rate-content {
  position: absolute;
  right: 0;
  top: 0;
  align-items: flex-end;
  justify-content: space-between;
}

.rate-number {
  font-family: "AvenirBlack";
  font-size: 33px;
  background: #40c1ff;
  color: #fff;
  padding: 7px;
  border-radius: 3px;
}

.rate-text h5 {
  margin-bottom: 0;
  line-height: 1;
  font-family: "AvenirMedium";
  font-size: 27px;
  text-align: right;
}

.rate-text {
  padding-right: 10px;
}

.rate-text span {
  font-size: 19px;
  font-family: "AvenirBook";
}

.slider-right-content-box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.book-now-btn {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.book-now-btn .btn {
  min-width: 245px;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00c9ff+0,607dff+100 */
  background: rgb(0, 201, 255);
  /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(0, 201, 255, 1) 0%,
    rgba(96, 125, 255, 1) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 201, 255, 1) 0%,
    rgba(96, 125, 255, 1) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 201, 255, 1) 0%,
    rgba(96, 125, 255, 1) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00c9ff', endColorstr='#607dff', GradientType=0);
  /* IE6-9 */
  color: #fff;
  border: 0;
  padding: 6px 20px;

  font-size: 25px;
  border-radius: 10px;
}

.book-now-btn .btn:hover,
.book-now-btn .btn.active,
.book-now-btn .btn:focus {
  background: rgb(96, 125, 255);
  background: -moz-linear-gradient(
    top,
    rgba(96, 125, 255, 1) 0%,
    rgba(0, 201, 255, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    top,
    rgba(96, 125, 255, 1) 0%,
    rgba(0, 201, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(96, 125, 255, 1) 0%,
    rgba(0, 201, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#607dff', endColorstr='#00c9ff', GradientType=0);
}

.book-now-btn .btn-primary:not(:disabled):not(.disabled).active,
.book-now-btn .btn-primary:not(:disabled):not(.disabled):active,
.book-now-btn .show > .btn-primary.dropdown-toggle {
  background: rgb(92, 98, 126);
  background: -moz-linear-gradient(
    top,
    rgba(96, 125, 255, 1) 0%,
    rgba(0, 201, 255, 1) 100%
  ) !important;

  background: -webkit-linear-gradient(
    top,
    rgba(96, 125, 255, 1) 0%,
    rgba(0, 201, 255, 1) 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    rgba(96, 125, 255, 1) 0%,
    rgba(0, 201, 255, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#607dff', endColorstr='#00c9ff', GradientType=0);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: rgb(67, 179, 255);
}

.store-location-content img {
  width: 100%;
  height: 235px;
}

.store-about-content p {
  font-family: "AvenirBook";
  font-size: 18px;
  margin-bottom: 0;
  color: #363636;
  text-align: justify;
}

.store-about-content h3 .store-location-content h3 {
  font-family: "AvenirBlack";
  font-size: 30px;
}

.store-location-content p {
  font-family: "AvenirMedium";
  font-size: 15px;
}

.store-slider-container {
  overflow: hidden;
  position: relative;
  height: 522px;
}

.store-slider {
  position: relative;
}

.store-slider-box {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.store-slider-container .slick-dots {
  display: none !important;
}

.store-slider-container .store-slider img {
  height: 522px;
}

.store-slider-container .store-favorite {
  display: none;
}

.src-mid-content h2 {
  font-size: 48px;
}

.src-mid-content p {
  font-family: "AvenirMedium";
  font-size: 18px;
  line-height: 1.4;
  max-width: 250px;
  color: #313131;
}

.single-store .utf_by_comment {
  font-size: 18px;
  color: #000;
  text-transform: capitalize;
}

.store-slider-container .slick-prev:before {
  background: url("../../assets/slider-icon.png");
  width: 20px;
  height: 35px;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  margin: 0px auto;
  transform: rotate(180deg);
  background-position: center;
}

.store-slider-container .slick-next:before {
  background: url("../../assets/slider-icon.png");
  width: 20px;
  height: 35px;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  background-position: center;
}

.store-slider-container .slick-next {
  background-color: transparent;
}

.store-slider-container .slick-prev {
  background-color: transparent;
}

.store-about-content a {
  text-decoration: underline;
  font-family: "AvenirBook";
  color: #40c1ff;
  font-weight: 600;
  font-size: 19px;
}

.single-store .utf_comment_content p {
  font-size: 20px;
  color: #000;
  font-family: "avenirromanopentype_bigfontsite_com";
  text-transform: capitalize;
}

.single-store .comments .utf_comment_content {
  color: #00c5ff;
  padding: 0 0 0px 95px;
  font-family: "avenirromanopentype_bigfontsite_com";
  font-size: 20px;
}

.single-store .name-designation h3 {
  font-size: 20px;
  color: #000;
  text-transform: capitalize;
  margin-bottom: 0;
  font-family: "AvenirBlack";
  padding-right: 12px;
  position: relative;
}

.single-store .name-designation h3:before {
  content: "";
  width: 3px;
  height: calc(100% - 6px);
  position: absolute;
  right: 0;
  top: 50%;
  background: #000;
  transform: translateY(-50%);
}

.single-store .name-designation span {
  padding-left: 10px;
  font-family: "avenirromanopentype_bigfontsite_com";
  font-size: 20px;
}

.single-store .service-detail {
  align-items: flex-end;
  position: relative;
  padding-bottom: 25px;
}

.single-store .service-detail:before {
  content: "";
  width: calc(100% - 20px);
  height: 3px;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #beeaff;
}

.store-location-content iframe {
  width: 100%;
  height: 230px;
  border: 0;
}

.store-location-content a {
  font-family: "AvenirMedium";
  font-size: 19px;
  color: #40c1ff;
}

.store-location-content a:hover {
  text-decoration: none;
}

.show-time {
  position: relative;
}

.show-time li {
  padding: 6px 0;
  border-bottom: 1px dashed #ececec;
}

.show-time a {
  font-size: 15px;
}

.show-time ul {
  margin-bottom: 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
  font-family: "AvenirLight";
  padding: 15px;
  margin-top: 20px;
  position: absolute;
  left: 0;
  top: 0;
  width: 285px;
}

.time-content img {
  width: 16px;
  height: 20px;
  margin-left: 13px;
  transform: rotate(90deg);
  transition: transform 0.2s linear;
  margin-top: -5px;
  cursor: pointer;
}

.time-content.rotate-arrow img {
  transform: rotate(270deg);
  transition: transform 0.2s linear;
  margin-top: 0;
}

.store-about-content h3,
.store-location-content h3 {
  font-size: 25px;
  margin-bottom: 20px;
}

.single-store .heading-title {
  font-size: 31px;
  font-weight: 600;
  color: #000;
  padding-bottom: 0;
  margin-bottom: 0;
}

.single-store .heading-title::after {
  content: none;
}

.opening-hours-content {
  position: relative;
  max-width: 340px;
}

.opening-hours-content .opening-hours {
  position: relative;
  left: 0;
  width: 100%;
  top: 0;
}

.single-store .store-service-tab .service-info h3 {
  font-size: 28px;
  font-family: "AvenirMedium";
}

.single-store .service-info p {
  color: #363636;
  font-family: "AvenirBook";
  margin: 0;
  font-size: 19px;
}

.single-store .service-cost {
  white-space: nowrap;
}

.single-store .service-cost h5 {
  color: #00c5ff;
  font-family: "AvenirMedium";
  font-size: 20px;
}

.single-store .service-list-view {
  padding: 25px 0 0 0;
}

.time-content {
  padding-top: 10px;
}

.voucher-section-list {
  width: calc(100% + 20px);
  margin-left: -10px;
}

.single-store .voucher-section-item {
  width: 300px !important;
  padding: 0 10px;
  height: 300px;
}

.single-store
  .voucherlisting
  .my-voucher
  .voucher-section
  .voucher-card
  .voucher-body {
  padding: 15px 10px !important;
  height: 224px !important;
}

.single-store .book-now {
  padding: 10px;
}

.store-listing .store-listing-filter {
  margin: 25px 0px;
  background: transparent;
  border: 0;
  padding: 18px 15px;
}

.breadcrumb-box .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
  background: transparent;
  font-family: "avenirromanopentype_bigfontsite_com";
}

.breadcrumb-box .breadcrumb-item {
  display: flex;
  font-size: 15px;
  position: relative;
  padding-right: 10px;
}

.breadcrumb-box .breadcrumb-item + .breadcrumb-item::before {
  content: "" !important;
  width: 2px;
  height: calc(100% - 10px);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #000;
  padding-right: 0 !important;
}

.breadcrumb-box .breadcrumb-item + .breadcrumb-item {
  padding-left: 10px;
  font-size: 21px;
}

.breadcrumb-box li.breadcrumb-item a {
  color: #000;
  font-size: 21px;
}

.breadcrumb-box .breadcrumb-item.active {
  color: #43b3ff;
}

.store-listing-filter p {
  color: #000;
  font-size: 31px;
}

.store-listing .store-listing-filter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.breadcrumb-box {
  margin-bottom: 10px;
}

.store-listing .store-filter-box {
  font-family: "AvenirBook";
}

.store-listing .store-filter-box .dropdown .btn {
  font-size: 19px;
  color: #000 !important;
}

.store-listing
  .store-filter-box
  .dropdown
  .btn-primary:not(:disabled):not(.disabled):active,
.store-listing
  .store-filter-box
  .dropdown
  .show
  > .btn-primary.dropdown-toggle {
  color: #000 !important;
}

.venues-section a:hover {
  text-decoration: none;
  color: inherit;
}

.venues-section a {
  text-decoration: none;
  color: inherit;
}

.venues-section {
  width: 100%;
}

.store-listing .switch .slider {
  background: #666;
}

.store-listing .switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.store-listing .switch input:checked + .slider {
  background-color: #2196f3;
}

span.counter-text {
  margin-right: 10px;
}

.single-store .comments .avatar {
  padding: 0 20px 0 0px;
}

.single-store .comments .avatar img {
  border: 3px solid #4ce5ff;
  height: 95px;
  width: 95px;
}

.single-store .utf_star_rating_section {
  right: 0;
}

.single-store .comments ul li {
  margin: 0;
}

.single-store .comments ul {
  margin-top: 20px;
}
.single-store
  .voucherlisting
  .my-voucher
  .voucher-section
  .voucher-card
  .voucher-body {
  padding: 15px 10px !important;
  height: 224px !important;
}
ul.pagination-chk li > a {
  width: auto;
  height: 40px;
  line-height: 40px;
  border: 0;
  font-size: 21px;
  border-radius: 4px;
  outline: none;
  display: inline-block;
  font-family: "avenirromanopentype_bigfontsite_com";
}

li.pagination__link--active > a {
  background: transparent;
  color: #b4e6ff !important;
}

li.previous.pagination__link--disabled {
  display: none;
}

ul.pagination-chk li {
  padding: 0 6px;
}

li.previous.pagination__link--disabled {
  opacity: 0.3;
}

li.next.pagination__link--disabled {
  display: none;
}

li.pagination__link--disabled a.pagination__link {
  background: transparent;
  border: 0;
}

ul.pagination-chk a.pagination__link {
  width: 40px;
  border: 0;
  border-radius: 4px;
  background: transparent;
  /* color: rgb(207, 207, 207); */
  color: inherit;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
}

li.previous a img {
  transform: rotate(180deg);
  margin-top: 2px;
}

a.pagination__link img {
  width: 10px;
  height: 20px;
  margin-top: -5px;
}

.voucher-section-item .voucher-body-box > h4 {
  margin-bottom: 0 !important;
}

.voucher-section-list {
  width: calc(100% + 20px);
  margin-left: -10px;
}

.new-customer-login .google-btn button {
  padding: 7px 15px !important;
}

.new-customer-login .google-btn button span {
  font-size: 14px !important;
}

.new-customer-login .facebook-btn button.metro {
  padding: 14px 13px !important;
  font-size: 14px;
}

.new-customer-login .signup-email a {
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.new-customer-login .signup-email a:hover {
  background: transparent !important;
  color: #262f3e !important;
  border-color: #262f3e !important;
}

.new-customer-login .store-services .google-btn,
.new-customer-login .store-services .facebook-btn {
  margin-bottom: 15px;
}
