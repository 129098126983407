.forget-password-section {
  background: #d7ecf8;
  min-height: 100vh;
}
.forget-section {
  padding: 80px 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.forget-form {
  padding: 40px 37px 40px 37px;
  max-width: 486px;
  margin: 0px auto;
  background: #fff;
  border-radius: 20px;
  /* box-shadow: rgb(16 25 40 / 8%) 0px 4px 8px 0px; */
  box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
  height: 100% !important;
  min-height: 100% !important;
  /* background: #fff;
  padding: 50px 30px;
  max-width: 500px;
  margin: 0px auto;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: rgba(16, 25, 40, 0.08) 0px 4px 8px 0px;
  height: 100% !important;
  min-height: 100% !important; */
}

.forget-form h1 {
  font-size: 27px;
  text-align: center;
  font-weight: 600;
  color: #00adff;
  margin-bottom: 0;
}

.forget-form h1 span {
  color: #018bcf;
}

.form-group .form-control {
  /* height: 40px;
  font-size: 14px;
  padding: 5px 15px;
  box-shadow: none !important; */
  height: 40px;
  font-size: 14px;
  padding: 5px 15px;
  box-shadow: none !important;
  font-size: 18px !important;
  color: #000 !important;
  font-family: "AvenirRoman";
  font-weight: inherit;
  border-radius: 10px;
  height: 50px;
  padding: 6px 15px;
}
.form-group .number-fields .form-control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.forget-form p {
  text-align: center;
  font-size: 14px;
  color: #000;
  margin-bottom: 50px;
  margin-top: 15px;
}

.forget-box {
  max-width: 400px;
  margin: 0px auto;
}

.forget-form .reset-btn {
  background: #262f3e;
  border: none;
  transition: all 0.5s;
  padding: 12px 40px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  border-radius: 4px;
  margin: 0px auto;
  display: block;
  width: 100%;
}

.reset-box {
  max-width: 400px;
  margin: 50px auto;
  margin-bottom: 0;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background: #018bcf;
  box-shadow: none;
  border: none;
}

.btn-primary:hover {
  color: #fff;
  background: #018bcf;
  box-shadow: none;
  border: none;
}

.forget-form .form-group {
  position: relative;
}

.forget-form .form-group i {
  position: absolute;
  top: 13px;
  right: 10px;
}

.forget-form .form-group .form-control {
  padding-right: 30px;
  height: auto;
  padding: 7px 20px;
  border: 1px solid #dadada;
  border-radius: 10px;
  font-size: 12px !important;
  /* color: #a2a2a2; */
  color: #000;
  font-family: "AvenirLight";
}
