.notification-section {
  position: absolute;
  top: 64px;
  right: -25px;
  z-index: 999;
  background-color: white;
  box-shadow: 0px 0px 10px #6d6e6d;
  max-width: 350px;
  width: 100%;

  border-radius: 10px;
  /* overflow-x: hidden; */
}
.notification-section:before {
  content: "";
  margin-top: 0px;
  left: 50%;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid #fff;
  position: absolute;
  top: -18px;
}
.notification {
  padding: 20px 10px;
}
.notify {
  overflow: auto;
  max-height: 420px;
  margin-top: 5px;
  height: auto;
}
.notification h6 {
  color: black;
  font-size: 18px;
  padding-top: 20px;
  cursor: pointer;
}

.notification .cancel {
  color: black;
  right: 10px;
  position: absolute;
  top: 5px;
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.notification .heading {
  display: flex;
  justify-content: space-between;
}

.heading {
  border-bottom: 1px solid #c4c2c2;
}

.notification-row {
  padding: 5px;
  border-bottom: 1px solid #e5e5e5;
}
.notification-row p {
  color: white;
  text-align: right;
  font-size: 10px;
  margin-bottom: 0;
  margin-top: -10px;
}
.profile-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px;
  cursor: pointer;
}
.notification-row img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.notification-row h5 {
  font-size: 13px !important;
  color: #777;
  margin-left: 10px;
  margin-bottom: 3px;
  font-weight: 600;
  text-align: left;
}

.notify::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.notify::-webkit-scrollbar {
  width: 0px;
  background-color: #f5f5f5;
}

.notify::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

.notify-day {
  color: #ffd366;
  margin-top: 5px;
}
.notify-btn .btn {
  margin: 0px 5px;
  /* display: block; */
  background: #ffd366;
  border-color: #ffd366;
  font-size: 14px;
  letter-spacing: 0.5px;
  padding: 2px 10px;
  text-shadow: 1px 0px 2px #000;
}
.notify-btn {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.notification-area .badge {
  padding: 2px 2px !important;
  border-radius: 50px !important;
  min-width: 10px !important;
  display: inline-block !important;
  text-align: center !important;
  font-size: 12px !important;
  padding: 0px 5px !important;
  position: absolute !important;
  top: -9px !important;
  right: -9px !important;
  color: #fff !important;
  background-color: #9553ff !important;
}
.notification-area {
  position: relative;
  margin: 0 15px;
}
.admin-notify .notification-section:before {
  content: "";
  margin-top: 0px;
  left: 45%;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid #fff;
  position: absolute;
  top: -18px;
}

.profile-text span.date > i {
  padding: 0 5px;
}

.profile-text span.date {
  padding-left: 10px;
  margin-left: auto;
  font-size: 12px;
}
