.appointment-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
  position: relative;
  margin-bottom: 20px;
  min-height: 520px;
}

.appointment-body {
  padding: 20px 25px;
}

/************* Search Customer *****************/

.customer-search {
  padding: 20px 25px;
}

.customer-search h4 {
  font-size: 16px;
  font-weight: 700;
}

.customer-search .form-inline .form-control {
  width: 100%;
  padding-right: 40px;
  height: auto;
  padding: 8px 15px;
  border-radius: 8px 8px 0 0;
}

.customer-search .form-inline .btn-primary {
  width: 30px;
  padding: 0;
  background: transparent;
  border: none;
  color: #999;
  position: absolute;
  right: 5px;
  top: 6px;
  font-size: 18px;
}

.customer-search .form-inline .btn-primary:focus {
  box-shadow: none;
  outline: none;
}

.customer-search .form-inline {
  position: relative;
}

.search-items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.search-icon img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
}

.search-content h4 {
  margin: 0;
}

.search-content p {
  margin: 0;
  color: #999;
}

.search-container-user {
  border: 1px solid #ddd;
  margin-top: -1px;
}

.search-container-user .search-items:last-child {
  border-bottom: none;
}

.search-content {
  padding-left: 15px;
}

.no-customer {
  border: 1px dashed #ddd;
  padding: 20px 30px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.no-customer img {
  width: 70px;
  margin-bottom: 10px;
}

.no-customer p {
  margin: 0;
  color: #999;
}

.search-container-user a {
  display: block;
  text-align: center;
  padding: 10px 10px;
  border-bottom: 1px solid #ddd;
  color: #0175ff;
}

.search-container-user a:hover {
  text-decoration: none;
}

/************* Footer Buttons ********/

.appointment-buttons {
  padding: 20px 25px;
  border-top: 1px solid #ddd;
  text-align: center;
  margin-top: 20px;
}

.appointment-buttons span {
  font-size: 16px;
  font-weight: 700;
}

.footer-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
}

.footer-button .btn {
  width: 48%;
  padding: 10px 15px;
  border-radius: 8px;
  font-family: "AvenirHeavy";
}

.footer-button .border-btn {
  background: transparent;
  color: #999;
  border: 1px solid #ddd;
}

.footer-button .border-btn:hover {
  background: transparent;
  color: #999;
  border: 1px solid #ddd;
}

.footer-button .appointment-btn {
  background: #8147dd !important;
  border-color: #8147dd !important;
}
/* .view-appointment-invoice-page .footer-button a {
  color: #fff;
} */
/* .view-appointment-invoice-page .footer-button a:hover {
  color: #fff !important;
} */

.footer-button .appointment-btn:hover {
  background: #8147dd;
  border: 1px solid #8147dd !important;
}

.footer-button .dropdown {
  width: 48%;
}

.footer-button .dropdown .btn {
  width: 100%;
  background: transparent;
  color: #000;
  border: 1px solid #ddd;
}

.footer-button .dropdown .btn:hover,
.footer-button .dropdown .btn:focus {
  background: transparent;
  color: #000;
  border: 1px solid #ddd;
}

.footer-button .show > .btn-primary.dropdown-toggle,
.footer-button .btn-primary:not(:disabled):not(.disabled):active {
  background: transparent !important;
  color: #000 !important;
  border: 1px solid #ddd !important;
}

.footer-button .dropdown-item,
.footer-button a {
  padding: 12px 10px 11px !important;
  color: #000;
  font-size: 14px;
  display: block;
  line-height: 16px;
}

.footer-button .dropdown-item:hover,
.footer-button a:hover {
  color: #0175ff;
}

/********* Appointment Form ************/

.add-appointment-form h4 {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  padding-bottom: 20px;
  color: #8147dd;
  font-family: "AvenirBlack";
}

.add-appointment-form textarea.form-control {
  height: 120px;
}

.add-more {
  cursor: pointer;
  font-weight: 600;
  color: #2577ff;
  font-size: 15px;
  background: #ffffff;
  padding: 10px 0px;
  border-radius: 4px;
}

/***************** Customer Detail ***************/

.customer-detail-appoint {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 20px 25px;
}

.customer-detail-icon img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  object-fit: cover;
}

.customer-detail-content h4 {
  font-weight: 700;
  font-size: 18px;
  margin: 0;
}

.customer-detail-content p {
  margin: 0;
  color: #000;
}

.customer-detail-content {
  padding-left: 15px;
  padding-right: 40px;
}

.customer-detail-action {
  position: absolute;
  right: 0;
  top: 20px;
}

.customer-detail-action .appointment-action {
  position: relative;
}

.appointment-action .dropdown-item a,
.appointment-action .dropdown-item {
  color: #999;
}

/**************** Customer Tab *******************/

.customer-details-activities.staff-page .nav-tabs .nav-link {
  width: 50% !important;
}

.customer-details-activities.staff-page .tab-content {
  padding: 0;
  overflow-y: auto;
  height: 165px;
}

.customer-detail-appointment .customer-appoint-box {
  padding: 20px 0px;
}

.customer-detail-appointment .appoint-box-time {
  width: 110px;
  padding: 0px 10px;
}

.customer-detail-appointment .appoint-box-name {
  padding: 0;
}

.customer-detail-appointment .appoint-box-name h4 {
  font-size: 16px;
}

.customer-detail-appointment .customer-appoint-box {
  border-bottom: 1px solid #efefef;
}

.customer-detail-appointment .appoint-box-price {
  padding: 0px 10px;
  width: 80px;
}

.customer-detail-appointment .appoint-box-price h6 {
  font-size: 16px;
}

.customer-detail-appointment .customer-appoint-box:last-child {
  border-bottom: none;
}

.customer-invoice-list {
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0px;
}

.customer-detail-invoice .customer-invoice-list:last-child {
  border-bottom: none;
}

.customer-detaili-invoice h4 {
  font-size: 16px;
  font-weight: 700;
  color: #333;
  margin: 0;
}

.customer-detaili-invoice p {
  display: block;
  color: #999;
  font-size: 12px;
  margin: 0;
}

.customer-detaili-invoice-status h4 {
  font-size: 16px;
  font-weight: 700;
  color: #333;
  margin: 0;
}

.customer-detaili-invoice-status {
  text-align: center;
  padding: 0px 20px;
}

.customer-detaili-invoice {
  padding: 0px 20px;
}

.customer-invoice-list .cancelled-badge,
.customer-invoice-list .completed-badge,
.customer-invoice-list .pending-badge {
  font-size: 11px;
  min-width: 80px;
}

/********** View Appointment **************/

.select-status {
  padding: 20px 25px;
  background: #f6f7fb;
}

.select-status .form-group {
  margin: 0;
}

.select-status .form-control {
  border-radius: 4px;
  max-width: 200px;
  margin: 0px auto;
}

.no-invoice {
  border: 1px dashed #ddd;
  padding: 20px 30px;
  text-align: center;
  margin: 20px 25px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.no-invoice img {
  width: 70px;
  margin-bottom: 10px;
}

.no-invoice p {
  margin: 0;
  color: #999;
}

.view-appointment-form h4 {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  padding-bottom: 20px;
}

.view-appointment-list .appoint-box-name {
  padding-left: 0;
}

.view-appointment-list .appoint-box-name h4 {
  padding: 0;
  font-size: 18px;
  font-weight: 700;
}

.view-appointment-list {
  padding-bottom: 10px;
  border-bottom: 1px dashed #ddd;
  margin-bottom: 30px;
}

.appointment-history h4 {
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  padding-bottom: 5px;
}

.appointment-history p {
  color: #000;
}

/**************** View Invoice **************/

.view-invoice-form {
  border: 1px solid #ddd;
  max-width: 400px;
  width: 100%;
  margin: 0px auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.view-invoice-form .table {
  margin: 0;
}

.view-invoice-form .table thead th {
  border: none;
  padding: 5px 20px;
}

.view-invoice-form .table thead th h4 {
  font-weight: 700;
  margin: 0;
  padding-bottom: 5px;
  padding-top: 20px;
  text-align: center;
}

.view-invoice-form .table thead th span {
  color: #999;
  font-weight: 400;
  display: block;
}
.view-invoice-form .table thead tr th h5 {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
.view-invoice-form .table thead tr th h6 {
  font-size: 16px;
  margin-left: 10px;
  font-weight: 700;
  color: #444;
}

.view-invoice-form .table tbody tr td h5 {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  /* white-space: nowrap; */
}

.view-invoice-form .table tbody tr td p {
  margin: 0;
  color: #999;
  max-width: 350px;
}

.view-invoice-form .table tbody tr td {
  padding: 15px 20px;
}

.view-invoice-form .table tfoot {
  text-align: center;
}
.view-invoice-form .table tfoot h4 {
  font-weight: 600;
  margin: 0;
}
.view-invoice-form .table tfoot h6 {
  margin: 0;
  margin-top: -5px;
  font-weight: 500;
}

/**********CHECKOUT PAGE**********/

.redeem-popup {
  display: flex;
  flex-flow: column;
  width: 100%;
}
.select-categories .select-list .redeem-popup p {
  color: #000;
}
.redeem-popup .btn {
  margin-top: 10px;
}
.pay-status {
  padding: 20px 25px;
}
.pay-status input#formBasicEmail {
  border-radius: 30px;
}
.Cash-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}
.cash-btn button + button {
  margin-left: 0px;
}
.cash-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}
/* .Cash-buttons span.spinner-border.spinner-border-sm {
  align-self: flex-end;
} */
.Cash-buttons .cash-btn span {
  margin-bottom: 15px;
}
.Cash-buttons button.appointment-btn.btn.btn-primary {
  padding: 8px;
  width: 100%;
  border: transparent;
  margin-bottom: 10px;
}
.other-btn button.appointment-btn.btn.btn-primary {
  color: #333;
  border: none;
  width: 354px;
  padding: 8px;
  margin-top: 20px;
  background-color: #d6d6d6;
}
.other-btn {
  text-align: center;
}
.checkout-appointment-page .footer-button .dropdown {
  width: 100%;
}
.total-d {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.balance-d {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ccc;
}
.total-output {
  max-width: 40%;
  margin-left: auto;
  margin-top: 10px;
  padding: 10px;
}
.total-d h4 {
  padding-bottom: 4px !important;
  font-size: 15px;
}
.balance-d h4 {
  padding-top: 4px !important;
  font-size: 15px;
}
.total-d span {
  font-weight: 700;
}
.balance-d span {
  font-weight: 700;
}
.service-details {
  margin-top: 8px;
}
.checkout-appointment-page .view-appointment-list {
  margin-bottom: 0;
  padding-bottom: 0;
  margin-right: 10px;
}

.checkout-appointment-page label {
  color: #333;
}

.pay-status label {
  font-weight: 700;
  font-size: 16px;
}
.Cash-buttons > div {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}
.Cash-buttons button.appointment-btn.btn.btn-primary:last-child {
  float: right;
}

.checkout-appointment-page .customer-appoint-box {
  padding-bottom: 0;
}

.scrollbar {
  height: 313px;

  overflow-y: auto;

  overflow-x: hidden;
}

.checkout-appointment-page .view-appointment-list:last-child {
  border-bottom: none;
}

.checkout-appointment-page .view-appointment-form h4 {
  padding-bottom: 10px;
}

.clientt:hover {
  border-bottom: 1px solid #e4e6ea;
  background-color: #e4e6ea;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 1px;
  cursor: pointer;
}

.clientt:focus {
  border-bottom: 1px solid #e4e6ea;
  background-color: #e4e6ea;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 1px;
  cursor: pointer;
}
.clientt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e4e6ea;
}

.calendar-content-view {
  background: #fff;
  padding: 30px;
  border-radius: 25px;
}

.calendar-associate {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.calendar-associate select {
  width: 25%;
  padding: 9px 10px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.calendar-filter {
  padding: 10px 0px 40px;
}

.calendar-date .schedule-date-toolbar {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0;
  max-width: 330px;
}

.calendar-date .schedule-date-toolbar .btn-default.active,
.calendar-date .schedule-date-toolbar .btn-default:active {
  border: #fff;
}

.fc .fc-button-group > .fc-button {
  text-transform: capitalize;
}

.calendar-content .fc-today-button.fc-button.fc-button-primary {
  text-transform: capitalize;
}

.calendar-content .fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 10px 4px;
  color: #333;
  cursor: pointer;
}
.calendar-content .fc .fc-timegrid-slot-label {
  padding: 5px 4px;
}

.calendar-content .fc .fc-timegrid-axis-cushion,
.calendar-content .fc .fc-timegrid-slot-label-cushion {
  padding: 5px 4px;
}

.calendar-content .fc .fc-timegrid-axis-cushion {
  text-transform: capitalize;
  font-size: 12px;
}

.calendar-content .fc .fc-col-header-cell-cushion:hover {
  text-decoration: none;
}

.calendar-content .fc .fc-timegrid-divider {
  padding: 0;
  border: none;
}

.calendar-days {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.calendar-days select {
  width: 48%;
  padding: 9px 10px;
  height: auto;
  border: 1px solid #ddd;
}

.calendar-days a {
  width: 20%;
  font-size: 22px;
  padding: 6px 10px;
  text-align: center;
}

.calendar-content .fc .fc-toolbar-title {
  font-size: 20px;
  font-weight: 700;
}

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(2) {
  color: transparent;
  position: relative;
}

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(2)::before {
  content: "Prev";
  color: #373a3c;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* .rbc-day-slot .rbc-event-content {
  height: auto;
  min-height: auto;
  font-size: 12px;
}
.rbc-day-slot .rbc-event {
  max-height: 100%;
  min-height: 35px;
  margin-top: -40px;
  font-size: 13px;
  left: 5px !important;
} */
.calender-popup.modal .modal-body {
  text-align: center;
  font-size: 18px;
}
.appointment-content .appoint-box-name span {
  display: inline-block;
}
.subtotal {
  float: right;
  max-width: 200px;
  width: 100%;
  border-bottom: 1px solid #000;
  margin: 20px 0;
}
.subtotal-upper,
.subtotal-lower {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.appoint-box-price h6 span {
  text-decoration: line-through;
  color: #a99f9f;
  font-size: 18px;
}
.search-container-user .search-user-list {
  overflow: auto;
  max-height: 250px;
  height: 100%;
}
.cash-btn .appointment-btn.btn {
  background: #8147dd;
  border-color: #8147dd;
}
.cross-btn {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.cancle-popup .modal-title {
  font-size: 18px;
}
.popup-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.popup-btn .btn,
.popup-btn .btn:hover,
.popup-btn .btn:focus {
  margin: 5px;
  background: #262f3e;
  color: #fff;
  border-color: #262f3e;
  min-width: 100px;
}
button.cancel-appointment.btn.btn-primary {
  background-color: #8f9090;
  border: 1px solid #8f9090;
}
span.error-msg {
  color: #ff0000;
}
