/* @import "https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800&display=swap"; */
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";

@font-face {
  font-family: "AvenirBlack";
  src: url("../src/assets/fonts/AvenirBlack.eot");
  src: url("../src/assets/fonts/AvenirBlack.eot") format("embedded-opentype"),
    url("../src/assets/fonts/AvenirBlack.woff2") format("woff2"),
    url("../src/assets/fonts/AvenirBlack.woff") format("woff"),
    url("../src/assets/fonts/AvenirBlack.ttf") format("truetype"),
    url("../src/assets/fonts/AvenirBlack.svg#AvenirBlack") format("svg");
}

@font-face {
  font-family: "AvenirMedium";
  src: url("../src/assets/fonts-medium/AvenirMedium.eot");
  src: url("../src/assets/fonts-medium/AvenirMedium.eot")
      format("embedded-opentype"),
    url("../src/assets/fonts-medium/AvenirMedium.woff2") format("woff2"),
    url("../src/assets/fonts-medium/AvenirMedium.woff") format("woff"),
    url("../src/assets/fonts-medium/AvenirMedium.ttf") format("truetype"),
    url("../src/assets/fonts-medium/AvenirMedium.svg#AvenirMedium")
      format("svg");
}
@font-face {
  font-family: "AvenirRoman";
  src: url("../src/assets/fonts/AvenirLTStd-Roman.eot");
  src: url("../src/assets/fonts/AvenirLTStd-Roman.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/AvenirLTStd-Roman.woff2") format("woff2"),
    url("../src/assets/fonts/AvenirLTStd-Roman.woff") format("woff"),
    url("../src/assets/fonts/AvenirLTStd-Roman.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirLight";
  src: url("../src/assets/fonts-light/AvenirLight.eot");
  src: url("../src/assets/fonts-light/AvenirLight.eot")
      format("embedded-opentype"),
    url("../src/assets/fonts-light/AvenirLight.woff2") format("woff2"),
    url("../src/assets/fonts-light/AvenirLight.woff") format("woff"),
    url("../src/assets/fonts-light/AvenirLight.ttf") format("truetype"),
    url("../src/assets/fonts-light/AvenirLight.svg#AvenirLight") format("svg");
}

@font-face {
  font-family: "avenirromanopentype_bigfontsite_com";
  src: url("../src/assets/fonts-roman/avenirromanopentype_bigfontsite_com.eot");
  src: url("../src/assets/fonts-roman/avenirromanopentype_bigfontsite_com.eot")
      format("embedded-opentype"),
    url("../src/assets/fonts-roman/avenirromanopentype_bigfontsite_com.woff2")
      format("woff2"),
    url("../src/assets/fonts-roman/avenirromanopentype_bigfontsite_com.woff")
      format("woff"),
    url("../src/assets/fonts-roman/avenirromanopentype_bigfontsite_com.ttf")
      format("truetype"),
    url("../src/assets/fonts-roman/avenirromanopentype_bigfontsite_com.svg#avenirromanopentype_bigfontsite_com")
      format("svg");
}

@font-face {
  font-family: "AvenirBook";
  src: url("../src/assets/fonts-avenir-book/AvenirBook.eot");
  src: url("../src/assets/fonts-avenir-book/AvenirBook.eot")
      format("embedded-opentype"),
    url("../src/assets/fonts-avenir-book/AvenirBook.woff2") format("woff2"),
    url("../src/assets/fonts-avenir-book/AvenirBook.woff") format("woff"),
    url("../src/assets/fonts-avenir-book/AvenirBook.ttf") format("truetype"),
    url("../src/assets/fonts-avenir-book/AvenirBook.svg#AvenirBook")
      format("svg");
}

@font-face {
  font-family: "avenirromanopentype_bigfontsite_com";
  src: url("../src/assets/fonts-roman/avenirromanopentype_bigfontsite_com.eot");
  src: url("../src/assets/fonts-roman/avenirromanopentype_bigfontsite_com.eot")
      format("embedded-opentype"),
    url("../src/assets/fonts-roman/avenirromanopentype_bigfontsite_com.woff2")
      format("woff2"),
    url("../src/assets/fonts-roman/avenirromanopentype_bigfontsite_com.woff")
      format("woff"),
    url("../src/assets/fonts-roman/avenirromanopentype_bigfontsite_com.ttf")
      format("truetype"),
    url("../src/assets/fonts-roman/avenirromanopentype_bigfontsite_com.svg#avenirromanopentype_bigfontsite_com")
      format("svg");
}

@font-face {
  font-family: "AvenirHeavy";
  src: url("../src/assets/havy-fonts/AvenirHeavy.eot");
  src: url("../src/assets/havy-fonts/AvenirHeavy.eot")
      format("embedded-opentype"),
    url("../src/assets/havy-fonts/AvenirHeavy.woff2") format("woff2"),
    url("../src/assets/havy-fonts/AvenirHeavy.woff") format("woff"),
    url("../src/assets/havy-fonts/AvenirHeavy.ttf") format("truetype"),
    url("../src/assets/havy-fonts/AvenirHeavy.svg#AvenirHeavy") format("svg");
}
@font-face {
  font-family: "AvenirRegular";
  src: url("../src/assets/fonts/AvenirNextLTProRegular.eot");
  src: url("../src/assets/fonts/AvenirNextLTProRegular.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/AvenirNextLTProRegular.woff2") format("woff2"),
    url("../src/assets/fonts/AvenirNextLTProRegular.woff") format("woff"),
    url("../src/assets/fonts/AvenirNextLTProRegular.ttf") format("truetype");
}

body {
  /* background: #d7ecf8!important; */
  margin: 0;
  font-family: "AvenirBlack" !important;
  font-size: 14px !important;
}
