/**********SetUP*******************/
.account-card {
  margin-bottom: 30px;
}

.account-card h2 {
  line-height: 28px;
  margin: 0;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  color: #555;
}
.account-card a {
  font-weight: 600;
  margin-bottom: 0px;
  display: block;
  font-size: 22px;
  color: #000000;
  padding: 15px 20px;
}
.account-card .card-body {
  padding: 0;
}
.account-card .card {
  border: 1px solid #f7f7f8;
  padding: 10px;
  box-shadow: 0 0px 5px 0 rgb(164 173 186 / 8%);
  margin-bottom: 20px;
}
.account-card .card-title.h5 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}
.tax-head img {
  width: 10%;
}
.btn-group-toggle .active1 {
  color: #fff !important;
  background-color: #262f3e !important;
}

.account-card a:hover {
  text-decoration: none;
  color: #333;
}

.input-group-prepend .fa {
  font: normal normal normal 14px/1 FontAwesome;
}

.account-setting textarea {
  height: 120px !important;
}

/*********************ACCOUNT SETTING*******************/

p.text-12 {
  background-color: #eef0f2;
  padding: 14px 16px;
  text-align: left;
  border-radius: 2px;
}
.info-card {
  margin-bottom: 30px;
}

.account-save {
  padding: 0px 40px 30px !important;
}

.account-setting .card-body {
  padding-bottom: 0;
}

.back-setup h5 {
  font-weight: 600;
}
.account-setting .input-group-text {
  /* display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem !important;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef !important;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem !important;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #8147dd !important;
  border: 1px solid #8147dd;
  border-radius: 10px 0 0 10px;
  width: 50px;
}
.back-setup {
  background: #fff;
  border-radius: 25px;
}

.account-setting .card:hover {
  box-shadow: none;
}
.account-setting .card {
  border: none;
  padding: 10px;
  box-shadow: none;
}

.account-save {
  text-align: right;
}
.account-save .btn {
  background-color: #262f3e;
  color: #fff;
  border-color: #262f3e;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.account-save .btn:hover {
  border: 1px solid #262f3e;
}

.invoice-tab-content {
  padding: 30px;
  background: #fff;
  border-radius: 20px;
}

.invoice-tab-content .card {
  border: none;
}

.invoice-tab-content .card .card-body {
  padding: 10px;
}

.invoice-tab-content .online-booking {
  padding: 0;
}

.invoice-tab-content .input-group-prepend {
  align-items: center;
  margin: 10px 0px;
}

.invoice-tab-content .input-group-prepend .form-label {
  margin: 0;
  padding-left: 5px;
}
/*********************INVOICE*******************/

.invoice input[type="checkbox"] {
  margin-top: -8px !important;
  margin-right: 10px;
  width: 25px;
  height: 18px;
}

/*********************INVOICE-SEQUENCE*******************/

.invoice-sequence table tr td span {
  cursor: pointer;
}

.invoice-sequence .partner-table .btn.btn-primary {
  background-color: #8147de;
  color: #fff;
  border-color: #8147de;
}

.invoice-sequence .partner-table .btn.btn-primary:hover {
  border: 1px solid #8147de;
}

.discount-type .btn-primary {
  background-color: #8147de;
  color: #fff;
  border-color: #8147de;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.discount-type .btn-primary {
  border: 1px solid #8147de;
}

.modal,
.discount-12 {
  /* font-family: "Nunito", sans-serif !important; */
  font-family: "AvenirBook" !important;
}

.discount-12 .btn-primary {
  border: 1px solid #8147de;
}

/*********************TAXES*******************/
.taxes {
  padding: 30px 20px;
  background: #fff;
  border-radius: 20px;
}

.taxes .btn-primary {
  background-color: #8147de;
  color: #fff;
  border-color: #8147de;
  padding: 5px 15px;
  font-size: 22px;
  box-shadow: 0px 3px 10px #00000038;
  border-radius: 8px;
  font-weight: inherit;
  min-width: auto !important;
  font-family: "AvenirBlack";
}

.taxes .btn-primary:hover {
  border: 1px solid #8147de;
}

.taxes .partner-table .btn-primary {
  margin-left: 5px;
  padding: 0.375rem 0.75rem;
  font-size: 22px;
}

.taxes h5 {
  margin: 0;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  color: #555;
}
.cus-12 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tax-head span.mb-5 {
  display: inline-block;
}
.default-tax {
  border-top: 1px solid #eee;
  padding-top: 50px;
}
.tax-head {
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0 30px 0;
}

.tax-head i.fa.fa {
  font-size: 60px;
  color: #999;
  margin-bottom: 10px;
}
.taxes p {
  color: #999;
}
.tax-show .card {
  background: #f7f7f7;
  border-color: #f3f0f0;
}

.tax-show .card h5 {
  padding-bottom: 5px;
}

.tax-calculation span {
  cursor: pointer;
}

.tax-sale {
  background: #262f3e;
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.tax-cal-box {
  display: flex;
  align-items: flex-start;
  padding: 20px 0px;
}

.cal-box-detail h4 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding-bottom: 5px;
  color: #555;
}

.cal-box-detail p {
  margin: 0;
  color: #999;
}

.cal-box-detail {
  padding-left: 15px;
}

.tax-cal-box .online-booking {
  padding: 0;
}

.tax-calculation-popup .modal-body {
  padding: 25px;
}

.tax-calculation-popup .btn-primary {
  background-color: #262f3e;
  color: #fff;
  border-color: #262f3e;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.tax-calculation-popup .btn-primary:hover {
  border: 1px solid #262f3e;
}

.tax-calculation-popup .modal-dialog {
  max-width: 600px;
}

/*********************PAYMENT TAXES MODAL*******************/

.pay-12 .modal-dialog {
  max-width: 570px !important;
}

.payment-tax {
  padding: 30px 20px;
  background: #fff;
  border-radius: 20px;
}

.payment-tax .btn-primary {
  background-color: #8147dd;
  color: #fff;
  border-color: #8147dd;
  padding: 10px 20px;
  font-size: 22px !important;
  box-shadow: 0px 3px 10px #00000038;
  font-family: "AvenirBlack" !important;
  border-radius: 8px;
}

.payment-tax .btn-primary:hover {
  border: 1px solid #8147dd;
}

.payment-tax .partner-table .btn-primary {
  margin-left: 5px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-family: "AvenirHeavy";
}

.payment-tax .partner-table table thead th:last-child {
  width: 150px;
}

.pay-12 .btn-primary {
  background-color: #262f3e;
  color: #fff;
  border-color: #262f3e;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.pay-12 .btn-primary:hover {
  border: 1px solid #262f3e;
}

.discount-12 .btn-primary {
  background-color: #262f3e;
  color: #fff;
  border-color: #262f3e;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.discount-12 .btn-primary:hover {
  border: 1px solid #262f3e;
}

/*********************DISCOUNT TYPE*******************/

.dic-info {
  box-shadow: 0 8px 15px 5px rgba(164, 173, 186, 0.2);
  background-color: #eef0f2;
  border-radius: 4px;
  overflow: hidden;
}

.dic-info .card {
  border: 1px solid #fff;
}

/*********************DISCOUNT TYPE MODAL*******************/

.location-listing {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.location-listing .location-box {
  width: 23%;
  cursor: pointer;
  margin: 1%;
}

.location-listing .location-box img {
  width: 100%;
  object-fit: cover;
}

.location-listing .location-box .service-body {
  padding: 15px;
}

.location-listing .location-box h4 {
  font-weight: 700;
  font-size: 20px;
  padding-top: 15px;
  margin: 0;
  color: #333;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.location-listing .location-box p {
  color: #000;
  margin: 0;
  padding-top: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.location-listing .location-box a:hover,
.location-listing .location-box a:focus {
  text-decoration: none;
}

.add-location-link {
  background-color: #262f3e;
  color: #fff !important;
  border-color: #262f3e;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
  border-radius: 4px;
}

.discount-12 input[type="checkbox"] {
  margin-top: -10px !important;
  margin-right: 10px;
  width: 20px;
  height: 18px;
}

.discount-12 .row .input-group-text {
  padding: 0.375rem 0.75rem !important;
  background-color: #e9ecef !important;
  border: 1px solid #ced4da !important;
}

/**************** LOCATION ***************************/

.location-page .form-group .number-fields .dropdown .btn {
  padding: 10px 20px;
  line-height: 1.3;
  border-right: none !important;
  font-size: 14px;
}

.location-page .text-right .btn-primary {
  background-color: #262f3e;
  color: #fff;
  border-color: #262f3e;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.location-page .text-right .btn-primary {
  border: 1px solid #262f3e;
}

.location-detail {
  background: #f3f3f3;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px 10px 25px;
  margin: 20px 0px;
}

.location-info label {
  font-size: 16px;
  font-weight: 700;
  color: #333;
  margin: 0;
}

.location-detail p {
  margin: 0;
}

.location-info {
  width: 25%;
  padding: 20px 20px 0px;
}

.location-page .service-body {
  padding: 25px 25px;
}

.availability-location h4 {
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  color: #555;
}

.availability-location {
  padding: 20px 0px;
}

.availability-location .row {
  align-items: center;
}

.availability-box .online-booking {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

.availability-box {
  display: flex;
  width: 30%;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 20px;
}

.availability-box-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.availability-box .online-booking {
  width: 100%;
  justify-content: space-between;
}

.availability-box .form-control {
  width: 49%;
  border-radius: 8px;
}

.availability-box .online-booking .switch {
  margin-right: 0;
  margin-left: 10px;
}

.location-search-input-,
.location-search-input-success {
  height: 40px;
  font-size: 16px;
  padding: 25px 15px;
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 8px;
}

.location-search-input-:focus,
.location-search-input-success:focus {
  outline: none;
}
.autocomplete div {
  padding: 10px;
}

.upload__image-wrapper {
  display: grid;
  justify-content: center;
  padding: 50px 25px;
  border: 2px dashed lightgray;
  margin: 5px 0px;
  border-radius: 5px;
  width: 100%;
  background-color: #f5f5f54d;
}
.upload__image-wrapper button {
  background-color: transparent;
  border: none;
  font-size: 15px;
  font-weight: 900;
  color: #262f3e;
}
.upload__image-wrapper button:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
.upload__image-wrapper i {
  font-size: 40px;
  color: #262f3e;
}
.uploadimg {
  text-align: center;
}
.remove-image {
  font-size: 15px;
}
.image-item-section {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
}
.image-item {
  width: calc(25% - 20px);
  margin-bottom: 30px;
  margin-right: 15px;
}
.image-item img {
  width: 100%;
  height: 145px;
  object-fit: cover;
}
.image-item__btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 10px;
}
.image-item__btn-wrapper .online-booking {
  justify-content: flex-end;
}
.image-item__btn-wrapper button {
  background-color: #262f3e;
  color: white;
  border: 1px solid #262f3e;
  margin: 5px;
  padding: 5px 7px;
  border-radius: 5px;
}
.image-item-section {
  margin-bottom: 50px;
  margin-top: 20px;
}
.upload__image-wrapper .remove-image {
  background-color: transparent;
  border: none;
  font-size: 13px;
  font-weight: 900;
  color: #b52c46 !important;
  margin-top: 20px;
}
.uploaad h4 {
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  color: #000;
}
.remove-wrapper-btn {
  background-color: #262f3e !important;
  border: 1px solid #262f3e !important;
  color: #fff !important;
}
.location-btn {
  margin-top: 32px;
  background-color: #2d2c33;
  /* text-align: -webkit-left; */
  border: 1px solid #2d2c33;
  border-radius: 5px;
}
.add-btn {
  font-size: 15px;
  cursor: pointer;
  color: #262f3e;
  font-weight: 600;
}
.category-popup textarea {
  height: 80px !important;
}

.category-popup .location-btn {
  margin-top: 0;
  background-color: #262f3e;
  color: #fff;
  border-color: #262f3e;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}
.category-popup .location-btn:hover {
  margin-top: 0;
  border: 1px solid #262f3e;
}
.category-popup.add-loc .modal-body {
  padding: 10px 20px;
}
.billing-location .location-info {
  width: 33%;
}
.edit-bill-btn {
  font-size: 15px;
  cursor: pointer;
  color: #262f3e;
  font-weight: 600;
  position: absolute;
  right: 20px;
  top: 10px;
  margin: 0;
}
.billing-location .edit-bill-btn {
  font-size: 15px;
  cursor: pointer;
  color: #262f3e;
  font-weight: 600;
  position: absolute;
  right: 20px;
  top: 10px;
  margin: 0;
}
.location-detail .edit-bill-btn {
  font-size: 15px;
  cursor: pointer;
  color: #262f3e;
  font-weight: 600;
  position: absolute;
  right: 35px;
  top: 30px;
  margin: 0;
}
.billing-box {
  position: relative;
}
.billing-location h4 {
  padding-top: 20px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  color: #000;
}

/******** Referral **********/

.referral {
  position: relative;
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
}
.referral .admin-title button {
  border: 1px solid transparent;
}
.referral .btn-primary:hover {
  border: 1px solid transparent;
}

.active-badge {
  background: #8147dd;
  padding: 2px 10px;
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  font-family: "AvenirRoman";
}
.disablebutton button.btn.btn.btn-primary {
  background-color: transparent;
  color: black;
  border: 1px solid #e9ecef;
}
.disablebutton button:focus {
  outline: none;
  box-shadow: none;
}
.partner-table table thead th:last-child {
  text-align: left;
}
.partner-table table tbody td:last-child {
  text-align: left;
  white-space: nowrap;
}
.partner-table table thead th:nth-child(2) {
  text-align: left;
}
.partner-table table tbody td:nth-child(2) {
  text-align: left;
}

.referral .partner-table .btn-primary {
  margin-left: 5px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
}
.referral .partner-table table thead th:nth-child(3) {
  text-align: center;
}
.referral .partner-table table tbody td:nth-child(3) {
  text-align: center;
}

.service-body.refers {
  padding: 15px 0px;
}
.service-body.refers .online-booking {
  text-align: right;
}
.delete-btn {
  background-color: #8147dd !important;
  border-color: #8147dd !important;
  border: 1px solid #8147dd !important;
}
.voucher-btn.btn.btn-primary {
  background-color: #262f3e;
  border-color: #262f3e;
}
/* .btn-primary {
  color: #fff;
  background-color: #262f3e !important;
  border-color: #262f3e !important;
} */
.Toastify__toast--success {
  background: #000 !important;
}
.Toastify__toast--error {
  background: #000 !important;
}
.text-muted {
  color: #000 !important;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: #8147dd !important;
  color: #fff !important;
  border: 1px solid #8147dd !important;
}
.dropdown .btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: inherit !important;
  color: inherit !important;
  border: 1px solid transparent !important;
}
.admin-title .btn.btn-primary {
  background: #9552fe;
  border-color: #9552fe;
}
.location-search-input-::placeholder,
.location-search-input-success::placeholder {
  color: #000;
}
textarea.form-control::placeholder {
  color: #000;
}
.verify-identity {
  position: relative;
  padding: 30px 20px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
  text-align: center;
  width: 100%;
  /* margin: 15vh auto; */
}
.verify-btn {
  margin-top: 30px;
  text-align: right;
}
.verify-btn .btn-primary,
.verify-btn .btn-primary:hover,
.verify-btn .btn-primary:focus {
  background: #262f3e;
  border: 1px solid #262f3e;
  box-shadow: none;
}
.verify-identity h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: left;
  color: #8147dd;
  font-family: "AvenirBlack";
}
/* .CardField--ltr .CardBrandIcon-container:not(.is-hidden)+.CardField-input-wrapper {
  left: 2em;
  margin-bottom: 30px;
  border: 1px solid red !important;
  padding: 8px;
} */

/* For StripeElement Css  */

.StripeElement {
  box-sizing: border-box;

  height: 50px;

  padding: 14px 12px;

  border: 1px solid #ced4da;
  border-radius: 8px;
  background-color: white;

  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.error-box.form-control {
  border-color: #fa755a;
}
.stripe-box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.stripe-box .form-group {
  width: 48%;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
  margin-bottom: 10px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.container.Stripe {
  padding: 45px;
}

.container.Stripe form {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.container.Stripe form div#card-element {
  width: 100%;
}

.container.Stripe button {
  padding: 10px 30px;
  margin-top: 10px;
}

.container.Stripe .form-row {
  width: 100%;
}

.container.Stripe .form-row label {
  margin-bottom: 5px;
}
.number {
  margin-bottom: 30px;
}
.card-errors {
  padding-top: 20px;
  color: #e53935;
}
button.btn.subb-p {
  margin-bottom: 35px;
}
.container.Stripe form {
  max-width: unset !important;
}
.number-emp {
  width: 60%;
}
button.amount-77 {
  margin-left: auto;
}
.form-check .pay-77 .form-check-sign .check {
  width: 30px;
  height: 20px;
  border: 1px solid rgba(247, 243, 243, 0.54);
}
.form-check .pay-77 .form-check-sign .check:before {
  margin-left: 12px;
}
.form-check .pay-77 .form-check-input:checked ~ .form-check-sign .check {
  background: #2b2a2b;
}
.card .form-check .pay-77 {
  margin-top: 0px;
}
.form-check.pay-77 .form-check-sign .check {
  width: 30px;
  height: 20px;
  border: 2px solid rgba(247, 243, 243, 0.54);
}
.form-check.pay-77 .form-check-sign .check:before {
  margin-top: -4px;
  margin-left: 11px;
}
.form-check.pay-77 .form-check-input:checked ~ .form-check-sign .check {
  background: #151414;
}

.amount-77 .form-check {
  position: unset;
  height: 0;
  margin: 0;
}

.amount-77 .form-check label.form-check-label {
  position: unset;
}

.amount-77 .form-check span.form-check-sign {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.amount-77.active:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 0px 4px #c5c5c5;
}
.number-emp {
  width: 100%;
  margin-bottom: 20px;
}
.amount-77 {
  margin-left: 10px !important;
}

.Stripe .card > p {
  padding-left: 30px;
  padding-right: 30px;
}

.Stripe button.btn.btn-danger.pull-right {
  margin-bottom: 15px;
}
.sdfsd3 {
  padding: 0 15px !important;
}

.sdfsd3 .btn.btn-danger.btn-round {
  float: right;
  margin-bottom: 15px;
}
.Stripe .form-row {
  flex-direction: column;
  padding: 0 20px;
}
.Stripe .form-row div#card-element {
  border-color: #e2e2e2;
}

.discount-12 .row .discount-type-checkbox .input-group-text {
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
}
.discount-type-checkbox.form-group {
  margin: 10px 0;
}

.payment-stripe .btn {
  display: block;
  margin: 0px auto;
  margin-top: 20px;
}

.payment-stripe {
  max-width: 100%;
  margin: 0;
  /* background: #fff;
  border-radius: 5px; */
  /* padding: 50px; */
  /* text-align: center; */
  width: 100%;
  margin-top: 30px;
}
.payment-stripe .btn-primary,
.payment-stripe .btn-primary:hover,
.payment-stripe .btn-primary:focus {
  display: block;
  margin: 0px auto;
  margin-top: 20px;
  background: #262f3e;
  border: 1px solid #262f3e;
}

.error {
  color: red;
}
.payment-stripe-container {
  max-width: 100%;
  margin: 0;
  background: #fff;
  border-radius: 5px;
  padding: 30px 50px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
}

/* step form pricing */

.pricing-package-section h1 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #8147dd;
}

.feature-listing {
  margin-top: 30px;
}

.feature-list-one h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  position: relative;
}
.feature-list-one h6::before {
  font-family: "FontAwesome";
  content: "\f046";
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: -30px;
  top: 2px;
  border-radius: 50%;
}

.feature-list-one {
  padding-left: 30px;
  position: relative;
  margin-bottom: 30px;
}
.feature-list-one p {
  color: #757171;
  font-size: 14px;
}

.pricing-section h1 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #8147dd;
  font-family: "AvenirBlack";
}
.pricing-row h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  position: relative;
}
.pricing-row p {
  color: #757171;
  font-size: 14px;
}
.pricing-row {
  margin-top: 20px;
}
.pricing-section {
  margin-top: 50px;
}
.payment-stripe-container .pricing-section {
  margin-top: 0px;
}
.step-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.step-btn .back-btn {
  background-color: transparent;
  border: 1px solid #262f3e;
  color: black;
  padding: 8px 25px;
  border-radius: 5px;
  margin-right: 10px;
  line-height: 20px;
}

.step-btn .continue-btn {
  background-color: #262f3e;
  border: 1px solid #262f3e;
  border-radius: 5px;
}
.details-form {
  margin-top: 40px;
}
.save-card-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.save-card-btn {
  margin-left: 15px;
}
.save-card-btn button {
  margin-right: 0px !important;
}
.card-price-details.pricing-section {
  margin-top: 0px;
}
.check-profile {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 20px;
}
.check-profile input {
  height: 18px;
  width: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid gainsboro;
}
.check-profile input:focus {
  outline: none;
  box-shadow: none;
}
.check-profile h6 a {
  padding-left: 15px;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
  color: black;
}
.check-profile .form-group {
  margin-bottom: 0px;
}
.check-option h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 25px;
  text-transform: capitalize;
}

.check-profile .form-group input:checked::after {
  font-family: "FontAwesome";
  content: "\f046";
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  font-size: 24px;
  color: rgb(23, 172, 23);
}
.check-profile .form-group input:checked {
  border: none !important;
}

.pricing-package .step-btn {
  justify-content: flex-end;
}
.pricing-package .step-btn a {
  color: white;
}
/* online booking page */
.online-booking-tab {
  padding: 30px 20px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
}
.online-booking-tab .nav-tabs .nav-link {
  color: #808286 !important;
  font-size: 16px;
  font-weight: 600;
  border: none;
}
.online-booking-tab .nav-tabs .nav-link.active {
  color: #262f3e !important;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 3px solid #262f3e;
  font-weight: 700;
}
.online-booking-tab .nav-tabs {
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-top: -10px;
  padding: 0px 20px;
}
.listing-profile {
  width: calc(33% - 30px);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0px 2px 0 rgb(16 25 40 / 10%);
  padding: 20px 20px;
  margin: 15px;
}

.listing-profile .top-side img {
  /* width: 120px;
  height: 120px;
  border-radius: 50%; */
  object-fit: cover;
  width: 100%;
}

.listing-profile .top-side {
  margin: 0px auto;
  width: 100%;
  text-align: center;
  position: relative;
}
.listing-profile .bottom-side {
  margin-top: 20px;
}
.listing-profile .profile-name h6 span {
  font-size: 12px;
  padding-left: 5px;
  color: #007bff;
}

.listing-profile .profile-name h6 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
}
/* .listing-profile .profile-name {
  text-align: center;
} */
.listing-profile .profile-name p {
  color: #8e939a;
  font-weight: 400;
  font-size: 12px;
}
.listing-profile .profile-view-btn button,
.listing-profile .profile-view-btn button:hover,
.listing-profile .profile-view-btn button:focus {
  width: 100%;
  background-color: #262f3e;
  color: #262f3e;
  font-weight: 400;
  border: 1px solid #262f3e;
  color: white;
  font-size: 15px;
}
.listing-profile .profile-view-btn {
  margin-top: 30px;
}
.listing-profile .address-info h6 {
  font-size: 14px;
  color: #a99d9d;
  font-weight: 400;
}
.listing-profile .name-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listing-profile .name-status h5 {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 5px;
  margin-bottom: 0px;
}
.online-profile-page {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.client-review-page .comments ul li {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0px 2px 0 rgb(16 25 40 / 10%);
  border: none;
}
.client-review-page .comments .avatar img {
  width: 105px;
  height: 105px;
  border: 5px solid rgba(0, 0, 0, 0.06);
  border-radius: 50px;
  object-fit: cover;
}
.client-review-page .comments .utf_comment_content {
  color: #666;
  padding: 0 0 0px 140px;
}
.client-review-page .store-review {
  padding: 40px 0px;
}
.overview-page {
  margin-top: 40px;
  background: antiquewhite;
  padding-bottom: 50px;
}

/* .right-overview img {
  width: 100%;
} */

/* .info-section-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 120px 0px;
}
.right-overview {
  width: 60%;
  margin-left: auto;
  position: absolute;
  right: 0px;
  top: 90px;
}
.left-overview {
  width: 70%;
  z-index: 999;
  position: relative;
}
.main-content-overview {
  background-color: #262f3e99;
  padding: 20px;
  border-radius: 10px;
  color: white;
}
.start-now button, .start-now button:hover, .start-now button:focus {
  background-color: #262f3e;
  border: 1px solid #262f3e;
  padding: 10px 25px;
  border-radius: 5px;
  font-weight: 500;
}

.start-now {
  margin-top: 40px;
}
*/
.start-now {
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
}
.start-now button,
.start-now button:hover,
.start-now button:focus {
  background-color: #262f3e;
  border: 1px solid #262f3e;
  padding: 10px 30px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 18px;
}
.main-content-overview p {
  font-size: 17px;
  text-align: left;
  color: #524e4e;
}

.main-content-overview h1 {
  font-size: 40px;
  font-weight: 900;
  margin: 20px 0px;
  margin-top: 0px;
  line-height: 40px;
}
.main-content-overview h6 {
  font-size: 25px;
  font-weight: 700;
}
.right-overview {
  width: 30%;
  display: flex;
  justify-content: space-around;
}
.left-overview {
  width: 60%;
}
.info-section-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px;
  padding: 80px 40px;
  transition: all ease 0.5s;
}
.info-section-one:hover .right-overview img {
  margin-right: -15px;
  transition: all ease 0.5s;
}
.right-overview img {
  width: 100%;
  width: 640px;
}
.info-image img {
  width: 100%;
  margin-top: -20px;
  transition: all ease 0.5s;
}
.info-section-two .info-image {
  width: 40%;
  margin: 0px auto;
}
.info-section-two {
  padding: 40px 40px;
  padding-top: 0px;
}
.info-section-two:hover .info-image img {
  margin-top: 0px;
  transition: all ease 0.5s;
}
.info-content-area.main-content-overview {
  text-align: center !important;
  margin-top: 40px;
}
.info-content-area.main-content-overview p {
  text-align: center;
}
.info-section-three .right-overview img {
  width: 100% !important;
}
.start-now.overview-btn {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
  padding-top: 0px;
}

.main-content-overview.bottom-text {
  text-align: center;
}
.listing-option .dropdown-toggle::after {
  display: none;
}
.listing-option {
  position: absolute;
  right: 0px;
}

.listing-option button,
.listing-option button:hover,
.listing-option button:focus {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.listing-option button i {
  font-size: 24px;
}

.profile-view-btn a {
  background-color: #262f3e;
  color: #fff !important;
  border-color: #262f3e;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
  border-radius: 4px;
  display: block;
  margin: 0px auto;
  text-align: center;
  text-decoration: none;
}
.ofline {
  background-color: #00000061;
  color: #1e1f20;
}
.online {
  background-color: #1cc5116e;
  color: #178e17;
}
.step-btn .btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #262f3e;
  border-color: #262f3e;
  cursor: not-allowed;
}
.check-profile p {
  font-size: 14px;
  margin-left: 15px;
  color: #262f3e;
  font-size: 16px;
  font-weight: 700;
}
.percent {
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
  font-weight: 900;
}

.profile-progress .progress {
  width: 60%;
  height: 30px;
  font-size: 16px;
}

.profile-progress {
  margin: 0px auto;
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
.profile-progress .progress-bar {
  color: #ffffff;
  font-weight: 800;
}

.circle-progress svg.CircularProgressbar {
  height: 250px;
  margin: 30vh auto;
}
.circle-progress .CircularProgressbar .CircularProgressbar-path {
  stroke: #40a716;
}
button.edit-refferal.edit-btn.btn.btn-primary {
  border: 1px solid #262f3e;
  background: #262f3e !important;
}
.details-box .CircularProgressbar {
  width: auto;
  vertical-align: middle;
  width: 40px;
}
.details-box {
  text-align: center;
}
.details-box h6 {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 700;
}
.details-box {
  text-align: center;
  margin-bottom: 30px;
  /* background-color: #b1b3b72e; */
  padding: 15px 5px;
  border-radius: 8px;
  border: 1px dashed #ced0d4;
}
.details-box .CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 25px;
  font-weight: bold;
}
.details-box .CircularProgressbar .CircularProgressbar-path {
  stroke: #008bcd;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}
.payment-stripe-container a {
  color: #fff !important;
  text-align: center;
  text-decoration: none;
}

.payment-stripe-container .step-btn {
  justify-content: flex-end !important;
}

/* ********************* New design ********************* */

.invoice.staff-page.new-invoice {
  font-family: "AvenirBlack" !important;
}
.page-title h3 {
  font-size: 30px;
  color: #8147dd;
  margin-bottom: 30px;
  font-family: "AvenirBlack";
}
.staff-page .nav-tabs {
  border-bottom: none !important;
}
.staff-page.new-invoice .nav-tabs .nav-tabs {
  border-bottom: none;
}
.staff-page.new-invoice .nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #8147dd !important;
  border-color: #8147dd !important;
  border-bottom: 2px solid transparent !important;
}
.staff-page.new-invoice .nav-tabs .nav-link {
  font-size: 22px;
  font-weight: inherit;
  width: 25% !important;
  text-align: center;
  padding: 0.5rem 1rem;
  color: #fff;
  background: #e0e0e0;
  border-radius: 8px;
}
.staff-page.new-invoice .nav-tabs .nav-link + .nav-link {
  margin-left: 15px;
}
.staff-page.new-invoice .nav-tabs .nav-link.active:hover,
.staff-page.new-invoice .nav-tabs .nav-link.active:focus {
  border-bottom: none !important;
}
.staff-page.new-invoice .tab-content {
  padding: 30px 0 0;
}

.new-invoice .form-label {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  font-size: 18px;
  color: #000;
  font-family: "AvenirHeavy";
  font-weight: inherit;
  text-transform: capitalize;
}
.new-invoice .form-group .form-control {
  box-shadow: none !important;
  font-size: 18px !important;
  color: #000 !important;
  font-family: "AvenirRoman";
  font-weight: inherit;
  border-radius: 10px;
  height: 50px;
  padding: 6px 15px;
}
.new-invoice .form-group .form-control::placeholder {
  color: #a2a2a2 !important;
  text-transform: capitalize;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #8147dd !important;
}
.rigister-tab .number-fields .dropdown .btn {
  height: auto !important;
}

.add-category .btn-perple:hover {
  border: 1px solid #8147dd !important;
}
