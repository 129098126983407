.loader-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-190px, -50%);
  bottom: 0;
  width: 60px;
  height: 60px;
  z-index: 99999;
  /* -webkit-animation: spin 2s linear infinite; /* Safari */
  /* animation: spin 2s linear infinite; */
}
.loader-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overlay {
  position: fixed;
  top: 0;
  left: 250px;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(225, 225, 225, 0.5); */
  z-index: 99999;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
