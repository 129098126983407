.revenue .tab-content {
  padding: 0;
}

.revenue .nav-tabs {
  background: #fff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.revenue-filter {
  padding: 30px;
  background: #fff;
  border-bottom-left-radius: 5px;
  margin-bottom: 30px;
  border-bottom-right-radius: 5px;
}

.revenue-body {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  height: 100%;
}

.revenue-body h4 {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 5px;
  margin: 0;
  color: #9551fd;
  font-family: "AvenirBook";
}
.revenue-table .table thead th {
  border: none;
  padding: 0.75em;
  white-space: nowrap;
}

.revenue-table .table {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.revenue-table .table td {
  border: none;
}

.revenue-table .table .bold-text td {
  font-weight: 700;
}

.completed,
.valid {
  background: #262f3e;
  color: #fff;
  padding: 2px 10px;
  border-radius: 30px;
  min-width: 100px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
}
.badge {
  padding: 6px 20px !important;
  border-radius: 5px !important;
  min-width: 100px !important;
  display: inline-block !important;
  text-align: center !important;
  font-size: 18px !important;
  line-height: inherit !important;
}
.badge.started {
  background: #0ccab8;
  color: #fff;
}
.badge.new {
  background: #0093ef;
  color: #fff;
  padding: 5px 10px;
}
.badge.cancelled {
  background: #f74969;
  color: #fff;
}
.badge.completed {
  background: #e9dff9;
  color: #000;
}
.refund {
  background: #e93a3a;
  color: #fff;
  padding: 2px 10px;
  border-radius: 30px;
  min-width: 100px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
}

.revenue-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 9;
}
.filter-left-block {
  max-width: calc(100% - 300px);
  width: 100%;
}
/* .filter-left-block {
  width: 50%;
}

.filter-right-block {
  width: 50%;
} */

.revenue-search {
  max-width: 300px;
  width: 100%;
}
button:focus {
  outline: none;
}
.filter-left-block .schedule-date-toolbar {
  max-width: 267px;
  border: 1px solid #d9d8d8;
  border-radius: 8px;
}

.filter-left-block .schedule-date-toolbar .btn-default.active,
.filter-left-block .schedule-date-toolbar .btn-default:active,
.filter-left-block .schedule-date-toolbar .btn-default.active:focus,
.filter-left-block .schedule-date-toolbar .btn-default:active:focus,
.filter-left-block .schedule-date-toolbar .btn-default:active:hover {
  border-color: #f1f1f1;
}

.filter-right-block .header-buttons .btn:before {
  display: none;
}
.filter-right-block .header-buttons .btn.btn-primary:after {
  content: "\f019";
  font-family: "FontAwesome";
  display: inline-block;
  font-weight: 100;
  vertical-align: middle;
  border: none;
}

.filter-right-block .header-buttons .btn-default {
  background: transparent;
  color: #000;
  border: 1px solid #c5c5c5;
  font-weight: 600;
}
.filter-right-block .header-buttons .btn-default:hover {
  color: #fff;
}
.filter-left-block .btn.btn-default.navigate i {
  color: #000;
}

.revenue-search form {
  display: flex;
  align-items: center;
}

.revenue-search form .btn {
  background: transparent;
  border: none;
  color: #5a5a5a;
  position: absolute;
  left: 0;
}

.revenue-search form {
  position: relative;
}

.revenue-search form .form-control {
  padding: 20px;
  padding-left: 40px;
  font-size: 15px !important;
  max-width: 300px;
  border-radius: 8px;
}

.revenue-search form .form-control::placeholder {
  color: #000 !important;
}

.calendar-box-view {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.calendar-box {
  padding: 10px 20px;
  font-size: 15px !important;
  max-width: 300px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
}

.calendar-filter-content {
  margin-right: 20px;
}

.calendar-box span {
  color: #999;
}

.calendar-box i {
  color: #333;
  margin-left: 5px;
}

.appointments-filter {
  position: relative;
}

.filter-items {
  background: #fff;
  max-width: 500px;
  width: 100%;
  position: absolute;
  top: -100px;
  box-shadow: 0px 5px 10px #0000001f;
  border-radius: 5px;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
}

.filter-items.filter-expand {
  opacity: 1;
  top: -70px;
  z-index: 9;
  visibility: visible;
}

.filter-items form {
  padding: 30px 20px;
}

.filter-items form label {
  font-weight: 600;
  color: #333;
}

.filter-buttons .btn-default {
  border: 1px solid #ddd;
  background: #fff;
  min-width: 100px;
  margin-right: 10px;
}

.filter-buttons .btn-primary {
  border: 1px solid #262f3e;
  background: #262f3e;
  min-width: 100px;
}

.filter-buttons {
  margin-top: 15px;
}

.filter-left-block span.display-date {
  color: #000;
}

.filter-left-block .btn.btn-default.hidden-xs.navigate.active {
  color: #000;
}

.sidebar-items {
  position: fixed;
  width: 300px;
  top: 0;
  right: -300px;
  height: 100vh;
  background: #fff;
  z-index: 99;
  box-shadow: 0px 5px 15px #0000000f;
  transition: all 0.5s;
}

.sidebar-items.sidebar-expand {
  right: 0px;
}

.sidebar-title {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #efeeee;
  margin-bottom: 0px;
}

.sidebar-title span {
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.sidebar-title span img {
  width: 20px;
}

.sidebar-title h2 {
  margin: 0;
  font-size: 25px;
  font-weight: 600;
  color: #9551fd;
}

.sidebar-items form {
  padding: 20px;
}

.sidebar-items .filter-buttons .btn {
  width: 48%;
}

.sidebar-items form label {
  font-weight: 600;
  color: #333;
}

.dark-btn .dropdown-menu.show button {
  display: block;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  color: #555;
  background-color: transparent;
  border: none;
}

.dark-btn .dropdown-menu.show a {
  display: block;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  color: #555;
  background-color: transparent;
  border: none;
}
.dark-btn.gbu6.dropdown .dropdown-menu button {
  display: block;
}

.dark-btn.gbu6.dropdown .dropdown-menu > button,
.dark-btn.gbu6.dropdown .dropdown-menu > span {
  width: 100%;
  display: block;
  text-align: left;
}
.revenue.staff-page .nav-tabs .nav-link {
  width: 20% !important;
}
.revenue-table {
  /* max-height: 500px; */
  /* overflow: auto; */
}
@media screen and (max-width: 767px) {
  .revenue .nav-tabs {
    white-space: nowrap;
    flex-wrap: inherit;
    overflow-x: auto;
  }

  .revenue.staff-page .nav-tabs .nav-link {
    width: auto !important;
  }

  .filter-left-block {
    width: 100%;
    margin-bottom: 10px;
  }
  .filter-right-block {
    width: 100%;
  }

  .revenue-search {
    margin-bottom: 10px;
  }

  .filter-right-block .header-buttons {
    display: flex;
    justify-content: center;
  }

  .filter-left-block .schedule-date-toolbar {
    margin: 0;
  }

  .revenue-filter {
    padding: 20px;
  }

  .calendar-filter-content {
    margin-bottom: 10px;
    margin-right: 0;
    width: 100%;
  }
  .filter-left-block .schedule-date-toolbar .btn-group {
    align-items: center;
  }

  .filter-left-block .btn.btn-default.hidden-xs.navigate.active {
    background: transparent;
  }

  .filter-left-block span.display-date {
    font-size: 14px;
  }
}

.disable-cursor {
  cursor: not-allowed;
  pointer-events: none;
}
.disable-cursor i {
  color: #d2d2d2;
  pointer-events: none;
  cursor: not-allowed;
  /* margin-top: 10px; */
  font-size: 16px;
}

.dark-btn.gbu6.dropdown .dropdown-menu a:hover {
  background-color: #f6f7fb;
}
.dark-btn.gbu6.dropdown .dropdown-menu span:hover {
  background-color: #f6f7fb;
}
.dark-btn.gbu6.dropdown .dropdown-menu button:hover {
  background-color: #f6f7fb;
}
.revenue-body .table td a.appoi-id {
  color: #262f3e;
  font-size: 12px;
  font-weight: 700;
}
.revenue-table p {
  text-align: center;
  font-size: 20px;
}
.modal-footer .btn.btn-primary {
  background: #262f3e;
  border-color: #262f3e;
}

@page {
  @bottom-center {
    content: "Page " counter(page) " of " counter(pages);
    font-size: 8pt;
  }
}
