.all-services {
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f7;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #f2f2f7;
}

.orange {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
}
.add-voucher .input-group-prepend {
  padding: 0px 10px;
  border: 1px solid #ced4da;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.switch-sec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.limit-sale {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.add-voucher-btn {
  background-color: #9552fe;
  color: #fff !important;
  border-color: #9552fe;
  padding: 6px 20px;
  font-size: 22px;
  /* box-shadow: 0px 3px 10px #00000038; */
  border-radius: 8px;
  display: inline-block;
  font-family: "AvenirHeavy";
}

.add-voucher .switch-sec {
  justify-content: flex-start;
}

.add-voucher-btn:hover {
  color: #fff !important;
  text-decoration: none;
}

.choose-color {
  display: flex;
  justify-content: flex-start;
}

.color-input {
  margin-right: 20px;
  position: relative;
}

.color-input input[type="radio"] {
  width: 40px;
  height: 40px;
  margin: 0;
}

.color-input input[type="radio"]::before {
  content: "";
  width: 40px;
  height: 40px;
  display: block;
  cursor: pointer;
  border-radius: 100%;
}

.color-input .form-check {
  padding: 0;
}

.color-input.orange-color input[type="radio"]::before {
  background: linear-gradient(
    -45deg,
    rgb(230, 100, 34) 0%,
    rgb(255, 190, 31) 100%
  );
}

.color-input.blue-color input[type="radio"]::before {
  background: linear-gradient(-45deg, rgb(57, 5, 92) 0%, rgb(48, 4, 77) 100%);
}

.color-input.green-color input[type="radio"]::before {
  background: linear-gradient(-45deg, rgb(12, 129, 27) 0%, rgb(3, 88, 17) 100%);
}

.color-input.dark-color input[type="radio"]::before {
  background: linear-gradient(-45deg, rgb(32, 32, 32) 0%, rgb(22, 22, 22) 100%);
}

.add-voucher textarea {
  height: 102px !important;
}

.voucher-btn {
  background-color: #262f3e;
  color: #fff;
  border-color: #262f3e;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.voucher-btn:hover {
  border: 1px solid #262f3e;
}
.all-services span a {
  cursor: pointer;
}

.select-servicespopup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgray;
  padding: 10px;
}
.select-servicespopup .input-group-prepend span.input-group-text {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin-right: 10px;
}
.select-servicespopup .input-group-prepend .form-label {
  margin-top: 7px;
}
.select-servicespopup p {
  font-weight: 900;
  font-size: 15px;
}

.voucher-listvalue {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 55px;
}
.voucher-box h4 {
  text-align: center;
  font-size: 15px;
  font-weight: 800;
  color: #262f3e;
  margin-top: 10px;
}
.voucher-box h3 {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #6e6e6f;
  margin-top: -5px;
  word-break: break-all;
}
.vouchername p {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #6e6e6f;
  margin-top: -5px;
}
.edit-delete {
  display: flex;
  justify-content: flex-end;
}
.edit-delete button {
  margin: 0px 5px;
}
.voucher-listvalue p {
  font-size: 17px;
  font-weight: 700;
}
.voucher-listing {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.voucher-listing .voucher-box {
  width: 32%;
  cursor: pointer;
  margin: 0.5%;
}
.voucher-listing .voucher-box .service-body {
  padding: 15px;
}
.voucher-box a:hover {
  text-decoration: none;
}
.vouchername {
  padding: 10px 0px;
  border-bottom: 1px solid #efefef;
}
.retail {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  padding: 10px 0px;
}
.voucherbtn {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.voucherbtn a {
  color: #fff !important;
  background-color: #262f3e !important;
  border-color: #262f3e !important;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.voucherbtn button {
  color: #fff !important;
  background-color: #9552fe !important;
  border-color: #9552fe !important;
  font-size: 18px !important;
  padding: 8px 15px !important;
}

.add-voucher .all-services .btn,
.add-voucher .all-services .btn :focus {
  border: none;
  background-color: transparent;
  color: #262f3e;
  box-shadow: none;
}

.vouchervalue {
  padding: 0px 20px;
}

.voucherbtn .add-voucher-btn {
  background-color: #9552fe !important;
  border-color: #9552fe !important;
  font-size: 18px;
  font-family: "AvenirBlack";
}
.voucherbtn .add-voucher-btn.voucher-edit-btn {
  background-color: #9552fe !important;
  border-color: #9552fe !important;
  font-size: 18px;
  font-family: "AvenirBlack";
}

.voucherbtn button:hover {
  border: 1px solid #9552fe;
}

.voucher-tab.staff-page .nav-tabs .nav-link {
  width: 50% !important;
}

.view-voucher-content {
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 350px;
  margin: 15px auto;
  background: #262f3e;
  color: #fff;
  position: relative;
}

.view-voucher-content:before,
.view-voucher-content:after {
  content: "";
  background: #fff;
  width: 80px;
  height: 80px;
  display: block;
  border-radius: 100%;
  position: absolute;
}

.view-voucher-content:before {
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
}

.view-voucher-content:after {
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
}

.view-voucher-content img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.view-voucher-content h4 {
  color: #fff;
  padding-top: 10px;
  margin: 0;
}

.voucher-value {
  color: #fff;
  padding: 20px 0px;
  border-top: 1px solid #2e3848;
  border-bottom: 1px solid #2e3848;
  margin-top: 20px;
  margin-bottom: 20px;
}

.voucher-value h3 {
  margin: 0;
  font-weight: 700;
}

.voucher-value span {
  display: block;
  padding-bottom: 5px;
}

.vouhcer-code p {
  margin: 0;
  padding: 5px;
}

.voucherbtn .voucher-disable {
  position: relative;
  cursor: not-allowed;
  background-color: #afaaaa !important;
  border: 1px solid #afaaaa !important;
  pointer-events: none;
}

.voucherbtn .voucher-disable span {
  pointer-events: none;
}

.voucherbtn .voucher-disable > * {
  cursor: not-allowed;
}
.select-service-popup .noservice-found h3 {
  font-size: 20px;
  text-align: center;
}
.select-service-popup .noservice-found {
  width: 100%;
  text-align: center;
}

.voucher .voucher-listing {
  width: 100%;
  align-items: inherit;
  justify-content: inherit;
}
.voucher .voucher-listing .voucher-box a {
  /* width: 100%; */
  height: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
  position: relative;
  margin-bottom: 20px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.voucher .voucher-listing .voucher-box a .service-card {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  position: relative;
  margin-bottom: 0;
}
/* .voucher .vouchername.disc {
  margin-top: auto;
}
.voucher .vouchername {
  height: 100%;
} */
.voucher .voucher-listing .voucher-box .service-body {
  padding: 15px 15px 25px;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}
.voucher .vouchername.disc {
  height: 100%;
}
.voucher .voucherbtn {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: not-allowed;
  margin-top: auto;
  padding-top: 20px;
}
.voucher .voucherbtn a {
  align-items: center;
  margin-bottom: 0 !important;
}
.number-fields.location-field .react-tel-input {
  width: 100px;
}
.number-fields.location-field .react-tel-input .form-control.location-num {
  width: calc(100% - 100px);
}

.number-fields.location-field .react-tel-input .form-control {
  width: 100px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none;
  padding-left: 35px;
  border: none;
}
.number-fields.location-field .react-tel-input .selected-flag .arrow {
  right: -50px;
}

.voucher-delete > p {
  text-align: center;
  font-size: 16px;
}
.voucher-delete {
  text-align: center;
}
.voucher-delete .btn-primary {
  padding: 5px 12px;
}
.modal-title.h4 {
  font-size: 18px;
  text-align: center;
  width: 100%;
  font-weight: 700;
  color: #262f3e;
  color: #8147dd !important;
}
.admin-inner-title {
  font-size: 20px !important;
}

.setup.voucher .voucher-listing .voucher-box a {
  /* width: 100%; */
  margin-right: 10px;
}

.setup.voucher .voucher-listing .voucher-box a .service-card {
  width: 100%;
}
