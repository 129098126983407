.my-profile {
  position: relative;
  padding: 50px 0px;
  background: #f4f4f6;
  width: 100%;
  height: 100vh;
}

.my-profile-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.profile-title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 40px;
}

.my-profile-content {
  width: calc(100% - 300px);
  padding-left: 40px;
}

.my-profile-sidebar {
  width: 300px;
  background: #262f3e;
  padding: 30px;
  border-radius: 4px;
  min-height: 680px;
}
.my-profile-sidebar.new-profile-sidebar {
  background: #00adff;
  border-radius: 10px;
  padding: 0;
  min-height: auto;
  align-items: unset;
  /* box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%); */
  box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
}
.new-profile-sidebar .user-info {
  padding: 30px;
}

.my-profile-content h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  padding-bottom: 20px;
  padding-top: 5px;
  margin-bottom: 0px;
  color: #00adff;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-pic img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.user-detail h4 {
  margin: 0;
  color: #fff;
  font-size: 21px;
  font-family: "AvenirHeavy";
  font-weight: 600;
  /* padding-bottom: 2px; */
}

.user-detail {
  padding-left: 20px;
  width: 150px;
  word-break: break-all;
}

.user-detail a {
  color: #fff;
  font-size: 14px;
  font-family: "AvenirMedium";
}

.my-profile-nav ul {
  margin: 0;
}

.my-profile-nav ul .nav a {
  display: block;
  width: 100%;
  padding: 15px 0px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.my-profile-nav ul .nav a.active {
  color: #fff;
}

.my-profile-nav ul .nav {
  flex-wrap: wrap;
  padding-top: 30px;
}

.my-profile-nav ul .nav a i {
  margin-right: 10px;
}

.my-profile-nav ul .nav a:hover {
  text-decoration: none;
}

.user-detail a:hover {
  text-decoration: none;
}
.myvoucher-btn:hover {
  box-shadow: none;
  outline: none;
  background-color: #262f3e;
  border: 1px solid #262f3e;
}
.profile-section {
  margin-top: 30px;
}
/* my favourite css */

.client-favorite {
  position: absolute;
  top: 110px;
  right: 10px;
  background: #ffffffb8;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 38px;
  border-radius: 100%;
  box-shadow: 0px 0px 5px 0px #0000001f;
  cursor: pointer;
}
.client-favorite i {
  color: red;
}
.favourite-card .box-details a:hover {
  text-decoration: none;
}

.favourite-section .favourite-card {
  /* background: #fff; */
  border-radius: 5px;
  /* box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1); */
  position: relative;
  /* margin-bottom: 20px; */
  height: 100%;
}
.favourite-card .card {
  border: none;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
}
.favourite-section .card img {
  height: 150px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
}
.favourite-card .card-body {
  padding: 10px 15px;
}
.favourite-section .favourite-card .box-details {
  height: 100%;
}
.favourite-card .card-title {
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 22px;
  color: #333;
  text-transform: capitalize;
  padding-top: 20px;
}
.favourite-detail h3 {
  color: #333;
  text-transform: capitalize;
  font-size: 18px;
  padding-bottom: 5px;
}
.favourite-detail ul {
  margin: 0;
}
.favourite-detail ul li {
  padding: 5px 0px;
  color: #000;
  font-size: 13px;
  font-weight: 600;
}
.favourite-detail ul li i {
  color: #ff2424;
  font-size: 16px;
  margin-right: 5px;
  vertical-align: middle;
}
.favourite-rating {
  padding-top: 5px;
}
.favourite-rating i {
  color: #ffd437;
  font-size: 16px;
  margin-right: 2px;
}
.favourite-rating {
  padding-top: 10px;
}
.favourite-rating span {
  background: #4caf50;
  color: #fff;
  padding: 5px 7px;
  margin-left: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}
/* .favourite-section {
  margin-top: 30px;
} */

/* my voucher css */

.my-voucher .voucher-section .voucher-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
  position: relative;
  margin-bottom: 20px;
  height: auto;
}
.my-voucher .voucher-section .voucher-card .voucher-body {
  padding: 35px 0 !important;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.voucher-section {
  margin-top: 30px;
}
.voucher-name h4 {
  font-size: 15px;
  color: #000;
}
.voucher-name h2 {
  font-size: 30px;
  font-weight: 900;
  padding-top: 50px;
  text-align: center;
  margin-bottom: 0;
}
.gift-voucher h4 {
  font-size: 16px;
  color: #000;
}
.gift-voucher h2 {
  font-size: 16px;
  font-weight: 700;
}
.gift-voucher {
  padding-top: 45px;
}
.gift-voucher h5 {
  font-size: 22px;
  font-weight: 900;
  padding-top: 10px;
  margin-bottom: 0;
}
.voucher-card:after {
  content: "";
  position: absolute;
  top: 39%;
  background-color: #f4f4f6;
  height: 40px;
  width: 40px;
  left: -25px;
  border-radius: 50px;
}
.voucher-card:before {
  content: "";
  position: absolute;
  top: 39%;
  background-color: #f4f4f6;
  height: 40px;
  width: 40px;
  right: -25px;
  border-radius: 50px;
}
/* edit profilecss */
.edit-profile .profile-section .profile-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
  position: relative;
  margin-bottom: 20px;
  z-index: 1;
}
.edit-profile .number-fields {
  flex-wrap: wrap;
}
.edit-profile .number-fields .react-tel-input {
  width: 100px;
}
.edit-profile .react-tel-input .form-control {
  width: 100px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none;
  padding-left: 35px;
}
.edit-profile .number-fields input {
  width: calc(100% - 100px);
}
.edit-profile .react-tel-input .selected-flag .arrow {
  position: absolute;
  right: -50px;
  left: inherit;
}
/* .profile-form {
    padding: 35px;
} */
.myvoucher-btn {
  color: #fff;
  background-color: #262f3e;
  border-color: #262f3e;
  width: 180px !important;
  padding: 10px !important;
  /* margin-top: 30px; */
}
.profile-img {
  background-color: transparent;
  height: 100px;
  width: 100%;
  max-width: 100px;
  margin: 0px auto;
  border-radius: 50%;
  margin-bottom: 35px;
  cursor: pointer;
}
.profile-card {
  padding: 35px;
}
.profile-img i {
  margin-left: 78px;
  margin-top: 66px;
  font-size: 11px;
  color: #fff;
  background-color: #9553ff;
  padding: 5px;
  box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
  border-radius: 50%;
  cursor: pointer;
}
.wallpaperimg {
  position: absolute;
  z-index: 999;
  max-width: 100px;
}

#upload-photo1 {
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 100px;
  height: 100px;
  left: 0;
  top: 0;
}
/* my appoinment css */
.appoint img {
  height: 60px;
  width: 60px;
  border-radius: 8px;
}
.appointment-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.appoint {
  display: flex;
  align-items: center;
}
.appointment-section {
  padding: 15px;
  padding-top: 10px;
}
.appointment-name h6 {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 0;
  font-family: "AvenirMedium";
  color: #000;
}
.appointment-name p {
  font-size: 15px;
  color: #000;
  font-family: "AvenirLight";
}
.appointment-name {
  margin-left: 20px;
  margin-top: 11px;
}
.appointment-time h6 {
  font-weight: 700;
  /* color: #565454; */
  color: #000;
  font-family: "AvenirMedium";
}
.upcoming-appointments .accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
}
.upcoming-appointments .accordion > .card {
  background-color: transparent;
}

.upcoming-appointments .accordion > .card .collapse {
  /* margin-top: 30px; */
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
  position: relative;
  /* margin-bottom: 20px; */
}
.upcoming-appointments .accordion > .card .collapsing {
  /* margin-top: 30px; */
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
  position: relative;
  /* margin-bottom: 20px; */
}
.appointments-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #9494942e;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.appointments-date .btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  width: auto !important;
  font-size: 13px;
  padding: 3px 10px;
  border-radius: 15px;
}
.appointments-date .btn-primary :hover {
  box-shadow: none;
  outline: none;
}
.appointments-date h6 {
  font-size: 20px;
  font-weight: 700;
  font-family: "AvenirMedium";
  color: #000;
}
.appointments-collapse {
  padding: 20px 15px;
}
.appoint-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.info-user h5 {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 0;
  margin-bottom: 0;
  font-family: "AvenirMedium";
}
.info-user > span {
  font-family: "AvenirLight";
}
.info-user h6 {
  color: #000;
  font-size: 14px;
  margin-top: 4px;
}
.appointment-bottom {
  margin-top: 50px;
}
.user-price h6 {
  font-size: 18px;
  font-weight: 700;
  color: #444546;
  font-family: "AvenirMedium";
}
.totalprice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  border-top: 1px solid #9494942e;
  padding-top: 25px;
}
.totalprice h6 {
  font-size: 20px;
  font-weight: 800;
}
.totalprice h5 {
  font-size: 18px;
  font-weight: 700;
}
.appointment-schedule i {
  background-color: #262f3e;
  margin: 10px;
  padding: 8px 9px;
  color: white;
  font-size: 20px;
  border-radius: 8px;
}
.appointment-schedule i.fa.fa-map-marker {
  padding: 8px 14px !important;
}

.appointment-schedule {
  display: flex;
  justify-content: space-between;
}
.appointment-schedule h6 {
  font-size: 13px;
  text-align: center;
  color: #000;
  font-family: "AvenirMedium";
}
.appointment-schedule .direction {
  margin: 0px 10px;
  cursor: pointer;
}
.appointment-schedule .reschedule {
  margin: 0px 10px;
  cursor: pointer;
}
.appointment-schedule .reschedule > a:hover {
  text-decoration: none;
}
.appointment-schedule .cancel {
  margin: 0px 10px;
  margin-right: 0px;
  cursor: pointer;
}
.appointments-date button i {
  position: inherit !important;
  right: auto;
  top: 0;
}
.upcoming-appointments {
  margin-top: 10px;
}
.pastappointments {
  margin-top: 50px;
}
.appointment-time h6 i {
  position: inherit !important;
  right: 0;
  top: 15px;
  font-size: 18px;
  margin-right: 5px !important;
}
.appointments-date .btn-primary:hover {
  background-color: #007bff;
  border: 1px solid #007bff !important;
  color: white !important;
  outline: none !important;
  box-shadow: none !important;
}

.cii i.fa.fa-camera {
  position: absolute;
  top: 0;
  left: 0;
}

.user-pics img {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  object-fit: cover;
  cursor: pointer;
}
.store-listing.wishlist-page .store-listing-content {
  padding-top: 0px !important;
  background-color: #f4f4f6;
}

.voucher-section .no-result {
  font-size: 18px;
}
.error-badge {
  background: #dc0b0b;
  border-radius: 50px;
  padding: 2px 10px;
  color: #ffff;
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
}
.error-badge i {
  margin-right: 5px;
}

.success-badge {
  background: #007bff;
  border-radius: 50px;
  padding: 2px 10px;
  color: #ffff;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  font-family: "AvenirHeavy";
}

.success-badge i {
  margin-right: 5px;
}
/* .appointment-scroll {
  max-height: 300px;
  overflow: auto;
  height: auto;
}
.upcoming-appointments .accordion {
  margin-bottom: 30px;
}

.appointment-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.appointment-scroll::-webkit-scrollbar {
  width: 0px;
  background-color: #f5f5f5;
}

.appointment-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
} */

.arrive-badge {
  background: #0ccab8;
  border-radius: 50px;
  padding: 2px 10px;
  color: #ffff;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.arrive-badge i {
  margin-right: 5px;
}
.appointment-scroll > p {
  text-align: center;
  font-size: 22px;
}
.favourite-section p {
  text-align: center;
  font-size: 22px;
}
.profile-toggle {
  display: flex;
  justify-content: space-between;
}
.toggle-sidebar {
  display: none;
}

/* Appointmment popup */
.appointment-modal {
  text-align: center;
  padding: 20px;
  margin-top: 30px;
}
.appointment-modal h6 {
  text-align: center;

  font-size: 18px;
  font-weight: 600;
}

.action-btn button {
  margin: 20px 10px;
  min-width: 85px;
}

.appointment-popup.modal-header {
  padding: 10px;
  border-bottom: none;
}

.appointment-popup .modal-title.h4 {
  font-size: 18px;
  font-weight: 600;
}

.close-modal {
  position: absolute;
  right: 10px;
  top: 6px;
}

.close-modal i {
  font-size: 20px;
  color: #706767;
  cursor: pointer;
}
.action-btn {
  margin-top: 15px;
}

.appointment-popup .modal-content {
  position: relative;
  top: 20%;
}

.action-btn .btn-primary:hover {
  border: 1px solid #018bcf !important;
}

.remove-pic {
  text-align: center;
  padding-bottom: 20px;
  margin-top: -10px;
}

.space-x-2 .btn {
  margin: 0px 10px;
}

.space-x-2 {
  padding-top: 20px;
}
.profile-section .delete-icon {
  position: absolute;
  top: 50%;
  left: 0px;
  right: auto;
}

.profile-section .delete-icon i {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 8px;
  margin-left: 0px;
  right: auto;
  margin-top: 12px;
  color: red;
}
.book-now-btn.btn-width .btn {
  min-width: 125px;
  width: 125px !important;
  padding: 9px 4px !important;
  font-size: 18px;
}
.book-now-btn.btn-width .btn:hover {
  border: 0 !important;
}
.new-profile-sidebar a.new-menu-link {
  padding: 15px 30px !important;
}
/* .new-profile-sidebar a.new-menu-link + a.new-menu-link  {
 margin-top:15px;
} */
a.new-menu-link:hover {
  background: #40c1ff;
}
a.new-menu-link.active {
  background: #40c1ff;
}
.new-profile-sidebar .my-profile-nav {
  height: 100%;
}
.my-profile-container.new-profile-container {
  align-items: unset;
}
.profile-card.new-profile-card {
  margin-bottom: 0 !important;
  padding: 40px 50px 35px 50px;
}
/* .new-profile-card .user-pics {
  border-radius: 50%;
 
} */
.profile-card.new-profile-card .profile-form .form-label {
  font-family: "AvenirHeavy";
  color: #000;
  font-weight: 700;
  font-size: 16px;
}
.profile-card.new-profile-card .profile-form .form-group .form-control {
  font-family: "AvenirLight";
  /* color: #a2a2a2; */
  font-size: 12px !important;
  border-radius: 10px;
  text-transform: capitalize;
  color: #000;
}
.profile-card.new-profile-card .profile-form .form-group ::placeholder {
  /* color: #a2a2a2 !important; */
  color: #000 !important;
}
.select-dropdown.dropdown {
  height: 50px;
  border-right: 1px solid #ced4da;
}
.select-dropdown.dropdown .btn {
  height: 100%;
}
.number-fields.new-number-fields {
  flex-wrap: unset;
}
.edit-profile .number-fields.number-fields.new-number-fields input {
  width: 100%;
}
.bg-color {
  background: #e5f7ff;
}
.set-height {
  height: 100% !important;
}
.my-profile-box {
  min-height: 500px;
  height: 100%;
}
.add-white-bg {
  background: #fff;
  border-radius: 5px;
}

.my-appointments.add-white-bg.new-my-appointments .no-result {
  box-shadow: none;
  padding: 0;
  background-color: transparent;
  font-size: 16px;
  /* font-family: "AvenirHeavy"; */
  font-family: "AvenirMedium";
}
.my-appointments.add-white-bg.new-my-appointments {
  padding: 30px;
  border-radius: 10px;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
}
.my-appointments.new-my-appointments .nav-tabs {
  border-bottom: 2px solid #00adff;
}
.my-appointments.new-my-appointments .nav-tabs .nav-item.show .nav-link,
.my-appointments.new-my-appointments .nav-tabs .nav-link.active {
  border-color: transparent;
  border-bottom: transparent;
  color: #00adff;
}
.my-appointments.new-my-appointments .nav-tabs .nav-link:focus {
  border-bottom: transparent;
  color: #00adff;
}
.my-appointments.new-my-appointments .nav-tabs .nav-link {
  border: 0;
  font-size: 21px;
}
.book-now-link {
  color: #000;
}
.profile-form .form-group {
  margin-bottom: 25px;
}
button.myvoucher-btn.btn.new-btn {
  margin: 0 5px;
}

.new-voucher .voucher-card:before {
  background-color: #ddf3ff;
  content: none;
}
.new-voucher .voucher-card:after {
  background-color: #ddf3ff;
  content: none;
}
.voucher-section-item .signle-voucher {
  height: 100%;
}
.new-voucher .voucher-section-item .signle-voucher {
  height: 243px;
  width: 100%;
  max-width: 243px;
}
.new-voucher .voucher-section-list .voucher-section-item {
  width: 33.33%;
  padding: 10px;
}
.new-voucher .voucher-section-item .voucher-body-box h4 {
  font-size: 18px;
}
.new-voucher .voucher-name h2 {
  padding-top: 0;
  margin-bottom: 0;
}
.my-voucher .voucher-section.new-voucher .voucher-card .voucher-body {
  padding: 20px 10px !important;
}
.new-voucher .voucher-section-item .gift-voucher h4 {
  /* margin-top: 20px; */
  align-self: center;
  margin-top: auto;
  color: #fff;
}
.new-voucher .voucher-section-item .voucher-name {
  height: auto;
}
.new-voucher .voucher-section-item .gift-voucher {
  padding-top: 0;
}
.new-voucher .gift-voucher h5 {
  padding-top: 0;
  margin-bottom: 0;
}
.new-voucher
  .voucher-section-item:nth-child(3n + 1)
  .signle-voucher
  .voucher-card {
  background: #9552ff;
  background: linear-gradient(to right, #9552ff 0%, #9552ff 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9552ff', endColorstr='#9552ff', GradientType=1);
}
.new-voucher
  .voucher-section-item:nth-child(3n -1)
  .signle-voucher
  .voucher-card {
  background: #607aff;
  background: linear-gradient(to right, #607aff 0%, #607aff 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#607aff', endColorstr='#607aff', GradientType=1);
}
.new-voucher .voucher-section-item:nth-child(3n) .signle-voucher .voucher-card {
  background: #00c5ff;
  background: linear-gradient(to right, #00c5ff 0%, #00c5ff 100%) !important;
}
.new-voucher ul.pagination-chk a.pagination__link {
  min-width: 100px;
  width: auto;
}
/* .my-profile {
  height: 100%;
} */
.store-listing.wishlist-page.new-store-listing .store-listing-content {
  background-color: #ddf3ff;
}
@media screen and (min-width: 1700px) {
  .main_content {
    min-height: calc(100vh - 135px);
  }
  .admin-view .main_content {
    min-height: calc(100vh - 60px);
  }
  .my-profile,
  .login-section,
  .forget-section,
  .register-section,
  .new-select-service {
    min-height: 100%;
    display: flex;
    align-items: center;
  }
  .my-profile,
  .new-select-service {
    min-height: 100vh;
  }
}
