.calendar-page,
.add-appointment-page.appointment-view,
.partner-services,
.setup.voucher,
.setup.add-voucher,
.customer-list,
.staff-page,
.add-staff,
.setup,
.account-setting,
.location-list-page,
.location-page,
.taxes,
.payment-tax,
.discount-type,
.referral,
.verify-container {
  width: 100%;
}
.signup-block-container {
  background: #000000;
  border-radius: 20px;
  /* overflow: hidden; */
  padding: 55px 60px 45px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.signup-block-container .cross-icon,
.signup-step-form .cross-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 99;
}
.signup-block-container .cross-icon img,
.signup-step-form .cross-icon img {
  width: 20px;
  cursor: pointer;
  z-index: 99;
}
.sb-left {
  width: 60%;
  padding: 60px 0;
  color: #fff;
  font-size: 24px;
}
.sb-left h2 {
  font-size: 52px;
  font-family: "AvenirBlack";
  font-weight: inherit;
  margin-bottom: 30px;
}
.sb-left > p {
  font-family: "AvenirRoman";
  margin-bottom: 38px;
}
.sb-right {
  width: calc(100% - 60%);
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.sb-right-img {
  width: 100%;
}

.sb-right-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sb-btn .btn-primary {
  font-size: 22px;
  /* line-height: 24px; */
  font-family: "AvenirBlack";
  border-radius: 8px;
  padding: 6px 14px;
  font-weight: inherit;
  background-color: #9553ff;
  border-color: #9553ff;
  box-shadow: none;
  border: none;
  min-width: 165px;
  border: 1px solid #9553ff;
}
.signup-step-form {
  background: #fff;
  border-radius: 20px;
  padding: 50px 40px 50px 50px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  min-height: 825px;
  height: auto;
}
.signup-left {
  width: 60%;
  z-index: 9;
}
.signup-footer-btn {
  width: 100%;
  padding-top: 20px;
  text-align: right;
  display: flex;
  align-items: flex-end;
  margin-top: auto;
  justify-content: flex-end;
}
/* .signup-right {
  width: calc(100% - 60%);
  padding-left: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
} */
.signup-left .progress {
  height: 10px;
  background-color: #ededed;
  border-radius: 50px;
}
.progress-bar {
  background-color: #8147dd;
  transition: width 0.6s ease;
  border-radius: 50px;
}
.signup-head h3 {
  font-size: 30px;
  color: #8147dd;
  margin-bottom: 30px;
  font-family: "AvenirBlack";
}
.signup-footer-btn .btn-primary {
  background: #8147dd;
  border-color: #8147dd;
  border: 1px solid #8147dd;
  font-size: 22px;
  padding: 6px 12px;
  font-family: "AvenirBlack";
  box-shadow: none;
  border-radius: 8px;
  min-width: 132px !important;
}
.signup-step-form:before {
  content: "";
  position: absolute;
  background-image: url("../../../assets/step1img.png");
  height: 500px;
  width: 500px;
  background-repeat: no-repeat;
  background-size: 500px;
  background-position: center;
  top: 0;
  right: 0;
  border-top-right-radius: 20px;
  z-index: 9;
}
.account-setting.signup-business {
  padding-top: 50px;
}

.signup-business .business-card .form-label,
.signup-business .des-field .form-label,
.signup-business .Time-card .form-label {
  font-size: 22px;
  color: #000;
  font-family: "AvenirHeavy";
  font-weight: inherit;
  text-transform: capitalize;
}
.account-setting.signup-business .form-control {
  font-size: 18px !important;
  color: #000 !important;
  font-family: "AvenirRoman";
  font-weight: inherit;
  border-radius: 10px;
  height: 50px;
  padding: 6px 15px;
}
.account-setting.signup-business .form-control:focus {
  border-color: #8147dd;
}
.account-setting.signup-business .form-group {
  width: calc(100% - 30px);
  margin-bottom: 15px;
}
.signup-business .des-field .form-group {
  width: 100%;
}
.account-setting.signup-business .form-control::placeholder {
  font-family: "AvenirLight";
  color: #a2a2a2 !important;
  text-transform: capitalize;
}
.signup-location {
  padding-top: 40px;
}
.signup-step-form.location:before {
  background-image: url("../../../assets/signup-location.png");
}
.signup-location .nav-tabs {
  border-bottom: none;
  margin-bottom: 35px;
}
.signup-location .nav-tabs .nav-item.show .nav-link,
.signup-location .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #8147dd;
  border-color: #dee2e6 #dee2e6 #fff;
}
.signup-location .nav-tabs .nav-link {
  border: none;
  border-radius: 8px;
  color: #fff;
  background: #e0e0e0;
  margin: 0 10px;
  font-size: 22px;
  font-family: "AvenirBlack";
  min-width: 180px;
  text-align: center;
  padding: 6px 12px;
}
.signup-location .nav-tabs .nav-link:first-child {
  margin-left: 0;
}
.signup-location .nav-tabs .nav-link:last-child {
  margin-right: 0;
}
.signup-footer-btn .btn-primary + .btn-primary {
  margin-left: 20px;
}
.signup-step-form.photo:before {
  background-image: url("../../../assets/signup-photo.png");
}
.signup-step-form.taxes:before {
  background-image: url("../../../assets/signup-taxes.png");
}

.location-field .react-tel-input .flag-dropdown {
  border: 1px solid #cccccc;
  border-radius: 10px 0 0 10px;
}
.location-field .react-tel-input .selected-flag .arrow {
  /* position: relative !important; */
  position: absolute;
  right: -70px;
  left: inherit;
  border: none;
  right: -60px !important;
  top: 2px;
}
.location-field .react-tel-input .selected-flag .arrow:before {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: "";
  display: inline-block;
  height: 8px;
  /* left: 4px; */
  position: relative;
  top: 6px;
  right: -80px;
  transform: rotate(133deg);
  vertical-align: top;
  width: 8px;
  color: #8c8c8c;
}
.location-lower {
  width: calc(100% + 165px);
}
.signup-location .form-label {
  font-size: 22px;
  color: #000;
  font-family: "AvenirHeavy";
  font-weight: inherit;
  margin-bottom: 8px;
  text-transform: capitalize;
}
.signup-location .form-control {
  height: 50px;
  font-size: 18px !important;
  border-radius: 10px;
  font-family: "AvenirLight";
  font-weight: inherit;
  color: #000;
}
.signup-location .form-group {
  margin-bottom: 30px;
}
.signup-location .form-control::placeholder {
  color: #a2a2a2 !important;
  text-transform: capitalize;
}
.signup-location .number-fields.location-field .react-tel-input .form-control {
  width: 120px;
  border: none;
  padding-left: 40px;
}
.signup-location .number-fields.location-field .react-tel-input {
  width: 120px;
}
.availability-location h4 {
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: inherit;
  color: #000;
  font-family: "AvenirHeavy";
}
.form-group {
  margin-bottom: 30px;
}
.availability-box .online-booking {
  font-size: 14px;
  color: #000;
  font-weight: inherit;
  font-family: "AvenirHeavy";
}
.account-setting.signup-business .card-title.h5 {
  font-size: 22px;
  font-weight: inherit;
  font-family: "AvenirHeavy";
  color: #000;
  margin-bottom: 20px;
  padding-top: 10px;
}
.account-setting.signup-business .input-group-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem !important;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #8147dd !important;
  border: 1px solid #8147dd;
  border-radius: 10px 0 0 10px;
  width: 50px;
}
.signup-business .input-group .form-control {
  border-radius: 0 10px 10px 0;
}
.signup-business .input-group-prepend .fa {
  font: normal normal normal 31px/1 FontAwesome;
}
.signup-photo .upload__image-wrapper {
  border: 1px solid #f4f1fa;
  border-radius: 20px;
  width: 100%;
  background-color: #f4f1fa;
  min-height: 440px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.signup-btn .btn {
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-family: "AvenirBlack";
  min-width: 180px;
}
.signup-btn .btn + .btn {
  margin-left: 20px;
}
.signup-btn .btn-gray {
  color: #fff;
  background: #e0e0e0;
  border-color: #e0e0e0;
}
.signup-btn .btn-gray:hover,
.signup-btn .btn-gray:focus {
  background: #8147dd;
  border-color: #8147dd;
}
.signup-btn .btn-perple {
  color: #fff;
  background: #8147dd;
  border-color: #8147dd;
}
.signup-btn .btn-perple:hover,
.signup-btn .btn-perple:focus {
  background: #e0e0e0;
  border-color: #e0e0e0;
}
.signup-photo {
  padding-top: 50px;
}
.signup-photo .upload__image-wrapper .uploadimg {
  cursor: pointer;
  width: 79px;
}
.signup-photo .image-item-section {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-bottom: 80px;
}
.signup-photo .image-item {
  width: 33.33%;
  margin-bottom: 0;
  padding: 10px;
  margin-right: 0;
  position: relative;
}
.signup-photo .image-item img {
  width: 100%;
  height: 145px;
  object-fit: cover;
  background: #f4f1fa;
  border-radius: 20px;
  position: relative;
}
.signup-photo .image-item > span {
  position: absolute;
  top: 15px;
  right: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  opacity: 0;
  transition: 0.3s all ease-in;
}
.signup-photo .image-item:hover > span {
  opacity: 1;
}
.signuptaxes .form-group {
  width: calc(100% - 30px);
  margin-bottom: 15px;
}

.availability-box select.form-control {
  appearance: none;
}
.availability-box .availability-select {
  width: 49%;
}

.availability-box .availability-select .form-control {
  width: 100%;
}
.availability-box .availability-select {
  position: relative;
}
.availability-box .availability-select:before {
  content: "\f0dc";
  font: normal normal normal 25px/1 FontAwesome;
  position: absolute;
  font-size: 20px;
  color: #000;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  cursor: pointer;
}
.react-tel-input .selected-flag .flag {
  left: 10px;
}
.signupinvoive .invoice-tab-content .nav-tabs .nav-link {
  min-width: 48%;
  padding: 6px 12px;
  border-bottom: none !important;
  color: #fff !important;
  background-color: #e0e0e0 !important;
}
.signupinvoive .invoice-tab-content .nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #8147dd !important;
  border-color: #dee2e6 #dee2e6 #fff !important;
}
.staff-page.signupinvoive .tab-content {
  padding: 0;
}
.signupinvoive .account-save {
  text-align: left;
  padding: 0 !important;
}
.signupinvoive .account-save .btn-primary {
  background: #8147dd;
  border-color: #8147dd;
  padding: 6px 12px;
  font-weight: 700;
  min-width: 100px;
  border-radius: 8px;
  font-size: 22px;
}
.partner-table table tbody td {
  border-top: 1px solid #e0e0e0;
}
.btn-perple {
  background: #8147dd !important;
  border: 1px solid #8147dd !important;
  color: #fff !important;
  padding: 6px 12px !important;
  font-weight: 700 !important;
  min-width: 80px !important;
  border-radius: 8px !important;
  font-size: 22px !important;
  font-family: "AvenirBlack";
  height: 45px;
}
.partner-table table thead th,
.partner-table table tbody td {
  font-size: 22px;
}
.payment-tax.signup-payment-tax {
  padding: 0;
}
.btn-group-toggle .active1 {
  background-color: #8147dd !important;
}
.signup-taxes .payment-tax {
  padding: 0;
}
.verify-identity.signup-verify-identity {
  box-shadow: none;
  padding: 30px 0 0 0;
}
.referral.signup-referral {
  box-shadow: none;
  padding: 0;
}
.verify-identity.signup-verify-identity h5 {
  margin-bottom: 0;
}
.signup-online-pricing .payment-stripe-container {
  box-shadow: none;
  padding: 30px 0 0;
}
/* .payment-stripe-container .step-btn {
  justify-content: flex-start !important;
} */
.step-btn .btn + .btn {
  margin-left: 15px;
}
button.edit-refferal.edit-btn.btn.btn-primary {
  border: 1px solid #8147dd;
  background: #8147dd !important;
}
.sb-btn .btn-primary + .btn-primary {
  margin-left: 15px;
}
.partner-setup .signup-step-form:before {
  content: none;
}
.partner-setup .signup-left {
  width: 100%;
  z-index: 9;
}
.invoice.staff-page.signupinvoive .invoice-tab-content {
  padding: 0;
}
.btn-perple-icons.btn {
  margin-left: 5px;
  padding: 0.375rem 0.75rem;
  background-color: #8147de;
  color: #fff;
  border-color: #8147de;
  font-family: "AvenirBlack";
  font-size: 22px;
}
.online-booking-container .sb-left {
  width: 50%;
}

.online-booking-container .sb-right {
  width: calc(100% - 50%);
}
ul.booking-list {
  margin-bottom: 40px;
}
ul.booking-list li.booking-list-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}
ul.booking-list li.booking-list-item:last-child {
  margin-bottom: 0;
}
li.booking-list-item .bl-icon {
  width: 14px;
}
li.booking-list-item .bl-icon img {
  width: 14px;
}
li.booking-list-item .bl-content {
  width: calc(100% - 14px);
  padding-left: 10px;
  font-size: 18px;
  font-family: "AvenirRoman";
}
li.booking-list-item .bl-content h3 {
  font-size: 22px;
  font-family: "AvenirHeavy";
  margin-bottom: 0;
}
li.booking-list-item .bl-content > p {
  margin-bottom: 0;
}
.signup-photo {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}
.signup-photo > img {
  width: 100%;
  height: 440px;
  object-fit: cover;
}

/* Image Upload css :start */
.signup-photo.adjustImg .upload__image-wrapper {
  position: relative;
}

.adjustImg.signup-photo .upload__image-wrapper .uploadimg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.adjustImg .upload__image-wrapper {
  padding: 0;
  margin: 0;
}
.signup-photo .upload__image-wrapper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.3);
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  border-radius: 20px;
}
.signup-photo .upload__image-wrapper:hover:before {
  opacity: 1;
}
.signup-photo .upload__image-wrapper:hover .uploadimg {
  opacity: 1;
  z-index: 99999;
}
.signup-photo .upload__image-wrapper {
  position: relative;
}
.upload__image-wrapper .signup-photo {
  display: none;
}
.adjustImg .upload__image-wrapper .signup-photo {
  display: block;
}
.signup-photo .upload__image-wrapper .uploadimg .white-icon {
  display: none;
}
.signup-photo .upload__image-wrapper:hover .uploadimg .white-icon {
  display: block;
}
.signup-photo .upload__image-wrapper:hover .uploadimg .dark-icon {
  display: none;
}
/* .adjustImg.signup-photo .upload__image-wrapper .uploadimg .img.dark-icon {
  display: none;
}
.adjustImg.signup-photo .upload__image-wrapper .uploadimg s.img.dark-icon{
  display: block;
}
.signup-photo .upload__image-wrapper:hover .uploadimg .img.dark-icon {
  display: none;
}
.signup-photo .upload__image-wrapper:hover .uploadimg .img.light-icon {
  display: block;
} */
.payment-stripe-container .step-btn.justify-content-start {
  justify-content: flex-start !important;
}
.adjustImg .signup-photo {
  padding-top: 0;
}

/* Image Upload css End */
.online-booking-container {
  padding: 55px 30px 45px 60px;
}
.online-booking-container .sb-btn .btn-primary {
  font-family: "AvenirHeavy";
  font-size: 22px;
}

.signup-step-form.taxes.btn-align .signup-left {
  width: 100%;
}

.signup-step-form.taxes.btn-align .signup-head {
  width: 60%;
}
.signup-step-form.taxes.btn-align
  .signup-online-pricing
  .payment-stripe-container {
  width: 60%;
}

.signup-footer-btn.payment-btn {
  position: absolute;
  right: 40px;
  bottom: 40px;
}
.signup-step-form.taxes.btn-align .verify-identity.signup-verify-identity {
  width: 60%;
}
.signup-step-form.taxes.btn-align .signup-footer-btn.verify-btn {
  position: absolute;
  bottom: 40px;
  right: 40px;
}

@media screen and (max-width: 1600px) {
  .online-booking-container .sb-btn .btn-primary {
    font-size: 18px;
    padding: 5px 12px;
  }
  .signup-step-form {
    padding: 35px 30px 40px 40px;
    min-height: 619px;
  }
  .signup-head h3 {
    font-size: 23px;
    margin-bottom: 22px;
  }
  .signup-left .progress {
    height: 8px;
  }
  .account-setting.signup-business {
    padding-top: 35px;
  }
  .signup-business .business-card .form-label,
  .signup-business .des-field .form-label,
  .signup-business .Time-card .form-label {
    font-size: 17px;
  }
  .account-setting.signup-business .form-control {
    font-size: 14px !important;
    height: 40px;
  }
  .account-setting.signup-business .form-group {
    margin-bottom: 12px;
  }
  .account-setting.signup-business .card-title.h5 {
    font-size: 17px;
    margin-bottom: 14px;
    padding-top: 7px;
  }
  .account-setting.signup-business .input-group-text {
    width: 40px;
  }
  .signup-business .input-group-prepend .fa {
    font: normal normal normal 20px/1 FontAwesome;
  }
  .signup-business .des-field textarea.form-control {
    height: 80px !important;
  }
  .account-setting.signup-business .form-group {
    width: calc(100% - 20px);
  }
  .signup-business .des-field .form-group {
    width: 100%;
  }
  .signup-business .pl-4.card-title.h5 {
    padding-left: 20px !important;
  }
  .signup-step-form:before {
    height: 400px;
    width: 400px;
    background-size: 400px;
  }
  .signup-footer-btn .btn-primary {
    font-size: 17px;
    min-width: 100px !important;
  }
  .signup-location {
    padding-top: 30px;
  }
  .signup-location .nav-tabs {
    margin-bottom: 30px;
  }
  .signup-location .nav-tabs .nav-link {
    margin: 0 8px;
    font-size: 18px;
    min-width: 160px;
  }
  .signup-location .form-label {
    font-size: 17px;
    margin-bottom: 6px;
  }
  .signup-location .form-control {
    height: 40px;
    font-size: 14px !important;
  }
  .number-fields.location-field .react-tel-input {
    width: 100px;
  }
  .signup-location
    .number-fields.location-field
    .react-tel-input
    .form-control {
    width: 100px;
  }
  .location-field .react-tel-input .selected-flag .arrow {
    right: -40px !important;
  }
  .signup-location .form-group {
    margin-bottom: 22px;
  }
  .signup-location .availability-location {
    padding: 12px 0px;
  }
  .signup-location .availability-location h4 {
    padding-bottom: 0;
    font-size: 17px;
  }
  .signup-btn .btn {
    font-size: 14px;
    padding: 8px 10px;
    min-width: 130px;
  }
  .signup-photo {
    padding-top: 30px;
  }
  /* .signup-photo .upload__image-wrapper {
    min-height: 330px;
  } */
  .signup-photo .upload__image-wrapper .uploadimg > img {
    width: 79px;
    object-fit: cover;
  }
  .signup-photo .image-item-section {
    margin-bottom: 50px;
  }
  .location-field .react-tel-input .selected-flag .arrow:before {
    right: -64px;
  }
  .availability-box .availability-select:before {
    font: normal normal normal 14px/1 "FontAwesome";
    font-size: 14px;
  }
  .account-setting.signup-business .input-group-text img {
    width: 30px;
    height: 25px;
  }
  .signupinvoive .account-save .btn-primary,
  .btn-perple {
    font-size: 18px !important;
    height: auto;
    padding: 5px 12px !important;
  }
  .staff-page.new-invoice .nav-tabs .nav-link {
    padding: 5px 10px;
    font-size: 18px;
  }
  .partner-table table thead th,
  .partner-table table tbody td {
    font-size: 17px;
  }
  .account-card a {
    font-size: 17px;
  }
  .btn-perple-icons.btn {
    font-size: 17px;
  }
  .online-booking-container .sb-left {
    padding: 0;
  }

  .online-booking-container h2 {
    font-size: 41px !important;
  }
  li.booking-list-item .bl-content h3 {
    font-size: 18px;
  }
  li.booking-list-item .bl-content {
    width: calc(100% - 16px);
    padding-left: 15px;
    font-size: 15px;
  }
  ul.booking-list li.booking-list-item {
    margin-bottom: 16px;
  }
  li.booking-list-item .bl-icon {
    width: 16px;
  }
  li.booking-list-item .bl-icon img {
    width: 16px;
  }
}
/* .disable {
  position: relative;
}

.disable .header-view {
  cursor: not-allowed;
  pointer-events: none;
} */
html.remove-sidebar .admin-menu {
  display: none;
}

html.remove-sidebar .admin-container {
  left: 0;
  padding-left: 0;
}
span.back-button {
  margin-right: 10px;
}
