@media screen and (min-width: 1700px) {
  .home-banner {
    height: calc(60vh - 125px);
  }
  .partners-profile {
    min-height: 100vh;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .admin-content {
    min-height: 100vh;
    /* display: flex; */
    width: 100%;
  }
}
@media screen and (max-width: 1700px) {
  .paymentdetail textarea.form-control {
    height: 261px !important;
  }
}
@media screen and (min-width: 1700px) {
  .signup {
    height: calc(100vh - 134px);
    min-height: 100vh;
  }
  .login {
    height: calc(100vh - 134px);
    min-height: 100vh;
  }
  .forget-password-section {
    height: calc(100vh - 134px);
    min-height: 100vh;
  }
  .admin-content {
    min-height: 100vh;
    width: 100%;
  }
  .dashboard {
    min-height: 100vh;
    width: 100%;
  }
}
@media screen and (min-width: 1700px) {
  .container {
    max-width: 1420px;
    width: 100%;
  }
}
@media screen and (max-width: 1600px) {
  .calendar-content-view {
    border-radius: 15px;
  }
  .service-list {
    border-radius: 15px;
  }
  .header-buttons .dropdown {
    font-size: 18px !important;
  }
  .header-buttons .btn {
    font-size: 18px !important;
  }
  .admin-inner-title {
    font-size: 18px !important;
  }
  .customer-list-dropdown .add-voucher-btn {
    font-size: 18px;
  }

  .service-card {
    border-radius: 15px;
  }
  .category-popup .modal-title {
    font-weight: inherit;
    font-size: 22px;
    font-family: "AvenirBlack";
  }
  .form-group .form-control {
    font-size: 14px !important;
    height: 40px;
  }

  .partner-add-services-content {
    border-radius: 15px;
  }
  .form-label {
    font-size: 17px;
  }
  .customer-list {
    border-radius: 15px;
  }
  .customer-upper,
  .customer-bottom {
    border-radius: 15px;
  }
  .back-setup {
    border-radius: 15px;
  }
  .account-setting .input-group-text {
    width: 40px;
  }
  .account-setting .input-group-text img {
    width: 30px;
    height: 25px;
  }
  .StripeElement {
    height: 40px;
    padding: 10px 12px;
  }
  .number-fields .dropdown .btn {
    height: 40px;
  }
  .voucherbtn button {
    padding: 4px 15px !important;
  }
  .voucher .voucher-listing .voucher-box a {
    padding: 4px 10px;
  }
  .admin-title a {
    font-size: 18px;
    padding: 8px 12px;
  }
  .footer-button .btn {
    padding: 6px 15px;
  }
  .footer-button .dropdown-item,
  .footer-button a {
    line-height: 13px;
  }
}

@media screen and (max-width: 1400px) {
  .service-bg.new-select-service .no-service.date-time h4 {
    padding: 8px 10px;
  }
  .store-service-tab .select-service-tab nav.nav.nav-tabs {
    width: 200px;
  }
  .store-service-tab .select-service-tab .tab-content {
    width: calc(100% - 200px);
    padding-left: 20px;
  }
  .stepwizard span.stepwizard-steps {
    padding-top: 20px;
  }
  .select-service-tab {
    padding: 15px 30px;
  }

  /* .new-select-service .total-rate:after {
    width: 83%;
  } */
  .voucher-section-item .voucher-body-box h4 {
    font-size: 20px;
  }
  .single-store .voucher-name h2 {
    padding-top: 0;
  }
}
@media screen and (max-width: 1024px) {
  .header-left {
    display: flex;
    align-items: center;
    width: 35%;
  }

  .header-right {
    display: flex;
    align-items: center;
    width: 65%;
    justify-content: space-between;
  }

  .search-container {
    width: 85%;
  }

  .search-container .dropdown {
    max-width: 100px;
    width: 100%;
  }

  .search-container .form-control {
    font-size: 12px !important;
    border: none;
    max-width: 200px;
    width: 100% !important;
    margin: 0 !important;
  }

  .navbar-light .navbar-brand img {
    max-width: 150px;
  }

  .my-profile-menu .dropdown-menu {
    position: absolute;
    top: 100%;
    left: auto;
    right: 0px;
  }

  .notification-section:before {
    content: "";
    margin-top: 0px;
    left: 60%;
  }
}

@media screen and (max-width: 991px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 100%;
  }

  .header-left {
    display: flex;
    align-items: center;
    width: 35%;
  }

  .header-right {
    display: flex;
    align-items: center;
    width: 65%;
    justify-content: space-between;
  }

  .search-container {
    width: 85%;
  }

  .search-container .dropdown {
    max-width: 100px;
    width: 100%;
  }

  .search-container .form-control {
    font-size: 12px !important;
    border: none;
    max-width: 200px;
    width: 100% !important;
    margin: 0 !important;
  }

  .navbar-light .navbar-brand img {
    max-width: 150px;
  }

  .my-profile-menu .dropdown-menu {
    position: absolute;
    top: 100%;
    left: auto;
    right: 0px;
  }

  .notification-section:before {
    content: "";
    margin-top: 0px;
    left: 60%;
  }

  /************************ Partner Pannel **************************************/
  .partner-table table.table-bordered thead th {
    white-space: nowrap;
  }

  .partner-table table thead th {
    white-space: nowrap;
  }

  .user-permission .btn-primary {
    margin-top: 15px;
  }

  .service-list {
    padding: 20px 20px 0;
    margin-bottom: 0;
    overflow-x: scroll;
  }

  .table-scroll {
    min-width: 800px;
  }

  .staff-page .nav-tabs .nav-link {
    width: 50% !important;
  }

  .online-booking {
    justify-content: flex-start;
    width: 100%;
  }

  .account-save {
    padding: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .quantity button {
    margin: 10px 15px;
  }

  .voucher-detail-section .voucherlisting {
    width: 100%;
  }

  .notification-section:before {
    content: "";
    margin-top: 0px;
    left: 62%;
  }

  .my-profile-menu .dropdown-menu {
    position: absolute;
    top: 100%;
    left: auto;
    right: 0px;
  }

  .header-left {
    display: flex;
    align-items: center;
    width: 35%;
  }

  .header-right {
    display: flex;
    align-items: center;
    width: 65%;
    justify-content: space-between;
  }

  .search-container {
    width: 85%;
  }

  .search-container .dropdown {
    max-width: 100px;
    width: 100%;
  }

  .search-container .form-control {
    font-size: 12px !important;
    border: none;
    /* max-width: 200px; */
    width: 100% !important;
    margin: 0 !important;
    max-width: 100% !important;
  }

  .navbar-light .navbar-brand img {
    max-width: 150px;
  }

  .my-profile-sidebar {
    width: 250px;
    background: #262f3e;
    padding: 30px;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    min-height: 680px;
  }

  .my-profile-content {
    width: calc(100% - 250px) !important;
    padding-left: 40px;
  }

  /************************ Partner Pannel **************************************/
  .customer-search {
    padding: 15px 20px;
  }

  .appointment-buttons {
    padding: 15px 20px;
  }

  .footer-button .btn {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .space-x-2 .btn:first-child {
    margin-top: 0;
  }

  .space-x-2 .btn {
    margin: 10px 0 0;
    width: 100% !important;
  }

  .bg-show.bg-hide {
    position: fixed;
    height: 100%;
    width: 100%;
  }

  .footer-button .dropdown-item,
  .footer-button a {
    padding: 10px 10px !important;
  }

  .calendar-days a {
    width: fit-content;
  }

  .voucher-detail-section .voucherlisting {
    width: 100%;
  }

  .voucher-detail-section {
    display: inherit;
  }

  .admin-header .notification-section {
    position: absolute;
    top: 64px;
    /* right: 5px; */
    z-index: 999;
    background-color: white;
    box-shadow: 0px 0px 5px #ced4ce;
    max-width: 320px;
    border-radius: 10px;
    /* margin: 0px auto; */
    /* width: 100%; */
  }

  /* for clientpanel design */
  .success-badge {
    width: fit-content;
  }

  .arrive-badge {
    width: fit-content;
  }

  .appointments-date {
    display: block !important;
  }

  .appointment-time h6 {
    text-align: right;
  }

  .appointment-section {
    padding: 10px;
  }

  .appointments-collapse {
    padding: 20px 0px;
  }

  .upcoming-appointments .accordion .card-body {
    padding: 0px 10px;
  }

  .my-profile-content {
    width: 100% !important;
    padding-left: 0px !important;
  }

  .appointment-img {
    display: block !important;
    align-items: center;
    width: 100%;
  }

  .my-profile-content h3 {
    padding-top: 10px !important;
  }

  /* end */
  .navbar-light .navbar-brand img {
    max-width: 135px;
  }

  .scrollable-category .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* header responsive */

  /* .header-right {
        display: none;
    } */

  .mobile-search {
    display: block !important;
    margin-top: 3px;
    margin-left: auto;
  }

  .header-right .search-container {
    display: none;
  }

  .header-right {
    width: 45%;
  }

  .p-pic img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  .mobile-search i {
    font-size: 19px;
    color: #999999;
  }

  .my-profile-menu .dropdown-menu {
    min-width: 8rem;
    right: 0px !important;
    margin-top: 10px !important;
    left: auto !important;
  }

  .toggle img {
    max-width: 25px;
  }

  .toggle {
    margin-right: 5px;
    cursor: pointer;
  }

  .notification-section:before {
    content: "";
    margin-top: 0px;
    left: 63%;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid #fff;
    position: absolute;
    top: -18px;
  }

  .online-booking {
    display: flex;
    justify-content: flex-start;
    width: auto;
  }

  .sidebar-wrapper {
    box-shadow: none;
  }

  .sidebar-menu a.navbar-brand img {
    left: -5px;
  }

  /* end header */
  /* my profile section */
  /* .login-form {
    padding: 0px;
    max-width: 500px;
    margin: 0px auto;
    background: none;
    border-radius: 8px;
    box-shadow: none;
  } */

  /* .register-form {
    padding: 0px;
    max-width: 600px;
    margin: 0px auto;
    background: none;
    border-radius: 8px;
    box-shadow: none;
  } */
  /* .google-btn button, .facebook-btn button.kep-login-facebook.metro{
        padding: 5px !important;
    } */
  .toggle-sidebar {
    display: block !important;
  }

  .toggle-sidebar i {
    font-size: 25px;
  }

  .my-profile-container.main-layout-expand .my-profile-sidebar {
    position: absolute;
    left: 0;
    top: 0px;
    bottom: 0px;
  }

  .my-profile-sidebar {
    position: absolute;
    top: 0px;
    left: -300px;
    bottom: 0px;
    z-index: 9999999;
    transition: all 0.5s;
    min-height: auto !important;
  }

  .my-appointments .nav-link {
    padding-bottom: 8px;
    font-size: 14px;
    line-height: 18px;
  }

  /* store details */
  .store-slider img {
    width: 100%;
    height: 280px;
    object-fit: cover;
  }

  .about-store ul li {
    padding: 5px 10px;
  }

  .featured-service-list {
    flex-wrap: wrap;
  }

  .featured-service-box {
    border: 1px dashed #e8e8e8;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    width: 48%;
    cursor: pointer;
    margin-bottom: 15px;
  }

  .booknow-fix-btn {
    padding: 15px 20px;
  }

  .booknow-fix-btn .btn {
    width: 140px;
  }

  .banner-content h2 {
    font-size: 35px;
  }

  .banner-content {
    padding: 30px;
  }

  .banner-content p {
    font-size: 14px;
  }

  .home-banner {
    background: #313131 url("assets/landing-mob.png") !important;
    padding: 80px 0px;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }

  .notification-section {
    right: 10px;
  }

  .admin-header .notification-section {
    right: 30px;
  }

  .category-info {
    padding: 10px 10px;
  }

  .category-info h2 {
    font-size: 14px;
  }

  .sidebar-menu {
    padding: 10px 0px;
  }

  .side-nav-content ul.main-navigation li a {
    font-size: 14px;
  }

  .sidebar-wrapper {
    width: 80vw;
    left: -81vw;
    padding: 50px 25px;
  }

  .side-nav-content ul.main-navigation li {
    margin: 25px 0;
  }

  .side-nav-content ul.main-navigation {
    margin: 0;
  }

  .side-nav-content .axil-contact-info-inner .axil-contact-info p {
    font-size: 14px;
  }

  .side-nav-content .axil-contact-info-inner h5.title,
  .side-nav-content .axil-contact-info-inner span.title {
    font-size: 16px;
    font-weight: 600;
    padding-top: 20px;
  }

  .close-icon {
    right: -10px;
    top: -30px;
  }

  .featured-video {
    padding-bottom: 50px;
  }

  .featured-video > div {
    height: 300px !important;
  }

  .sub-title {
    padding-bottom: 30px;
  }

  .section-b-space {
    padding-bottom: 0px;
  }

  .sub-footer ul {
    flex-wrap: wrap;
  }

  .sub-footer ul li {
    width: 50%;
    text-align: center;
    padding: 5px 0px;
  }

  /******* Store ******/

  .store-listing-filter {
    flex-wrap: wrap;
  }

  .store-filter-box {
    justify-content: center;
    flex-wrap: wrap;
  }

  .store-filter-box .dropdown {
    margin-left: 0;
    width: 100%;
    margin-top: 10px;
  }

  .store-filter-box .dropdown .btn {
    width: 100%;
  }

  .store-search-result {
    text-align: center;
    width: 100%;
  }

  .store-list {
    width: 100%;
    padding: 0;
  }

  .store-image {
    width: 100%;
  }

  .store-detail {
    width: 100%;
    padding: 20px;
  }

  .store-image img {
    width: 100%;
    height: 220px;
  }

  .select-service-tab nav.nav.nav-tabs {
    width: 100%;
    margin-bottom: 10px;
  }

  .store-service-tab .select-service-tab nav.nav.nav-tabs {
    width: 100%;
    margin-bottom: 10px;
  }

  .store-service-tab .select-service-tab .tab-content {
    width: 100%;
    padding: 0;
  }

  .select-service-tab .tab-content {
    width: 100%;
  }

  .about-store > ul {
    text-align: center;
    margin-bottom: 1rem !important;
  }

  .stepwizard {
    padding: 20px 0;
  }

  .notification-area {
    margin: 0 0;
  }

  .store-service-tab .select-service-tab .nav-tabs .nav-link {
    width: 50%;
    border-left: 1px solid #ddd;
  }

  .store-service-tab .select-service-tab .nav-tabs .nav-link.active:after {
    content: "";
    width: 100% !important;
    height: 4px !important;
    position: absolute;
    border: none !important;
    left: 0;
    right: 100% !important;
    top: 0 !important;
    background: #007bff;
  }

  .error-badge {
    margin-right: 0;
    text-align: center;
    width: fit-content;
  }

  .my-voucher .voucher-section .voucher-card {
    height: auto;
  }

  .voucher-card:after {
    left: -15px;
  }

  .voucher-card:before {
    right: -15px;
  }

  /************************ Partner Pannel **************************************/
  /* .footer-button .btn {
    font-size: 14px;
  } */
  /* Appointment page */
  .rbc-btn-group {
    width: 100%;
  }

  .rbc-toolbar .rbc-toolbar-label {
    padding: 10px;
    text-align: left;
    width: 100%;
  }

  .rbc-toolbar button {
    align-items: center;
    width: 33.33%;
  }

  .my-profile-menu {
    padding-left: 15px;
  }

  .p-pic {
    padding-right: 0;
  }

  .p-pic .dropdown-toggle.btn {
    max-width: 200px;
    width: 100%;
  }

  .author__access_area {
    justify-content: flex-end;
    width: auto;
  }

  .mobile-search {
    display: block;
  }

  .header.searchwrap .header-right .search-container {
    display: block;
  }

  .header .search-container > span {
    display: block;
  }

  .header.searchwrap .search-container {
    background: #fff;
    padding: 30px 20px 15px;
    z-index: 9999;
    position: fixed;
    width: 100%;
    top: -0;
    max-width: 100%;
    height: 100%;
    left: 0;
  }

  .header-right .search-container {
    background: #fff;
    padding: 30px 20px 15px;
    z-index: 9999;
    position: fixed;
    width: 100%;
    top: -0;
    max-width: 100%;
    height: 100%;
    left: 0;
    transition: 0.3s all ease-in;
    box-shadow: 1px 0px 10px 0px rgb(0 0 0 / 10%);
    z-index: 999;
  }

  .searchwrap .header-right .search-container {
    top: 0;
    height: 100%;
  }

  .header-right .search-container span {
    position: absolute;
    top: 2px;
    right: 5px;
    font-size: 20px;
    opacity: 0.5;
    cursor: pointer;
    transition: 0.3s all ease-in;
  }

  .header-right .search-container span:hover {
    opacity: 1;
  }

  .search-container i.fa.fa-map-marker {
    position: absolute;
    top: 43px;
    right: 32px;
    font-size: 18px;
    left: auto;
    transform: none;
  }

  .header-right .search-container .form-inline .form-group {
    margin-bottom: 0;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0px 3px 8px #0000000d;
    margin-bottom: 20px;
    overflow: hidden;
  }

  .header-right .search-container .form-inline {
    border: none;
    border-radius: 0;
    box-shadow: none;
    flex-wrap: wrap;
  }

  .search-location {
    width: 100%;
  }

  .search-location input {
    width: 100%;
    padding: 8px 25px 8px 10px;
    border-radius: 8px;
  }

  .search-container .form-control {
    font-size: 14px !important;
    border: none;
    /* max-width: 200px; */
    width: 100% !important;
    margin: 0 !important;
    max-width: 100% !important;
    box-shadow: 0px 3px 8px #0000000d;
    border: 1px solid #e0e0e0;
    height: 100%;
    padding: 8px 35px 8px 10px;
  }

  .search-container .form-control:hover,
  .search-container .form-control:focus {
    outline: inherit;
    box-shadow: 0px 3px 8px #0000000d !important;
    border: 1px solid #e0e0e0 !important;
  }

  .form-inline .btn-primary {
    position: absolute;
    right: 20px;
    top: 90px;
  }

  .search-container .autocomplete {
    position: absolute;
    margin-top: 5px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    left: 0;
    padding: 0 20px;
  }

  .search-container .autocomplete .autocomplete-box {
    background-color: white;
    box-shadow: 0px 3px 8px #0000000d;
    width: 100%;
    padding: 8px 20px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .search-container .autocomplete .autocomplete-box span {
    display: inline-block;
    position: relative;
    font-size: 14px;
    white-space: normal;
  }

  /* .signup {
    background: #fff;
  } */

  .transparent-bg {
    padding-bottom: 20px;
  }

  .footer-box {
    flex-flow: column;
    padding-bottom: 20px;
  }

  .copyright {
    margin-bottom: 10px;
  }

  /* .footer-button .btn {
    font-size: 14px;
  } */
  .register-form {
    padding: 30px 27px 15px 27px;
  }

  .register-form h1 {
    font-size: 22px;
  }

  .register-form .rigister-tab {
    margin-top: 25px;
  }

  .register-form .form-label {
    font-size: 14px;
  }

  .register-form .form-group .form-control {
    font-size: 11px !important;
  }

  .register-form .form-group {
    margin-bottom: 15px;
  }

  .s-btn.btn.btn-primary {
    margin-top: 10px;
    padding: 7px 40px;
    font-size: 14px;
  }
}
@media screen and (max-width: 1600px) {
  .calendar-days a {
    font-size: 18px;
    padding: 5px 10px;
  }
  .payment-tax .btn-primary {
    font-size: 18px !important;
  }
  .select-dropdown.dropdown {
    height: 40px;
  }
  .voucher-gift {
    padding: 40px 30px;
  }
  .sb-left {
    font-size: 18px;
  }
  .sb-left h2 {
    font-size: 52px;
    margin-bottom: 25px;
  }
  .sb-btn .btn-primary {
    font-size: 18px;
    min-width: 128px;
  }
  .badge {
    font-size: 14px !important;
  }
  .sb-left > p {
    margin-bottom: 34px;
  }

  .additional-notes {
    padding: 0 30px;
  }
  .new-select-service .form-label {
    font-size: 16px;
  }
  .service-bg.new-select-service .page-title {
    font-size: 30px;
    padding-bottom: 25px;
  }
  .new-select-service .card img {
    height: 230px;
  }
  .service-bg.new-select-service .box-details .card-title {
    padding: 30px 22px 0;
    font-size: 20px;
  }
  .service-bg.new-select-service .box-details .card-text {
    font-size: 16px;
    padding: 0 22px 14px;
  }
  .service-bg.new-select-service .no-service.date-time {
    padding: 0 22px;
  }
  .new-select-service .left-scrollbar {
    max-height: 155px;
    padding-top: 10px;
  }
  .new-select-service .content-detail h6,
  .new-select-service .content-detail span {
    font-size: 16px;
  }
  .service-bg.new-select-service .content-detail {
    padding: 15px 22px;
  }
  .new-select-service .border-des {
    /* padding: 0px 20px; */
  }
  .new-select-service .total-rate {
    padding: 20px;
  }
  .new-select-service .book-now {
    padding: 10px 20px 20px;
  }
  .new-select-service .book-now .btn {
    padding: 10px 16px;
    font-size: 18px;
  }
  .new-select-service .total-rate h6 {
    font-size: 20px;
  }
  .stepwizard span.stepwizard-steps {
    font-size: 20px;
  }
  .stepwizard {
    padding: 40px 30px;
  }
  .service-heading {
    font-size: 20px;
    padding: 0 30px;
  }
  .select-service-tab {
    padding: 20px 30px;
  }
  .new-select-service
    .store-service-tab
    .select-service-tab
    .nav-tabs
    .nav-link {
    font-size: 18px;
  }
  .new-select-service .select-service-name h4,
  .new-select-service .new-service-cost,
  .new-select-service .select-service-name span {
    font-size: 18px;
  }
  .scrollbar-table {
    max-height: 400px;
    height: auto;
  }
  .select-staff-pic img {
    width: 84px;
    height: 84px;
  }
  .prefer-scrollbar {
    padding: 0 30px;
  }
  .new-select-service .select-staff-name h4 {
    font-size: 20px;
  }
  .new-select-service .select-staff-name span {
    font-size: 17px;
  }
  .prefer-scrollbar {
    max-height: 468px;
    height: 100%;
  }
  .service-bg.new-select-service .chek-3 {
    margin: 0 30px;
  }
  .service-bg.new-select-service .date-calendar .calendar-grid h6,
  .service-bg.new-select-service .date-calendar span {
    font-size: 18px;
  }
  .select-time .date-calendar .calendar-grid {
    height: 75px !important;
    width: 75px !important;
  }
  .select-time .date-calendar {
    max-width: 560px;
  }
  .date-calendar .slick-list {
    margin: 0 60px;
  }
  .new-select-service .select-time .heading-month h4 {
    font-size: 20px;
  }
  .select-time .time-scrollbar {
    max-height: 280px;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .service-bg.new-select-service .time-details .service-card {
    padding: 0 0 30px;
  }
  .new-select-service .select-time .date-calendar {
    margin: 0px auto 65px;
  }
  .service-bg.new-select-service .no-service {
    padding: 0 22px;
  }
  .service-bg .no-service h4 {
    font-size: 16px;
  }
  .paymentdetail .paymentdetail-content {
    padding: 0 30px 20px;
  }
  .new-select-service .paymentdetail .sub-heading h5 {
    font-size: 20px;
  }
  .new-select-service .paymentdetail .sub-heading p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .new-select-service .paymentdetail-content .form-label {
    font-size: 16px;
  }
  .new-select-service .paymentdetail .form-control {
    font-size: 16px !important;
  }
  .new-select-service .paymentdetail .booking-notes p {
    font-size: 16px;
  }
  .paymentdetail textarea.form-control {
    height: 200px !important;
  }
  .succ-12 h4 {
    font-size: 24px;
  }
  .succ-12 > a {
    font-size: 20px;
  }
  .succ-12 img {
    margin-bottom: 25px;
  }
  .succ-12 {
    padding: 60px 20px;
    background-color: #fff;
    width: 610px;
    border-radius: 20px;
  }
  .service-bg .page-title a {
    font-size: 16px;
  }
  /* .admin-title .btn-perple {
    font-size: 14px !important;
  } */
  .taxes .btn-primary {
    padding: 5px 20px;
    font-size: 18px;
  }
  .payment-tax .partner-table .btn-primary {
    padding: 5px 10px;
  }
  .discount-type .btn-primary {
    padding: 5px 12px;
    font-size: 18px;
  }
  .select-all-staff {
    padding-bottom: 25px;
  }
  .staff-copmmision {
    padding-top: 25px;
  }
  .closedatemodal.edithour .btn.btn-primary {
    font-size: 18px;
  }
}
@media screen and (max-width: 1400px) {
  .additional-notes {
    padding: 0 30px;
  }
  .new-sidebar .close-icon.show img {
    width: 20px;
  }
  .new-sidebar .close-icon {
    top: 40px;
  }
  .service-bg.new-select-service .page-title {
    font-size: 25px;
    padding-bottom: 20px;
  }
  .new-select-service .card img {
    height: 180px;
  }
  .service-bg.new-select-service .box-details .card-title {
    padding: 28px 20px 0;
    font-size: 20px;
  }
  .service-bg.new-select-service .box-details .card-text {
    font-size: 16px;
    padding: 0 20px 14px;
  }
  .service-bg.new-select-service .no-service.date-time {
    padding: 0 20px;
  }
  .new-select-service .total-rate h6 {
    font-size: 18px;
  }
  .select-staff-name {
    padding-left: 15px;
  }
  .new-select-service .select-staff-name h4 {
    font-size: 18px;
  }
  .new-select-service .select-staff-name span {
    font-size: 15px;
  }
  .select-time .date-calendar {
    max-width: 550px;
  }
  .date-calendar .slick-list {
    margin: 0 50px;
  }
  .select-time .date-calendar .calendar-grid {
    height: 70px !important;
    width: 70px !important;
  }
  .service-bg.new-select-service .date-calendar .calendar-grid h6,
  .service-bg.new-select-service .date-calendar span {
    font-size: 16px;
  }
  .no-availability {
    font-size: 18px;
    font-family: "AvenirHeavy";
    color: #000;
  }
  .paymentdetail textarea.form-control {
    height: 150px !important;
  }
  .succ-12 img {
    margin-bottom: 28px;
    width: 65px;
  }
  .succ-12 h4 {
    font-size: 20px;
  }
  .succ-12 > a {
    font-size: 16px;
  }
  .succ-12 {
    padding: 50px 20px;
    width: 510px;
    border-radius: 10px;
  }
  .side-nav-content ul.main-navigation li a {
    font-size: 16px;
  }
  .new-sidebar .title,
  .new-sidebar .address > p {
    font-size: 16px;
  }
  .store-service-tab .select-service-tab nav.nav.nav-tabs {
    width: 260px;
  }
  .store-service-tab .select-service-tab .tab-content {
    width: calc(100% - 260px);
  }
  .service-bg.new-select-service .content-detail {
    /* padding: 10px 0; */
    padding: 10px 20px;
  }
  .new-select-service .select-time .heading-month h4 {
    font-size: 18px;
  }
  .new-sidebar address + address {
    margin-top: 30px;
  }
}
