.home-banner {
  background: #313131 url("../../assets/landing-1.png");
  background-repeat: no-repeat;
  padding: 115px 0px;
  background-size: cover;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 125px);
  display: flex;
  align-items: center;
}

.banner-content {
  padding: 50px 0px;
  border-radius: 2px;
  max-width: 450px;
}

.banner-content h2 {
  font-weight: 700;
  font-size: 45px;
  margin: 0;
  padding-bottom: 0px;
}

.banner-content p {
  max-width: 480px;
  font-size: 20px;
  font-family: "AvenirMedium";
  line-height: 22px;
  margin-top: 10px;
}

.home-category {
  padding: 45px 0px;
}

.category-box a {
  color: #000;
}

.category-box a:hover {
  text-decoration: none;
}

.scrollable-category .col-md-3 {
  flex: 0 0 20%;
  max-width: 20%;
  margin-bottom: 30px;
}

.category-thumb img {
  width: 135px;
  height: 135px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #80c3d3;
}

/* .category-thumb::before {
  border-radius: 100%;
  content: '';
  background-image: linear-gradient(to bottom, #80c3d3 0%, #8ccad9 100%);
  top: 2px;
  left: 14px;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  width: 142px;
  height: 142px;
} */
.category-info {
  /* border: 1px solid #efefef; */
  padding: 15px 20px;
}

.category-thumb {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 5px;
}

.home-category ul.slick-dots {
  display: none !important;
}

.category-info {
  /* border: 1px solid #efefef; */
  padding: 0;
  text-align: center;
}

/* .home-category .slick-prev {
  display: none !important;
} */

.category-info h2 {
  font-size: 19px;
  margin: 0;
  padding-bottom: 2px;
}

.category-info a {
  color: #777;
  font-size: 12px;
  font-weight: 500;
}

.category-info a:hover {
  text-decoration: none;
}

.category-box a:hover .category-info {
  color: #000;
}

.category-box {
  position: relative;
  margin-bottom: 30px;
  border-radius: 50%;
  /* width: calc(100% - 15px) !important; */
}

.home-category .title {
  margin: 0;
  font-weight: 400;
  font-size: 30px;
  padding-left: 18px;
  padding-bottom: 10px;
}

.title {
  margin: 0;
  font-weight: 400;
  font-size: 30px;
  padding-bottom: 10px;
}

.title span {
  font-weight: 700;
}

.scrollable-category {
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollable-category::-webkit-scrollbar {
  display: none;
}

.featured-store {
  padding: 68px 0px;
}

.featured-store-thumb img {
  width: 100%;
  height: 166px;
  object-fit: cover;
}

.featured-store-info {
  padding: 10px 0px;
  background: #fff;
}

.featured-store-info h2 {
  font-size: 17px;
  margin: 0;
  padding-bottom: 5px;
  font-weight: 600;
}

.featured-store-info a {
  color: #777;
  font-size: 12px;
  font-weight: 500;
}

.featured-store-info a:hover {
  text-decoration: none;
}

.featured-store-box {
  position: relative;
  margin-bottom: 30px;
}

.featured-store-info p {
  font-size: 13px;
  margin: 0;
  padding-top: 5px;
}

.featured-store .title {
  text-align: left;
}

.featured-rating i {
  color: #ffd335;
}

.featured-rating span {
  font-weight: 600;
}

.testimonial-box {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 35px;
  display: flex;
  align-items: center;
}

.testimonial-thumb img {
  width: 72px;
  height: 72px;
  margin: 0px auto;
  display: block;
  border-radius: 100%;
  border: 3px solid #51c5ff;
}

.testimonial-info h2 {
  /* text-align: center; */
  font-size: 19px;
  margin: 0;
  padding: 0px;
  padding-right: 7px;
}

.testimonial-info {
  padding-left: 10px;
}

.testimonial-info span {
  font-size: 17px;
  margin: 0;
  line-height: 18px;
  font-family: "AvenirMedium";
}

.testimonial-info p {
  font-size: 15px;
  margin: 0;
  line-height: 18px;
  font-family: "AvenirMedium";
}

.scrollable-testimonial {
  height: 395px;
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollable-testimonial::-webkit-scrollbar {
  display: none;
}

.scrollable-testimonial .testimonial-box:last-child {
  margin: 0;
}

.venues-section .like-icon {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.venues-section .featured-store-thumb {
  position: relative;
  height: 166px;
  border: 1px solid #f2f2f2;
  border-bottom: none;
}

.venues-section .like-icon i {
  color: white;
  font-size: 22px;
}

.venues-section .venues-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.venues-section .featured-store-info h2 {
  font-size: 20px;
  margin: 0;
  padding-bottom: 0;
  font-weight: 600;
}

.venues-section .venues-rating p {
  font-size: 8px;
  margin-bottom: 0px;
  text-align: right;
  line-height: 11px;
  padding-top: 0px;
  margin-right: 10px;
  font-weight: 400;
  font-family: "AvenirBook";
  color: #000;
}

.venues-section .venues-rating {
  display: flex;
  align-items: center;
}

.venues-section .venues-rating h6 {
  font-size: 14px;
  margin-bottom: 0px;
  padding: 4px 6px;
  background-color: #40c1ff;
  border-radius: 2px;
  color: #fff;
}

.venues-heading-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.venues-heading-bottom h5 {
  margin-bottom: 0px;
  margin: 5px 0 0 5px;
  font-family: "AvenirMedium";
  font-size: 11px;
  padding: 2px 7px;
  background-image: linear-gradient(to top, #a7b3ff, #6fd5ff);
  color: white;
  border-radius: 2px;
  text-transform: uppercase;
}

.featured-store-info .venues-heading-bottom p {
  font-size: 14px;
  margin: 0;
  padding-top: 0px;
  line-height: 15px;
  font-family: "AvenirBook";
  color: #707070;
}

.venues-section .featured-store-box {
  margin-bottom: 10px;
}

.business-owner-section img {
  height: 285px;
}

.bussiness-owner .business-owner-section {
  /* background: url(../../assets/bussiness.jpg); */
  height: 285px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  background-image: linear-gradient(to right, #b5a1da, #573180);
  position: relative;
}

.business-content h6 {
  font-size: 45px;
  line-height: 45px;
  color: white;
  margin-bottom: 20 px;
}

/* 
.business-content {
  max-width: 230px;
} */
.business-content p {
  font-size: 19px;
  line-height: 20px;
  font-family: "AvenirMedium";
}

.business-content button {
  background-color: #51c5ff;
  color: white;
  border: 1px solid #51c5ff;
  font-size: 20px;
  font-family: "AvenirLight";
  border-radius: 5px;
  padding: 3px 10px;
}

.business-owner-image {
  position: absolute;
  right: 45px;
}

.bussiness-owner {
  margin-top: 58px;
}

.home-category .slick-next:before {
  background: url(../../assets/slider-icon.png) !important;
  height: 50px !important;
  width: 31px !important;
  opacity: 1;
  background-size: cover;
  background-repeat: no-repeat !important;
  z-index: 9999999999 !important;
}
.home-category .slick-prev:before {
  background: url(../../assets/slider-icon.png) !important;
  height: 50px !important;
  width: 31px !important;
  opacity: 1;
  background-size: cover;
  background-repeat: no-repeat !important;
  z-index: 9999999999 !important;
  transform: rotate(-180deg);
}
.home-category .slick-next,
.home-category .slick-prev {
  background-color: transparent !important;
  padding: 0px;
  right: 0px !important;
  height: auto !important;
  width: auto !important;
  margin-top: -22px;
}
.home-category .slick-prev {
  right: auto !important;
  left: 0 !important;
}
.social-icon {
  display: grid;
  position: absolute;
  right: 10px;
  transform: translate(-50%, -50%);
  top: 50%;
  text-align: center;
  z-index: 99;
}

.social-icon i {
  margin: 5px 0px;
  font-size: 25px;
}

.testimonial-info span {
  position: relative;
  padding-left: 7px;
  margin-left: 7px;
}

.testimonial-info span:before {
  content: "";
  width: 2px;
  height: calc(100% - 10px);
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
  background: #000;
}

.venues-section .venues-rating p span {
  font-size: 11px;
  font-family: "AvenirMedium";
}

.slick-slider.slick-width.slick-initialized {
  width: calc(100% + 40px);
  margin-left: -20px;
}
