/* .varify .header,
.varify .footer {
  display: none;
} */
.Verify-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgb(0, 0, 0, 0.1);
}

.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}
h1.loading {
  font-size: 50px;
  margin-bottom: 0;
  font-weight: 700;
  color: #262f3e;
}
@keyframes dots {
  0%,
  20% {
    font-size: 80px;
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    font-size: 80px;
    color: #262f3e;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    font-size: 80px;
    text-shadow: 0.25em 0 0 #018ccf, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    font-size: 80px;
    text-shadow: 0.25em 0 0 #018ccf, 0.5em 0 0 #018ccf;
  }
}
