.navbar-light .navbar-brand img {
  max-width: 180px;
}

.header {
  width: 100%;
  /* background: #f7f7f7; */
  position: relative;
  z-index: 999;
  /* box-shadow: 0px 0px 10px #00000014; */
}

.header .navbar {
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0rem;
  /* padding:28px 0; */
}

.btn-primary:hover,
.btn-primary:focus {
  color: #fff;
  background-color: #373373;
  border-color: #373373;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #373373;
  border-color: #373373;
}

.author__access_area ul {
  margin: 0;
}

.btn-gradient.btn-gradient-two:hover,
.btn-gradient.btn-gradient-two:focus {
  color: #fff;
}

.author__access_area ul li > span {
  color: #777;
  display: inline-block;
  padding: 0 10px;
}

.author__access_area ul li .access-link {
  color: #000;
  cursor: pointer;
  font-weight: 700;
}

.navbar-light .navbar-nav .nav-link {
  color: #000 !important;
  font-weight: 500;
  margin-right: 40px;
  font-size: 15px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #f1454f !important;
}

.author__access_area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 20px;
  width: 166px;
  font-family: "AvenirMedium";
}

.author__access_area ul li .access-link:hover {
  text-decoration: none;
  color: #018cce;
}

.form-control {
  font-size: 14px !important;
}

.toggle img {
  height: 24px;
}

/* .p-name .dropdown-toggle.btn {
  background: transparent !important;
  font-size: 12px;
  color: #000;
  border-color: transparent !important;
  border: none;
  box-shadow: none;
  padding: 0;
  line-height: 14px;
  font-weight: 400;
  white-space: normal;
  text-align: left;
}
.p-name h5 {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
  padding-bottom: 2px;
}
.p-name .dropdown {
  margin-top: -5px;
} */
.p-name {
  word-break: break-all;
}

.p-pic .dropdown-toggle.btn {
  background: transparent !important;
  font-size: 12px;
  color: #000;
  border-color: transparent !important;
  border: none;
  box-shadow: none;
  padding: 0;
  line-height: 14px;
  font-weight: 400;
  white-space: normal;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
}

.p-name {
  padding-left: 5px;
}

.p-pic .dropdown-toggle::after {
  content: none;
}

.p-pic h5 {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
  padding-bottom: 2px;
}

.p-pic .dropdown {
  margin-top: -5px;
}

.my-profile-menu .nav-link {
  padding: 0;
  font-size: 11px;
  cursor: pointer;
}

.p-pic img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.my-profile-menu {
  display: flex;
  align-items: center;
}

.my-profile-menu .dropdown-menu {
  min-width: 9rem;
  margin-left: 22px !important;
  margin-top: 2px !important;
}

.my-profile-menu .dropdown-item {
  font-size: 12px;
  padding: 5px 10px;
  color: #555;
}

.my-profile-menu .dropdown-item:active {
  background: #f8f9fa;
  color: #000;
}

.p-pic {
  padding-right: 10px;
}

/* .header-left {
  display: flex;
  align-items: center;
  width: 40%;
} */

/* .header-right {
  display: flex;
  align-items: center;
  width: 60%;
  justify-content: space-between;
} */

.toggle {
  margin-right: 15px;
  cursor: pointer;
}

.search-container .form-control {
  font-size: 13px !important;
  border: none;
  max-width: 250px;
  width: 100% !important;
  margin: 0 !important;
  background-color: transparent;
}

.search-container .form-control:focus {
  outline: none;
  box-shadow: none;
}

.search-container .dropdown .btn {
  background: transparent;
  color: #777;
  font-size: 14px;
  border: none;
  width: 100%;
  text-align: left;
  border-right: 1px solid #dadada;
  border-radius: 0;
  padding: 0px 15px;
}

.search-container {
  width: 70%;
  position: relative;
}

.search-container .dropdown .btn:hover,
.search-container .dropdown .btn:focus,
.search-container .show > .btn-primary.dropdown-toggle,
.search-container .btn-primary:not(:disabled):not(.disabled):active,
.search-container .show > .btn-primary.dropdown-toggle {
  border-right: 1px solid #dadada !important;
}

.search-container .btn,
.search-container .btn:hover {
  background: transparent;
  color: #777;
  border: none;
}

.search-container .btn:focus {
  box-shadow: none !important;
}

.search-container .form-inline {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  justify-content: space-between;
  box-shadow: 0px 3px 8px #0000000d;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: transparent !important;
  color: #777 !important;
  border: none !important;
}

.search-container .dropdown {
  max-width: 150px;
  width: 100%;
}

.search-container .dropdown-item {
  font-size: 12px;
  color: #777;
}

.search-container .dropdown-toggle::after {
  background: url("../../assets/arrow.png");
  width: 13px;
  height: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  position: absolute;
  right: 15px;
  top: 7px;
  margin: 0;
}

/********** Sidebar *********/

.sidebar-wrapper {
  width: 330px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -331px;
  z-index: 9999;
  padding: 55px;
  background: #000;
  -webkit-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
  -o-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
  overflow-y: auto;
  box-shadow: 0px 10px 20px #0000005e;
}

.sidebar-wrapper.expand {
  left: -1px;
}

.sidebar-menu {
  position: relative;
  padding: 50px 50px;
}

.sidebar-menu ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.close-icon {
  position: absolute;
  right: -20px;
  top: -20px;
  cursor: pointer;
}

.close-icon img {
  width: 30px;
  opacity: 0;
  transition: all 0.5s;
}

.close-icon.show img {
  width: 30px;
  opacity: 1;
}

.sidebar-menu ul li a:hover {
  color: #018ccf;
  text-decoration: none;
}

.side-nav-content ul.main-navigation {
  margin: -45px 0;
}

.side-nav-content ul.main-navigation li {
  margin: 25px 0;
}

.side-nav-content ul.main-navigation li a {
  color: #ffffff;
  font-size: 18px;
  text-decoration: none;
  font-family: "AvenirHeavy";
  text-transform: capitalize;
}

.side-nav-content .axil-contact-info-inner h5.title,
.side-nav-content .axil-contact-info-inner span.title {
  font-size: 18px;
  line-height: 1;
  color: #ffffff;
  font-weight: 500;
  display: block;
  margin-bottom: 15px;
  text-align: left;
  padding-bottom: 0;
}

.side-nav-content .axil-contact-info-inner .axil-contact-info p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 4px;
}

.side-nav-content .axil-contact-info-inner .axil-contact-info .address p a {
  color: #ffffff;
  text-decoration: none;
}

.side-nav-content .axil-contact-info-inner .axil-contact-info .address p i {
  padding-right: 14px;
  font-size: 14px;
  color: #ffffff;
}

.side-nav-content
  .axil-contact-info-inner
  .axil-contact-info
  + .axil-contact-info {
  margin-top: 30px;
}

ul.social-share {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-decoration: none;
  margin: -10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

ul.social-share li {
  margin: 10px;
}

ul.social-share.style-rounded li a {
  width: 32px;
  display: block;
  height: 32px;
  line-height: 32px;
  border-radius: 100%;
  background: #333333;
  text-align: center;
  color: #757589;
  text-decoration: none;
  font-size: 15px;
}

ul.social-share.style-rounded li a i {
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
}

.side-nav-content
  .axil-contact-info-inner
  .axil-contact-info
  .address
  + .address {
  margin-top: 30px;
}

address {
  margin-bottom: 15px;
  color: #fff;
}

.toggle .btn.btn-primary,
.toggle .btn.btn-primary:hover,
.toggle .btn.btn-primary:focus {
  background: transparent !important;
  border-color: transparent !important;
  padding: 0;
  border: none !important;
  box-shadow: none !important;
}

.close-icon .btn.btn-primary,
.close-icon .btn.btn-primary:hover,
.close-icon .btn.btn-primary:focus {
  background: transparent !important;
  border-color: transparent !important;
  padding: 0;
  border: none !important;
  box-shadow: none !important;
}

.my-profile-menu a {
  font-size: 12px;
  padding: 7px 10px;
  color: #555;
  font-weight: 400;
  display: block;
  width: 100%;
}

.my-profile-menu a:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

/* 
notification section */
.notification-area {
  display: flex;
}

.notification-area i {
  font-size: 20px;
  color: #898989;
  cursor: pointer;
}

.header-right .search-container .search-dropdown-container {
  position: absolute;
  background-color: white;
  width: 100%;
  max-height: 400px;
  height: auto;
  overflow: auto;
}

.header-right .search-container .search-dropdown {
  border-bottom: 1px solid #f1f0f0;
  /* display: flex; */
  padding: 12px 20px;
  /* justify-content: space-between; */
}

.header-right .search-container .search-dropdown h4 {
  font-size: 16px;
  margin-bottom: 0px;
  cursor: pointer;
  white-space: nowrap;
}

.header-right .search-container .search-items {
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  justify-content: flex-start;
  padding: 0px;
  border-bottom: none;
  cursor: pointer;
  width: 100%;
}

.header-right .search-container .search-dropdown h3 {
  font-size: 12px;
  margin-bottom: 0px;
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
}

.header-right
  .search-container
  .search-dropdown-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #555;
}

.header-right .search-container .search-dropdown-container::-webkit-scrollbar {
  width: 5px;
  background-color: #555;
}

.header-right
  .search-container
  .search-dropdown-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: white;
}

.search-container input,
.search-container input:hover,
.search-container input:focus {
  border: none;
  padding-left: 10px;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  padding-right: 20px;
  font-size: 13px !important;
}
.search-container input::placeholder {
  color: #000;
}
.search-container input::placeholder {
  color: #777;
}

.search-container .autocomplete {
  position: absolute;
  margin-top: 10px;
  width: 100%;
  height: 100%;
}

.search-container .autocomplete .autocomplete-box {
  background-color: white;
  box-shadow: 0px 3px 8px #0000000d;
  width: 100%;
  padding: 10px 20px;
}

.search-container input.form-control::placeholder {
  color: #000 !important;
}

.search-container .autocomplete * {
  cursor: pointer;
  white-space: normal;
  word-break: break-word;
}

.search-container .autocomplete .autocomplete-box {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.search-container .autocomplete span {
  display: inline-block;
  position: relative;
  white-space: normal;
}

.search-container i.fa.fa-map-marker {
  position: absolute;
  right: 10px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  opacity: 0.5;
  cursor: pointer;
}

.mobile-search {
  cursor: pointer;
  display: none;
}

.form-inline .form-group {
  border-right: 1px solid #e0e0e0;
}

.search-dropdown .search-content {
  padding-left: 0;
}

.header .search-container {
  max-width: 480px;
  width: 100%;
  font-family: "AvenirLight";
  position: relative;
}

.header-left {
  display: flex;
  align-items: center;
  width: auto;
}

.header-right {
  display: flex;
  align-items: center;
  /* width: 60%; */
  justify-content: space-between;
}

.search-container .form-inline {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  /* justify-content: space-between; */
  box-shadow: 0px 3px 8px #0000000d;
  white-space: nowrap;
  flex-flow: nowrap;
}

.search-container .form-control {
  border: none;
  max-width: 100%;
}

.header .search-container > span {
  display: none;
}

.sidebar-menu a.navbar-brand img {
  max-width: 170px;
  position: absolute;
  top: -30px;
  left: 45px;
}

.sidebar-menu a.navbar-brand {
  padding: 0;
  margin: 0;
  display: block;
}

/**********new design css *************/
.author__access_area ul li .signup-link {
  /* color: #00adff; */
  cursor: pointer;
  /* font-weight: 500; */
  text-decoration: none;
}

.author__access_area ul li .access-link:hover {
  text-decoration: none;
  color: #00adff;
}

.search-container .form-inline .form-group {
  position: relative;
}

.search-container button.btn.btn-primary img {
  height: 15px;
  width: 15px;
}

.search-container .form-inline .form-group {
  width: 37%;
}

.search-container input,
.search-container input:hover,
.search-container input:focus {
  padding-right: 60px;
}

.access-link.active {
  color: #00adff !important;
  font-weight: 500;
}

@media (min-width: 576px) {
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
}

/* New SideBar */
@media (min-width: 1850px) {
  .new-sidebar .sidebar-menu {
    height: 100%;
  }
}
.new-sidebar .sidebar-menu a.navbar-brand {
  padding: 23px 60px;
}
.new-sidebar .sidebar-menu {
  /* position: relative; */
  padding: 0;
  height: 100%;
  display: flex;
  flex-flow: column;
}
.new-sidebar .sidebar-menu a.navbar-brand {
  padding: 23px 60px;
  margin: 0;
}

.new-sidebar .sidebar-menu a.navbar-brand img {
  max-width: 165px;
  /* position: absolute; */
  top: 15px;
  left: 30px;
}
.new-sidebar .close-icon {
  right: 30px;
  top: 20px;
}
.new-sidebar .close-icon.show img {
  width: 20px;
}
.new-sidebar .side-nav-content {
  height: calc(100% - 359px);
  padding: 35px 30px;
}
.new-sidebar .axil-contact-info-bottom {
  border-top: 1px solid #00adff;
  padding: 35px 30px;
  margin-top: auto;
}
.sidebar-wrapper.new-sidebar {
  padding: 0;
}
.new-sidebar .side-nav-content ul.main-navigation {
  margin: 0;
}
.new-sidebar .title {
  margin: 0;
  font-size: 18px;
  padding-bottom: 0;
  margin-bottom: 5px;
  color: #00adff;
  display: inline-block;
  font-family: "AvenirHeavy";
}
.new-sidebar .address > p {
  font-size: 18px;
  margin-bottom: 5px;
  font-family: "AvenirBook";
}
.new-sidebar address {
  margin-bottom: 0;
}
.new-sidebar address + address {
  margin-top: 40px;
}
.new-sidebar .address > p > a {
  color: #fff;
}
.new-sidebar .address > p > a > i {
  padding-right: 10px;
}
.menu-toggle {
  display: flex;
  align-items: center;
  /* margin-left: 90px; */
}
/* .admin-container.wrapper-expand .menu-toggle {
  padding-left: 50px;
} */
.menu-toggle .patners-logo img {
  width: 150px;
  margin-left: 15px;
}
.partners-cross-icon .close-icon {
  right: 10px;
  top: 18px;
}
.partners-cross-icon .close-icon img {
  width: 16px;
  opacity: 1;
}
.menu-toggle > span > img {
  width: 20px;
}
.admin-menu .menu-logo {
  width: 100%;
  text-align: left;
  padding: 12px 10px 11px 20px;
}
