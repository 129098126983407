.service-bg {
  background-color: #f6f7fb;
  padding-top: 50px;
  padding-bottom: 50px;
}

.select-serve .store-service-tab .nav-tabs {
  justify-content: flex-start;
}

.service-bg .page-title a {
  font-size: 20px;
  /* font-weight: 500; */
  color: #000;
  font-family: "AvenirHeavy";
  font-weight: unset;
}

/* .service-title {
  padding: 25px 20px 10px;
} */
.select-serve .noservice-found {
  padding: 20px;
  width: 100%;
}

.select-serve .noservice-found h3 {
  font-size: 20px;
  text-align: center;
}

/* .service-title h3 {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
} */
.select-serve .store-service-tab {
  padding: 0;
}

.select-serve .table td,
.table th {
  padding: 1.5rem 0.75rem;
}

.service-bg .content-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0px;
  padding-top: 15px;
}

.service-bg .content-detail h6 {
  font-size: 14px;
  color: #444;
  margin-bottom: 0;
  /* font-weight: 700; */
  padding-bottom: 4px;
  text-transform: capitalize;
}

.select-serve .store-services {
  padding: 0 0;
}

.service-bg .content-detail span {
  font-size: 13px;
  color: #999;
  font-weight: 600;
}

.service-bg .rate span {
  font-size: 15px;
  font-weight: 700;
  color: #444;
}

.service-bg .total-rate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
}

.book-now {
  padding: 10px 25px 25px;
}

.service-bg .book-now .btn {
  padding: 8px 20px;
  font-size: 16px;
}

.total-rate h6 {
  font-weight: 700;
  font-size: 17px;
  color: #333;
  margin: 0;
}

.service-bg .page-title {
  margin: 0;
  padding-bottom: 40px;
  font-size: 20px;
  /* font-weight: 700; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: #333;
}

.service-bg .book-button {
  padding: 10px 20px 25px;
}

.service-bg .box-details .card-title {
  margin-bottom: 2px;
  /* font-weight: 700; */
  font-size: 20px;
  color: #333;
  text-transform: capitalize;
  padding-top: 20px;
}

.service-bg button.Book-Now.btn.btn-primary {
  width: 100%;
  border: transparent;
}

.bold-h {
  font-weight: 600;
  font-size: 16px;
  color: #333;
}

td.bold-h > div {
  font-weight: 400;
  color: #999;
}

.cus-34 h4 {
  font-size: 20px;
  padding: 0 0 0 30px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}

.scrollbar-table {
  max-height: 550px;
  height: auto;
  padding: 0px;
  overflow-y: auto;
  overflow-x: hidden;
}
.select-service-tab .scrollbar-table {
  padding-right: 15px;
}
.heading-bold {
  font-weight: 600;
}

.left-scrollbar {
  height: 205px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}

.service-bg .box-details .card-text {
  margin-bottom: 0;
  color: #999;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 20px;
}

.select-serve .service-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 20px rgb(16 25 40 / 5%);
  position: relative;
  margin-bottom: 20px;
}

.border-des {
  border-bottom: 1px solid #efefef;
  border-top: 1px solid #efefef;
  /* padding: 0px 25px; */
}

.service-bg .box-details .card-body {
  padding: 0;
}

.service-bg .no-service {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
}

.service-bg .box-details .card {
  border: none;
}

.service-bg .no-service h4 {
  font-size: 16px;
  text-align: center;
  margin: 20px 0;
  color: #999;
}

.service-bg .box-details .table td:first-child {
  border-top: none;
}

.scrollbar-table tbody tr:first-child td {
  border-top: 0;
}

.service-bg .date-calendar .calendar-grid h6 {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  color: #000;
}

.service-bg .date-calendar span {
  font-weight: 400;
  color: #000;
}

.service-bg .time-details .service-card {
  padding: 20px 0;
}

.service-bg .chek-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding: 23px 30px;
}

.service-bg .date-calendar .slick-current .calendar-grid {
  border-color: #00adff;
  background-color: #00adff;
}

.service-bg .date-calendar .calendar-grid * {
  color: #333;
}

.service-bg .date-calendar .slick-current .calendar-grid * {
  color: #fff;
}

.select-serve .store-service-tab .nav-tabs > a {
  padding: 8px 20px;
  margin-right: 25px;
  margin-left: 25px;
  text-transform: uppercase;
}

.scrollbar-table tbody tr td:last-child {
  vertical-align: middle;
}

.select-serve td.round {
  width: 60px;
}

.select-serve .round label {
  height: 40px;
  width: 40px;
}

.select-serve .card img {
  height: 180px;
  object-fit: cover;
}

.select-time .service-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
  position: relative;
  margin: 0;
}

.chek-3 h4 {
  margin-bottom: 0 !important;
}

.select-time .date-calendar .calendar-grid {
  border: 1px solid #dee2e6;
  height: 85px !important;
  width: 85px !important;
  border-radius: 50px;
  display: block !important;
  text-align: center;
  padding: 10px 10px;
  margin: 0 auto;
  cursor: pointer;
}

.select-time .heading-month h4 {
  text-align: center;
  /* font-weight: 600; */
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
}

.select-time .date-calendar {
  display: block;
  max-width: 650px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.select-time .date-calendar .calendar-grid {
  display: flex !important;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.select-time .date-calendar .calendar-grid h6 {
  margin-bottom: 0 !important;
}

.select-time .round label {
  top: 95%;
}

.select-time .time-scrollbar {
  max-height: 360px;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.time-scrollbar::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.time-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.time-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.time-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.select-time .card img {
  height: 140px;
  object-fit: cover;
}

.select-time .time-scrollbar .time-check:last-child .chek-3 {
  border-bottom: 0;
}

.select-time .box-details .card {
  border: 0;
}

.select-time {
  padding: 0px 0;
}

.date-calendar .slick-list {
  margin: 0 40px;
}

.date-calendar .slick-prev::before {
  background: url("../../../assets/slider-icon.png");
  background-size: cover;
  width: 25px;
  height: 40px;
  transform: rotate(-180deg);
}

.date-calendar .slick-next::before {
  background: url("../../../assets/slider-icon.png");
  background-size: cover;
  width: 25px;
  height: 40px;
}

.date-calendar .slick-next,
.date-calendar .slick-prev {
  background: 0 0 !important;
}

.associate-details tbody tr:first-child td {
  border-top: 0;
}

.associate-details .service-card {
  padding-top: 20px;
}

.associate-details td.customer-detail-icon {
  padding-left: 50px;
  width: 80px;
}

.associate-details td.round {
  width: 120px;
}

.time-check h4 {
  margin-bottom: 0;
  font-size: 17px;
}

.prefer-scrollbar {
  height: 468px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 40px;
}

.select-associate {
  padding: 0px 0;
}

.time-scrollbar a:hover {
  text-decoration: none;
}

.cu-12 {
  font-size: 17px;
}

.select-associate .card img {
  height: 180px;
  object-fit: cover;
}

.select-associate .service-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
  position: relative;
  padding: 0;
}

.customerlogin .login-btn {
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.customerlogin .login-btn div:first-child {
  width: 100%;
}

.customerlogin .login-btn div {
  width: 100%;
}

.signup-email a {
  margin-left: 0;
  border: 1px solid transparent;
  min-width: 100px;
  font-weight: 500;
  font-size: 14px !important;
  background: #262f3e !important;
  margin-bottom: 28px;
  width: 100%;
  padding: 13px 15px;
  display: block;
  color: #fff !important;
  border-radius: 5px;
}

.login-btn .btn {
  margin-left: 0;
  border: 1px solid transparent;
  min-width: 100px;
  font-weight: 500;
  font-size: 16px;
  background: #262f3e;
  margin-bottom: 28px;
  width: 100%;
  padding: 10px;
}

.login-btn .btn.btn-default {
  border: 1px solid #ddd !important;
  color: #333;
  background: 0 0 !important;
  box-shadow: 0 3px 10px #00000038;
}

.login-btn img {
  width: 45px;
  height: 46px;
  border: 1px solid #ced4da;
  position: absolute;
  padding: 10px;
}

.customerlogin h5 {
  padding-top: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.customerlogin a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
}

.paymentdetail .paymentdetail-content {
  padding: 0 40px 20px;
}

.paymentdetail .sub-heading h5 {
  color: #333;
  margin-bottom: 20px;
  font-weight: 700;
}

.paymentdetail .sub-heading p {
  color: #444;
  margin-bottom: 30px;
}

.paymentdetail .form-control {
  height: auto;
  padding: 12px 20px;
  background: 0 0;
  border: 1px solid #dadada;
  border-radius: 4px;
}

.paymentdetail .paywith {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ddd;
}

.paywith h6 {
  color: #000;
  font-weight: 400;
  font-size: 15px;
}

.paymentdetail .paywith img {
  width: 30px;
  height: 20px;
  margin-left: 15px;
}

.paymentdetail textarea.form-control {
  height: 361px !important;
}

.paymentdetail .cancel-policy {
  margin-bottom: 20px;
}

.paymentdetail .cancel-policy h6 {
  color: #999;
  margin: 25px 0 5px 0;
  font-weight: 600;
  font-size: 16px;
}

.paymentdetail .cancel-policy p {
  color: #000;
  margin: 0;
}

.paymentdetail .cancel-policy strong {
  font-weight: bolder;
  margin-left: 6px;
}

.paymentdetail .booking-notes {
  margin-top: 10px;
}

.paymentdetail .booking-notes p {
  color: #000;
}

.select-staff-table a {
  display: flex;
  align-items: center;
  padding: 20px 25px;
  border-bottom: 1px solid #efefef;
  text-decoration: none;
}

.select-staff-pic img {
  border-radius: 50%;
  width: 111px;
  height: 111px;
  object-fit: cover;
}

.select-staff-name {
  padding-left: 25px;
}

.select-staff-name h4 {
  font-size: 20px;
  color: #444;
  /* font-weight: 700; */
  text-transform: capitalize;
  margin: 0;
}

.select-staff-name span {
  color: #999;
  font-size: 15px;
}

.select-serve.customerlogin .service-card {
  box-shadow: none;
}

.already-account {
  text-align: center;
  padding-bottom: 20px;
}

.additional-notes {
  padding: 0 40px;
}

/****** Service Tab *****/

.select-service-tab {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 25px 40px;
}

.select-service-tab nav.nav.nav-tabs {
  flex-wrap: wrap;
  display: flex;
  width: 230px;
  border: none;
  padding-top: 10px;
}

.select-service-tab nav.nav.nav-tabs a {
  width: 100%;
  margin: 0;
}

.select-service-tab .tab-content {
  width: calc(100% - 250px);
  padding-left: 20px;
}

.store-service-tab .select-service-tab .nav-tabs .nav-link.active:hover,
.store-service-tab .select-service-tab .nav-tabs .nav-link.active:focus {
  border: none !important;
  background: #fafafa !important;
}

.store-service-tab .select-service-tab .nav-tabs .nav-link::before {
  display: none;
}

.store-service-tab .select-service-tab .nav-tabs .nav-link {
  text-transform: capitalize;
  border: none !important;
  /* font-weight: 700; */
  font-size: 16px;
  position: relative;
}

.store-service-tab .select-service-tab .nav-tabs .nav-link.active {
  /* border: none !important; */
  background: #fafafa !important;
  border-radius: 0;
}

.store-service-tab .select-service-tab .nav-tabs .nav-link.active::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 25px solid #fafafa;
  position: absolute;
  right: -25px;
  top: 0;
}

.select-service-list {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #efefef;
  align-items: center;
}

.select-service-name h4 {
  font-size: 18px;
  /* font-weight: 600; */
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 5px;
}

.select-service-name span {
  /* font-weight: 600; */
  font-size: 13px;
  color: #9191a3;
}

.scrollbar-table .select-service-list:last-child {
  border-bottom: none;
}

.no-availability {
  padding: 20px;
  text-align: center;
  margin-top: 50px;
  font-size: 20px;
  color: #999;
}

/*Success Page */

.success {
  text-align: center;
}

.succe-233 .footer {
  display: none;
}

.success {
  display: flex;
  justify-content: center;
  padding: 50px 0px;
  background-color: #fafafa;
  align-items: center;
  min-height: 100vh;
}

.succ-12 p {
  color: #999;
}
.succ-12 h4 {
  font-size: 29px;
  margin-bottom: 5px;
  font-family: "AvenirHeavy";
  font-weight: inherit;
}
.succ-12 > a {
  font-size: 24px;
  color: #00bbff;
  font-family: "AvenirHeavy";
}
.succe-233 .header {
  display: none;
}

.success .card {
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
  border: none;
}

p.cun-2.card-text h4 {
  margin: 0;
}

p.cun-2.card-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.success input[type="checkbox"] {
  margin-right: 10px;
}

.succ-12 img {
  width: 100px;
  object-fit: cover;
  margin-bottom: 40px;
}

.succ-12 {
  padding: 80px 20px;
  background-color: #fff;
  width: 810px;
  border-radius: 25px;
  font-family: "AvenirHeavy";
}

.service-amount {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #000;
}

.service-amount span:nth-child(2n) {
  text-decoration: line-through;
  color: #999;
}

/* step wizard css start */
.stepwizard {
  /* margin-bottom: 30px; */
  padding: 55px 40px 45px;
}

.stepwizard ol.stepwizard-panel {
  background-color: transparent;
  position: relative;
  overflow: hidden;
  counter-reset: wizard;
  width: 100%;
  display: flex;
  justify-content: center;
}

li.stepwizard-step {
  position: relative;
  float: left;
  width: 20%;
  text-align: center;
  color: #000;
}

.current ~ li.stepwizard-step {
  color: #555;
}

li.stepwizard-step:before {
  counter-increment: wizard;
  content: counter(wizard);
  display: block;
  color: #fff;
  background-color: #007bff;
  border: 2px solid #007bff;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  position: relative;
  left: 50%;
  margin-bottom: 15px;
  margin-left: -15px;
  z-index: 1;
  cursor: pointer;
}

.current ~ li.stepwizard-step:before {
  background-color: #d6d9da;
  color: #333;
  border-color: #d6d9da;
}

li.stepwizard-step + li.stepwizard-step:after {
  content: "";
  display: block;
  width: 100%;
  background-color: #007bff;
  height: 2px;
  position: absolute;
  left: -50%;
  top: 1em;
  z-index: 0;
}

li.current.stepwizard-step ~ li.stepwizard-step:after {
  background-color: #d6d9da;
}

/* step wizard css End */

.new-notes {
  padding-top: 0px !important;
}

.single-store .select-service-tab {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 15px 0;
}

.single-store .store-service-tab .select-service-tab .nav-tabs .nav-link {
  color: #000;
  background: #40c1ff;
}

.single-store .store-service-tab .select-service-tab .nav-tabs .nav-link:hover,
.single-store .store-service-tab .select-service-tab .nav-tabs .nav-link.active,
.single-store
  .store-service-tab
  .select-service-tab
  .nav-tabs
  .nav-link.active:hover,
.single-store
  .store-service-tab
  .select-service-tab
  .nav-tabs
  .nav-link.active:focus {
  border: none !important;
  background: #40c1ff !important;
  color: #fff !important;
}

.single-store
  .store-service-tab
  .select-service-tab
  .nav-tabs
  .nav-link.active {
  background: #40c1ff !important;
  border-radius: 0;
}

.single-store
  .store-service-tab
  .select-service-tab
  .nav-tabs
  .nav-link.active::after {
  content: none !important;
}

.single-store .store-service-tab .select-service-tab .nav-tabs .nav-link {
  font-size: 19px;
  position: relative;
  font-family: "AvenirBook";
  background: #b3e6ff;
  text-transform: capitalize;
}

.single-store
  .store-service-tab
  .service-selection
  .service-list-view:nth-last-child(2) {
  border-bottom: 0;
}

.single-store .service-list-view {
  border-bottom: 0;
}

.single-store .store-service-tab .service-info span {
  color: #40c1ff;
  font-family: "AvenirMedium";
  font-size: 19px;
}

.single-store .service-selection {
  border-bottom: 0;
}

.single-store .comments ul li {
  border: 0;
  border-radius: 0;
}

.single-store .comments ul li:not(:last-child) {
  position: relative;
}

.single-store .comments ul li:not(:last-child):before {
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #71d1ff;
}

.single-store .utf_by_comment span.date {
  color: #000;
  font-family: "AvenirLight";
}

.remove-apps .footer-upper-content {
  display: none !important;
}
.remove-apps .footer {
  box-shadow: 0px 0px 10px -5px #58bbd4;
  margin-top: 0;
}
.service-bg.new-select-service {
  background-color: #e5f7ff;
}
.service-bg.new-select-service .page-title {
  font-size: 40px;
  font-family: "AvenirBlack";
  padding-bottom: 32px;
}
.service-bg.new-select-service .page-title > p {
  margin-bottom: 0;
  color: #000;
}
.service-bg.new-select-service .page-title > p > span {
  color: #00adff;
  display: inline-block;
}
.new-select-service .service-card {
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
  height: 100%;
  margin-bottom: 0;
}
.new-select-service .box-details {
  border-radius: 25px;
}
.service-bg.new-select-service .box-details .card {
  border: none;
  border-radius: 10px;
}
.new-select-service .card img {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  height: 307px;
}
.service-bg.new-select-service .box-details .card-title {
  font-size: 24px;
  color: #000;
  padding-top: 20px;
  padding: 40px 30px 0;
  font-family: "AvenirBlack";
  margin-bottom: 5px;
  font-weight: inherit;
}
.service-bg.new-select-service .box-details .card-text {
  color: #000;
  font-size: 20px;
  font-family: "AvenirRoman";
  padding: 0 30px 20px;
  font-weight: unset;
}
.new-select-service .left-scrollbar {
  max-height: 205px;
  height: auto;
  padding: 0;
  padding-top: 10px;
}
.service-bg.new-select-service .content-detail {
  /* padding: 20px 0; */
  padding: 15px 30px;
  align-items: flex-start;
}
.new-select-service .border-des {
  border-bottom: 0;
  border-top: 1px solid #00adff;
  /* padding: 0px 30px; */
}
.new-select-service .total-rate {
  position: relative;
}
.new-select-service .total-rate:after {
  content: "";
  width: 89%;
  height: 1px;
  background: #00adff;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  position: absolute;
}
.new-select-service .content-detail h6 {
  font-size: 20px;
  color: #000;
  font-family: "AvenirHeavy";
  font-weight: inherit;
}
.new-select-service .content-detail span {
  font-size: 20px;
  color: #0098ff;
  font-weight: unset;
  font-family: "AvenirHeavy";
}
.new-select-service .total-rate h6 {
  font-size: 24px;
  color: #000;
  font-family: "AvenirBlack";
  font-weight: unset;
}
.new-select-service .total-rate h6:nth-child(2) {
  color: #00adff;
}
.new-select-service .book-now {
  padding: 20px 30px 25px;
}
.new-select-service .total-rate {
  padding: 20px 30px;
}
.new-select-service .book-now .btn {
  padding: 15px 20px;
  min-width: 245px;
  background: rgb(0, 201, 255);
  background: linear-gradient(
    to bottom,
    rgba(0, 201, 255, 1) 0%,
    rgba(96, 125, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00c9ff', endColorstr='#607dff', GradientType=0);
  border: 0;
  font-size: 22px;
  border-radius: 10px;
  font-family: "AvenirBlack";
  font-weight: unset;
}
.new-select-service .select-serve .store-service-tab {
  /* box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%); */
  height: 100%;
}
.service-heading {
  font-size: 24px;
  padding: 10px 40px 0;
  font-family: "AvenirBlack";
}
.new-select-service .store-service-tab .select-service-tab nav.nav.nav-tabs {
  background: #e3f6ff;
}
.new-select-service
  .store-service-tab
  .select-service-tab
  .nav-tabs
  .nav-link.active {
  background: #40c1ff !important;
  color: #fff !important;
}
.new-select-service
  .store-service-tab
  .select-service-tab
  .nav-tabs
  .nav-item.nav-link {
  border-bottom: 1px solid #e3f6ff !important;
}
.new-select-service
  .store-service-tab
  .select-service-tab
  .nav-tabs
  .nav-link.active::after {
  content: none;
}
.new-select-service .store-service-tab .select-service-tab .nav-tabs .nav-link {
  font-family: "AvenirBook";
  font-size: 20px;
  margin: 6px 0;
}
.new-select-service
  .store-service-tab
  .select-service-tab
  .nav-tabs
  .nav-link:first-child {
  margin-top: 0;
}
.new-select-service
  .store-service-tab
  .select-service-tab
  .nav-tabs
  .nav-link:last-child {
  margin-bottom: 0;
}
.new-select-service .noservice-found {
  font-size: 16px;
  font-family: "AvenirMedium";
}
.new-select-service .select-service-name h4 {
  font-size: 20px;
  font-family: "AvenirHeavy";
  font-weight: unset;
}
.new-select-service .select-service-name span {
  font-size: 20px;
  font-weight: unset;
  color: #0098ff;
  font-family: "AvenirHeavy";
}
/* .new-select-service .service-cost h5 {
  font-size: 20px;
  font-family: "AvenirHeavy";
  color: #00adff;
} */
.new-select-service .select-service-list {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
  /* border-bottom: 1px solid #efefef; */
  border-bottom: none;
  align-items: flex-start;
}
.new-select-service .select-service-list .select-service-name {
  padding: 0 20px;
}
.new-select-service .new-service-cost {
  font-size: 20px;
  font-family: "AvenirHeavy";
  color: #0098ff;
  font-weight: unset;
}
.stepwizard .progress {
  height: 10px;
  border-radius: 15px;
  box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
}
.stepwizard .progress .progress-bar {
  background: #00adff;
  border-radius: 10px;
}
.stepwizard span.stepwizard-steps {
  padding-top: 25px;
  display: inline-block;
  font-size: 24px;
  font-family: "AvenirBlack";
  color: #0098ff;
  font-weight: inherit;
}
.service-bg .no-service h4 {
  font-size: 20px;
  text-align: center;
  margin: 20px 0;
  color: #000;
  font-family: "AvenirHeavy";
  font-weight: inherit;
}
.new-select-service .select-staff-pic img {
  border: 3px solid #00adff;
}
.new-select-service .select-staff-name h4 {
  font-size: 25px;
  font-family: "AvenirMedium";
  color: #000;
}
.new-select-service .select-staff-name span {
  color: #00adff;
  font-size: 20px;
  font-family: "AvenirHeavy";
  text-transform: capitalize;
}
.new-select-service .select-staff-table a {
  padding: 0;
  border-bottom: none;
}
.new-select-service .associate-details,
.new-select-service .time-details {
  height: 100%;
}
.new-select-service .select-time .date-calendar .calendar-grid {
  border: 1px solid #00adff;
  /* background: #00adff;
  border-color: #00adff; */
}
.service-bg .date-calendar .calendar-grid * {
  color: #000;
}
.service-bg.new-select-service .date-calendar .calendar-grid h6 {
  font-family: "AvenirHeavy";
  font-size: 20px;
  font-weight: inherit;
}
.service-bg.new-select-service .date-calendar span {
  font-family: "AvenirHeavy";
  font-size: 20px;
  font-weight: inherit;
}
.new-select-service .select-time .date-calendar {
  margin: 0px auto 130px;
}
/* .new-select-service .select-time .time-scrollbar {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
} */

/* .new-select-service .select-time .time-scrollbar::-webkit-scrollbar,
.new-select-service .left-scrollbar::-webkit-scrollbar {
  display: none;
} */

.service-bg.new-select-service .chek-3 {
  border-bottom: 1px solid #00adff;
  padding: 13px 0;
  margin: 0 20px;
}
.new-select-service .select-time .time-scrollbar a:last-child .chek-3 {
  border-bottom: 0;
}
.new-select-service .select-time .heading-month h4 {
  color: #000;
  font-family: "AvenirHeavy";
  font-size: 25px;
  font-weight: unset;
}
.new-select-service .paymentdetail .form-control {
  border-radius: 10px;
  color: #000;
  font-family: "AvenirMedium";
  font-size: 20px !important;
}
.new-select-service .paymentdetail .form-control:focus {
  /* color: #000; */
  border-color: #00adff !important;
}
.new-select-service .paymentdetail .form-control::placeholder {
  color: #6c6c6c !important;
  font-family: "AvenirMedium";
}
.new-select-service .paymentdetail-content .form-label {
  font-size: 20px;
  color: #000;
  font-weight: inherit;
  font-family: "AvenirHeavy";
  margin-bottom: 0.5rem;
}
.new-select-service .paymentdetail .sub-heading h5 {
  color: #000;
  margin-bottom: 0;
  font-weight: inherit;
  font-family: "AvenirHeavy";
  font-size: 24px;
}
.new-select-service .paymentdetail .sub-heading p {
  color: #000;
  font-family: "AvenirMedium";
  font-size: 20px;
  font-weight: inherit;
}
.new-select-service .paymentdetail .booking-notes p {
  color: #000;
  font-family: "AvenirMedium";
  font-size: 20px;
}
.new-select-service .paymentdetail .booking-notes {
  margin-top: 0;
}
.new-select-service .StripeElement {
  border-radius: 10px !important;
  border: 1px solid #dadada !important;
  color: #000 !important;
  height: 47px !important;
  padding: 14px 12px !important;
}
.new-select-service .StripeElement::placeholder {
  color: #000 !important;
}
.new-select-service .StripeElement--focus {
  box-shadow: none !important;
  border-color: #00adff !important;
  /* color: #000 !important; */
}
.service-bg.new-select-service .no-service.date-time h4 {
  background-image: linear-gradient(to right, #e5ddff, #c8f1ff);
  padding: 15px;
  border-radius: 10px;
  margin: -5px 0 20px;
  width: 100%;
}
.service-bg.new-select-service .no-service.date-time {
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 30px;
}
.new-select-service .form-label {
  font-size: 24px;
  color: #000;
  margin-bottom: 0.5rem;
  font-family: "AvenirHeavy";
  font-weight: inherit;
}
/* .new-select-service .paymentdetail textarea.form-control {
  height: 200px !important;
} */
.service-bg.new-select-service .time-details .service-card {
  padding: 0;
}
.new-select-service .select-staff-table {
  margin-bottom: 20px;
}
.service-bg.new-select-service .chek-3 {
  margin: 0 40px;
}
/* .new-select-service .select-service-list:first-child {
  padding-top: 0;
} */
/* 
.new-select-service .box-details {
  height: 100%;
}
.service-bg.new-select-service .box-details .card {
  height: 100%;
}
.service-bg .box-details .card-body {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.new-select-service .total-rate {
  height: 100%;
}
.new-select-service .book-now {
  margin-top: auto;
} */
/* .new-select-service .book-now {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.new-select-service .box-details {
  height: 100%;
  position: relative;
} */

.new-select-service .box-details {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.service-bg.new-select-service .box-details .card {
  height: 100%;
}
.new-select-service .book-now {
  margin-top: auto;
}
