/* @import 'https://maxst.icons8.com/vue-static/landings/line-awesome/font-awesome-line-awesome/css/all.min.css'; */
@import "https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap";

.super-admin-view .header,
.super-admin-view .footer {
  display: none;
}

.super-admin-wrapper {
  overflow-x: hidden;
  /* font-family: "Nunito", sans-serif !important; */
  font-family: "AvenirBook" !important;
}

.super-admin-view .main_content {
  padding: 0;
}

.admin-header-view {
  text-align: center;
  position: relative;
  z-index: 99;
}

.header-top-box h1 {
  font-size: 30px;
  padding: 5px 0px;
}

.super-admin-content-view {
  display: flex;
}

.super-admin-menu {
  min-height: 100vh;
  border-right: 1px solid #1e1e1f;
  width: 250px;
  background: #1e1e1f;
  transition: all 0.5s;
}

.super-admin-content {
  padding: 30px 20px;
}

.super-admin-container {
  width: calc(100% - 250px);
  background: #f6f7fb;
  transition: all 0.5s;
}

.super-admin-container.admin-wrapper-expand {
  width: 100%;
}

.admin-menu-list ul {
  margin: 0;
  padding: 0;
}

.admin-menu-list ul a {
  padding: 18px 25px;
  display: block;
  color: #fff;
  text-decoration: none;
  transition: all 0.5s;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
}

.super-admin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  background: #fff;
  min-height: 60px;
  box-shadow: 0px 0px 15px #33333312;
}
.super-admin-menu.menu-hide {
  width: 0;
  opacity: 0;
}
.admin-menu-logo {
  width: 250px;
  text-align: center;
  background: #1e1e1f;
  padding: 11px 10px;
  border-bottom: 1px solid #2b2b2d;
}

.admin-menu-logo h2 {
  font-weight: 600;
  font-size: 25px;
  color: #fff;
  margin: 0;
}

.super-admin-info img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50px;
  margin-right: 0px;
}

.super-admin-info {
  display: flex;
  align-items: center;
}

.admin-menu-logo img {
  max-width: 150px;
  width: 100%;
}

.menu-toggle img {
  width: 32px;
  opacity: 0.9;
  transition: all 0.5s;
  cursor: pointer;
}

.menu-toggle img:hover {
  opacity: 1;
}

.admin-menu-list {
  padding-top: 40px;
}

.admin-menu-list ul a:hover {
  color: #fff;
  background: #1e1e1f;
}

.admin-menu-list ul a.active {
  color: #fff;
}

.super-admin-info h3 {
  margin: 0;
  font-size: 14px;
  color: #212529;
  font-weight: 600;
}

.admin-menu-list ul a i {
  margin-right: 10px;
  font-size: 16px;
  vertical-align: middle;
}

.admin-dashboard .admin-card {
  margin-bottom: 15px;
  margin-top: 15px;
  border-color: aliceblue;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  background: #fff;
}

.admin-dashboard .h4 {
  color: #000;
  /* color: #fff; */
}

.admin-dashboard .text-muted {
  color: #000 !important;
  /* color: #fffb !important; */
}

.menu-toggle a {
  color: #555;
  font-size: 20px;
}

.super-admin-title {
  margin: 0;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: #555;
}
/* .admin {
  background: #fff;
  /* margin-bottom: 15px;
  margin-top: 15px; *
  border-color: aliceblue;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
} */

.super-admin-title a {
  font-size: 14px;
  font-weight: 500;
  color: #a5a5a5;
}

.super-admin-title a:hover {
  text-decoration: none;
  color: #262f3e;
}

.font-weight-600 {
  font-weight: 600;
}
/* 
.admin-dashboard .fa {
  font-size: 40px;
  opacity: 0.2;
} */

.admin-dashboard .card-body {
  cursor: pointer;
  padding: 30px 20px;
}

.admin-dashboard .card-body .fa {
  transition: all 0.5s;
}

.admin-dashboard .card-body:hover .fa {
  opacity: 1;
  color: #262f3e !important;
}

.user-list .table-responsive > .table-bordered .btn {
  background: #373373;
  border: none;
  padding: 5px 20px;
  font-size: 14px;
  min-width: 90px;
}

.user-list .table td,
.table th {
  padding: 15px 20px;
  vertical-align: middle;
}

.user-list .table td p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  margin: 0;
}

.user-list .table-responsive > .table-bordered {
  border: 0;
  background: #fff;
}

.user-list .table td {
  font-size: 14px;
}

.user-list .table td a {
  color: #478adc;
}

.action-buttons .btn {
  margin: 0px 5px;
}

.block-btn {
  background: #f1454f !important;
}

.approve-btn {
  background: #37a864 !important;
}

.edit-btn {
  background: #6d6d6d !important;
}

.super-admin-search {
  text-align: right;
  padding-bottom: 25px;
}

.super-admin-search .form-inline {
  justify-content: flex-end;
}

.super-admin-search .btn {
  background: #555555;
  border: none;
  padding: 7px 15px;
  font-size: 14px;
}

.super-admin-search .form-control {
  font-size: 13px;
  padding: 8px 10px;
  height: auto;
}

.super-admin-login {
  background: #f9f9f9;
  padding: 100px 0px;
  min-height: 100vh;
}

.super-admin-login-box {
  padding: 50px 40px;
  max-width: 500px;
  margin: 0px auto;
  background: #fff;
}

.super-admin-login-box .form-group .form-control {
  height: auto;
  padding: 10px 20px;
  background: transparent;
  border: 1px solid #dadada;
  border-radius: 4px;
  font-size: 14px;
}

.super-admin-login-box .form-group .form-control:focus {
  box-shadow: none;
  background: transparent;
}

.super-admin-login-box input:-internal-autofill-selected {
  background: transparent !important;
}

.super-admin-login-box h2 {
  font-size: 35px;
  text-align: center;
  font-weight: 600;
  color: #333;
  padding-bottom: 20px;
}

.super-admin-detail {
  display: flex;
  align-items: flex-start;
  padding: 20px 15px;
}

.super-admin-pic img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 3px solid #fff;
}

.super-admin-name span {
  font-size: 12px;
  color: #d0cdcd;
}

.super-admin-name h4 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-top: 5px;
}

.super-admin-name {
  padding-left: 20px;
  padding-top: 5px;
}

.admin-menu-list ul h5 {
  font-size: 12px;
  text-transform: uppercase;
  padding: 0px 25px;
  margin-top: 20px;
  color: #fff;
  margin-bottom: 10px;
}

ul.sub-menu {
  background: #5f5f5f;
  display: none;
}

ul.sub-menu li a {
  font-size: 13px;
  padding-left: 50px;
}

ul.sub-menu li a i {
  margin-right: 2px;
}

.admin-menu-list ul li {
  position: relative;
}

.admin-menu-list ul > li.has-menu > a:after {
  content: "\f107";
  font-family: FontAwesome;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: all 0.5s;
}

ul.sub-menu.menu-open {
  display: block;
}

.admin-menu-list ul > li.has-menu.menu-expand > a:after {
  transform: rotate(180deg);
}

.super-admin-info .dropdown-toggle {
  background: transparent;
  font-size: 12px;
  color: #333;
  border: none;
}

.super-admin-info .dropdown-toggle:hover,
.super-admin-info .btn-primary:not(:disabled):not(.disabled):active:focus,
.super-admin-info .dropdown-toggle:focus,
.super-admin-info .show > .btn-primary.dropdown-toggle:focus {
  background: transparent;
  font-size: 12px;
  color: #333;
  border: none;
  box-shadow: none;
}

.super-admin-info .btn-primary:not(:disabled):not(.disabled):active {
  background-color: transparent;
  border-color: transparent;
  color: #333;
}

.super-admin-info .show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
  color: #333;
}

.super-admin-info .dropdown-item {
  font-size: 13px;
  padding: 8px 20px;
  font-weight: 600;
  color: #999;
}

.super-admin-info .dropdown-menu {
  min-width: 6rem;
  left: -5px !important;
}

.super-admin-search .form-label {
  padding-right: 20px;
}

.super-admin-search select.form-control {
  max-width: 150px;
  width: 100%;
}

.add-distributor {
  padding: 50px;
  background: #fff;
  border: 1px solid #ddd;
  margin-bottom: 50px;
}

.add-distributor .form-group {
  margin-bottom: 20px;
}

.add-distributor .super-admin-title {
  padding-bottom: 30px;
}

.distributor-button .d-btn {
  background: #37a864;
  border: none;
  padding: 10px 30px;
  font-size: 15px;
}

.distributor-form label {
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
}

.user-list .tab-content {
  padding: 30px;
  background: #fff;
  border: 1px solid #dee2e6;
  margin-top: -1px;
  padding-top: 50px;
}

.user-list .nav-tabs .nav-link {
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  width: 50%;
  text-align: center;
}

.user-list .nav-tabs .nav-link:focus,
.user-list .nav-tabs .nav-link:hover {
  outline: none;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.category-popup {
  /* font-family: "Nunito", sans-serif !important; */
  font-family: "AvenirBook" !important;
}

.super-admin-content-view .form-control,
.super-admin-content-view .form-control::placeholder {
  color: #000;
}

.service-available {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.service-available label {
  width: 100%;
}

.service-available .form-check {
  margin-right: 20px;
  color: #000;
}

/************ Services List ****************/

.partner-services-content {
  padding: 20px 0px;
}

.header-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-buttons .btn {
  margin-left: 10px;
  border: 1px solid transparent;
  min-width: 100px;
  font-weight: 500;
  font-size: 22px;
  background: #9551fd;
  padding: 5px 12px;
}

.header-buttons .btn:hover {
  border: 1px solid transparent;
  background: #9551fd;
}

.header-buttons .dropdown-toggle::after {
  display: none;
}

.light-btn .btn {
  background: #ffffff;
  color: #333;
  box-shadow: 0 3px 5px 0 rgba(164, 173, 186, 0.25);
}

.header-buttons .dropdown-item {
  font-size: 14px;
  font-weight: 500;
  color: #555;
}

.header-buttons .dropdown a {
  display: block;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  color: #555;
}

.dropdown a:hover {
  text-decoration: none;
}

.dark-btn .btn-primary:not(:disabled):not(.disabled).active,
.dark-btn .btn-primary:not(:disabled):not(.disabled):active,
.dark-btn.show > .btn-primary.dropdown-toggle {
  background: #8147dd !important;
  color: #fff !important;
  border: 1px solid #8147dd !important;
}

.dark-btn .btn-primary:not(:disabled):not(.disabled).active:focus,
.dark-btn .btn-primary:not(:disabled):not(.disabled):active:focus,
.dark-btn.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.light-btn .btn-primary:not(:disabled):not(.disabled).active:focus,
.light-btn .btn-primary:not(:disabled):not(.disabled):active:focus,
.light-btn.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  background: #ffffff;
  color: #333;
  box-shadow: 0 3px 5px 0 rgba(164, 173, 186, 0.25);
}

.service-list {
  position: relative;
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
}
.service-list h4 {
  font-size: 20px;
  font-weight: inherit;
  /* margin: 0; */
  padding-bottom: 0;
  text-transform: uppercase;
  color: #9551fd;
  letter-spacing: 0.5px;
  font-family: "AvenirBlack" !important;
}

.service-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
}

.service-list-header [class*="service-"] {
  padding: 10px;
  text-align: left;
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.service-list-header .service-discount {
  text-decoration: inherit;
}

.service-list-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
  padding: 15px 10px;
  border: 1px solid #eaeaea;
  margin-top: -1px;
  cursor: pointer;
}

.service-list-items [class*="service-"] {
  padding: 10px;
  font-weight: 500;
  text-align: left;
}

.service-name {
  font-weight: 600 !important;
  font-size: 15px;
  width: 31%;
  text-align: left !important;
}

.service-name span {
  display: block;
  font-size: 12px;
  color: #999;
  font-weight: 500 !important;
}

.service-discount {
  text-decoration: line-through;
  color: #999;
  width: 10%;
}

.service-hour {
  width: 15%;
}

.service-price {
  font-size: 16px;
  font-weight: 700 !important;
  width: 10%;
}

.service-extra {
  width: 17%;
}

.add-category textarea {
  height: 120px !important;
}

.category-popup .modal-header .close {
  position: absolute;
  right: 15px;
  top: 13px;
  font-size: 35px;
  font-weight: 300;
  padding: 15px;
}

.category-popup .modal-title {
  font-weight: inherit;
  font-size: 30px;
  color: #8147dd;
  font-family: "AvenirBlack";
}

.category-popup .modal-header {
  justify-content: center;
}

.category-popup .modal-body {
  padding: 30px;
}

.category-popup .modal-header .close:focus {
  outline: none;
  box-shadow: none;
}

.add-category .l-btn.btn.btn-primary {
  background: #262f3e;
  padding: 10px 40px;
  font-size: 15px;
}

.add-category .btn-primary:not(:disabled):not(.disabled):active {
  background: #262f3e !important;
  color: #fff !important;
}

.service-list-items-multiple-service .service-list-items {
  border: none;
  padding: 0px 0px;
}

.service-list-items-multiple-service {
  position: relative;
  background: #fff;
  cursor: pointer;
  border: none;
  padding: 10px 0px;
  margin: 0;
  border-top: 1px solid #f7f7f7;
}

.service-list-items-multiple-service h4 {
  font-weight: 600 !important;
  font-size: 15px;
  width: 55%;
  text-align: left !important;
  padding: 10px;
}

.action-icon-img .btn {
  background: transparent;
  border: none;
  color: #777;
  font-size: 20px;
}

.action-icon-img .btn::after {
  display: none;
}

.action-icon-img {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.action-icon-img .btn:hover,
.action-icon-img .btn:focus {
  background: transparent;
  color: #777;
  border: none;
  box-shadow: none;
  outline: none;
}

.no-result {
  background: #fff;
  padding: 50px;
  text-align: center;
  box-shadow: 0px 0px 20px #0000000d;
  border-radius: 5px;
}

.no-result h4 {
  margin: 0;
}
/* 
.header-buttons .btn:before {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 4px;
} */

/************ Add Services ****************/

.partner-add-services-content {
  padding: 20px 0px;
  background: #fff;
  box-shadow: 0px 0px 10px #00000014;
  border-radius: 25px;
}

.add-service-box {
  background: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 50px;
  cursor: pointer;
}

.add-service-box.bdr-right {
  border-right: 1px dashed #d1d3d8;
}

.add-service-box img {
  width: 30px;
  /* opacity: 0.5; */
}

.add-service-box h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 5px;
  padding-top: 10px;
  color: #000;
}

.add-service-box p {
  color: #777;
  max-width: 250px;
  margin: 0px auto;
}

.add-service-box a {
  color: #333;
  display: block;
}

.add-service-box a:hover {
  text-decoration: none;
  color: #333;
}

/* .single-services .super-admin-title,
.package-services .super-admin-title,
.add-services .super-admin-title {
  justify-content: center;
} */

.single-services .super-admin-title a,
.package-services .super-admin-title a,
.add-services .super-admin-title a {
  position: absolute;
  right: 0;
}

/************** Single Service  ****************/
.single-services .error {
  position: absolute;
}

.single-services .staff-list {
  justify-content: flex-start;
}

.single-services .staff-item {
  margin: 0.6%;
}

.service-card {
  background: #fff;
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgba(16, 25, 40, 0.1);
  position: relative;
  margin-bottom: 20px;
}

.service-header {
  padding: 25px 25px;
  padding-bottom: 10px;
}

.service-body {
  padding: 15px 25px;
}

.service-header h2 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #9552fe;
  letter-spacing: 0.5px;
}

.service-header p {
  margin: 0;
  color: #000;
}

.treatment-info {
  margin: 0;
  color: #000;
  font-size: 12px;
  padding-top: 8px;
}

.single-services-content textarea {
  height: 120px !important;
}

.package-services-content textarea {
  height: 100px !important;
}

.online-booking {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  color: #000;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 26px;
  margin: 0;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(19px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.sales-setting h5 {
  padding-top: 10px;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.5px;
}

.sales-setting {
  padding-bottom: 10px;
}

.sales-setting p {
  color: #000;
}

.staff-item {
  display: flex;
  align-items: center;
  padding: 15px 15px;
  width: 32%;
  font-weight: 600;
  color: #333;
  flex-wrap: wrap;
  position: relative;
  border: 1px dashed #ddd;
  border-radius: 5px;
  text-align: center;
  flex-flow: column;
  font-size: 16px;
  margin-bottom: 5px;
}

.staff-item img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 0px 10px;
  margin-bottom: 10px;
}

.staff-item .form-check {
  position: absolute;
  right: 6px;
  top: 6px;
}

.staff-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.select-all-staff {
  padding-bottom: 12px;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.staff-copmmision {
  padding-top: 20px;
}

.staff-copmmision h5 {
  padding-top: 10px;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.5px;
}

.staff-copmmision p {
  color: #000;
}

.extra-time h5 {
  font-weight: 600;
  font-size: 17px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.pricing-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pricing-input .form-group {
  width: 49%;
}

.pricing-input .form-group.full {
  width: 100%;
}

.pricing-option {
  background: #ffffff;
  padding: 0;
  border-radius: 8px;
  margin: 0;
  position: relative;
}

.remove-pricing {
  position: absolute;
  right: 10px;
  color: #fb3333;
  font-size: 20px;
  cursor: pointer;
}

.pricing-option h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.5px;
}

.add-pricing-option span {
  cursor: pointer;
  font-weight: 600;
  color: #2577ff;
  font-size: 15px;
  background: #ffffff;
  padding: 10px 0px;
  border-radius: 4px;
}

.single-services-content .online-booking {
  position: absolute;
  right: 20px;
  bottom: 30px;
}

.single-services-content .out-service {
  position: relative;
}

.single-services-content .voucher-sales {
  position: relative;
}

.single-services-content .voucher-sales .online-booking {
  top: 10px;
  right: 0;
}

/* .single-services-content .out-service .online-booking  {
  top: 10px;
  right: 20px;
} */
.single-services-content .out-service .online-booking {
  top: 30px;
  right: 20px;
}

.add-pricing-option {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-button.service-save {
  padding: 20px 0px;
  display: flex;
  justify-content: flex-end;
}

.service-save .l-btn.btn.btn-primary {
  background: #262f3e;
}

.service-save .l-btn.btn.btn-default {
  border: 1px solid #ddd !important;
  color: #999;
  margin-right: 10px;
  padding: 12px 40px;
}

.service-save .btn {
  max-width: 150px;
  margin-right: 10px;
}

.service-category-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f1f1f1;
  padding: 12px 15px;
  border-radius: 5px;
  flex-wrap: wrap;
}

.service-category-list-items ul {
  margin: 0;
}

.service-category-list-items ul li {
  font-weight: 500;
  font-size: 16px;
}

.category-edit span {
  color: #018bcf;
  font-weight: 600;
  cursor: pointer;
}

.service-category-list-items {
  width: 80%;
}

.category-edit {
  width: 20%;
  text-align: right;
}

.select-categories .select-list {
  padding: 15px 0px;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  position: relative;
}

.select-categories .select-list h5 {
  margin: 0;
  font-size: 18px;
}

.select-categories .select-list p {
  margin: 0;
  font-size: 12px;
  color: #999;
}

.select-categories .select-list:last-child {
  border: none;
}

.select-categories + .login-button {
  margin-top: 20px;
}

.add-service-price i {
  margin-left: 10px;
  color: #f34949;
}

.extra-option h5 {
  padding-top: 15px;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 15px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.5px;
}

.select-services .select-categories {
  padding-bottom: 20px;
}

.select-service-popup .modal-dialog {
  max-width: 800px;
}

.accordion .btn {
  width: 100%;
  text-align: left;
  color: #333;
  text-decoration: none;
  padding: 10px 0px;
  font-size: 20px;
  font-weight: 500;
}

.accordion .btn:focus {
  box-shadow: none;
}

.accordion .card-header {
  background: #fff;
  border: none;
  padding: 0;
}

.accordion .card {
  border: none;
  border-bottom: 1px solid #ddd !important;
}

.accordion .btn:hover,
.accordion .btn:focus {
  color: #333;
  text-decoration: none;
}

.accordion .btn i {
  position: absolute;
  right: 0;
  top: 15px;
}

.accordion .card:last-child {
  border-bottom: none !important;
}

.accordion .card-body {
  padding: 0px 20px;
}

.add-service-price {
  position: absolute;
  right: 10px;
  top: 20px;
  font-weight: 600;
  font-size: 18px;
}

.service-pricing .input-group-prepend {
  padding: 0px 10px;
  border: 1px solid #ced4da;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

button.l-btn.delete-btn.btn.btn-primary {
  background-color: #ea3333 !important;
}

/* .pricing-input .input-group-prepend {
  margin-left: 10px;
} */
.pricing-input .input-group-prepend {
  padding: 0px 10px;
  border: 1px solid #ced4da;
  border-left: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #eee;
  font-family: "AvenirHeavy";
}

.partner-header h2 {
  font-size: 16px;
  text-transform: uppercase;
  color: #000;
  margin: 0;
}

.partner-header {
  padding-bottom: 25px;
}

.partner-table table thead th {
  color: #000;
  font-weight: 700;
  border: none;
  padding: 15px 10px;
  /* border-top: 1px solid #f1f1f1; */
  letter-spacing: 0.2px;
}

.partner-table table tbody td {
  color: #000;
  padding: 15px 10px;
  font-size: 15px;
  vertical-align: middle;
  border-top: 1px solid #f1f1f1;
}

.partner-table .login-button.service-save {
  padding: 20px 0px;
  display: flex;
  justify-content: unset;
}

.package-services .l-btn.btn.btn-primary {
  width: auto !important;
}

.package-services .delete-btn {
  background-color: #ea3333 !important;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.3;
  background: #ccc;
}

.partner-table table .disabletable tr:first-child {
  opacity: 0.3;
  background: #ccc;
  cursor: not-allowed;
}

.disable-block {
  opacity: 0.5;
  cursor: not-allowed;
}

.disable-block input {
  pointer-events: none;
}

.disabled input {
  pointer-events: none;
}

.pdd-bt-89 {
  padding-bottom: 89px;
}

.advance-pricing-popup {
  /* font-family: "Nunito", sans-serif !important; */
  font-family: "AvenirBook" !important;
}

.advance-pricing-box {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  background: #f7f7f7;
  border-radius: 5px;
}

.advance-pricing-box .form-group {
  width: 24%;
}

.special-price-associate {
  padding: 20px 0px;
}

.associate-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 10px;
}

.associate-user img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.associate-user {
  display: flex;
  align-items: center;
  width: 40%;
}

.associate-user p {
  margin: 0;
  padding-left: 15px;
  font-weight: 600;
  color: #000;
}

.price-associate {
  width: 25%;
  padding-right: 10px;
}

.duratiion-associate {
  width: 25%;
}

.special-price-associate h4 {
  font-weight: 600;
  color: #555;
  font-size: 20px;
}

.advance-pricing-popup .modal-title {
  font-weight: 700;
  color: #555;
}

.associate-pricing-buttons {
  text-align: right;
}

.associate-pricing-buttons .btn.btn-default {
  border: 1px solid #ddd;
  color: #999;
  margin-right: 10px;
}

.associate-pricing-buttons .btn.btn-primary {
  background: #262f3e;
  border-color: #262f3e;
}

.associate-pricing-buttons .btn.btn-primary:hover {
  border: 1px solid #262f3e !important;
}

.react-tel-input .special-label {
  display: none;
}

.register-form .form-group .react-tel-input .form-control {
  border-radius: 0;
  padding: 12px 40px;
  padding-right: 15px;
  border-right: none;
}

.react-tel-input .flag-dropdown {
  right: 0;
  left: 0;
}

.react-tel-input .selected-flag {
  width: 100%;
}

.react-tel-input .selected-flag .arrow {
  position: absolute;
  right: -70px;
  left: inherit;
}

.react-tel-input .selected-flag:focus .arrow {
  border-left-width: 3px;
  border-right-width: 3px;
  border-top: 4px solid #555;
}

.search-client {
  height: 59vh;
  position: sticky;
  overflow: hidden;
}

.client-img img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  margin-right: 10px;
}

.client-sec {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
}

.clientt p {
  text-decoration: underline;
  cursor: pointer;
  color: #018bcf;
  text-align: center;
  font-size: 15px;
}

.admin-dashboard .col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  text-align: left;
}
/* height: 80px;
  width: 80px;
  background: #fff;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -30px;
  left: 0;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
} */
.admin-dashboard .fa {
  font-size: 40px;
  opacity: 0.5;
  color: #1e1e1f !important;
}

.no-border td {
  border: none !important;
}
/******************************* Media SCreen ******************************/

@media screen and (max-width: 767px) {
  .super-admin-content {
    padding: 30px 10px;
  }

  .super-admin-container {
    width: 100%;
  }

  .super-admin-menu {
    width: 0;
    margin-left: -1px;
  }

  .super-admin-search .form-inline {
    justify-content: center;
  }

  .super-admin-search .btn {
    margin-top: 10px;
  }

  .user-list .table-responsive > .table-bordered .btn {
    min-width: 100px;
    margin-bottom: 5px;
  }

  /* .super-admin-menu.menu-hide {
    width: 250px;
    opacity: 1;
  } */
}

.admin-dashboard .text-muted + span {
  display: block;
  text-align: right;
}

.add-business,
.add-business:hover,
.add-business:focus {
  max-width: 180px;
  color: #ffff;
  background: #1e1e1f;
  border-color: #1e1e1f;
  font-size: 15px;
  border: 1px solid #1e1e1f;
}
.text-right {
  text-align: right !important;
  padding: 20px;
}

.delete-btn.act-balance {
  background-color: #262f3e !important;
  color: white;
  border: 1px solid transparent;
  padding: 7px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-decoration: none;
}
.back-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-btn a h2 {
  font-size: 16px;
  color: #1e1e1f;
}

.partner-table table thead th span {
  display: block;
}
.partner-table .table tbody + tbody {
  border-top: none;
}

.super-admin-content .btn-primary:not(:disabled):not(.disabled).active,
.super-admin-content .btn-primary:not(:disabled):not(.disabled):active,
.super-admin-content .show > .btn-primary.dropdown-toggle {
  background: #1e1e1f !important;
  color: #fff !important;
  border: 1px solid !important;
}

.business-table .delete-btn {
  padding: 8px 25px;
}
.admin-main-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.super-admin-content .admin-title {
  padding-bottom: 10px;
}
.admin-main-heading .admin-title {
  padding-bottom: 0 !important;
}
.partner-header h2 {
  font-size: 18px;
  font-weight: 700;
  color: #1e1e1f;
}
.super-admin-content .partner-table table tbody td:last-child,
.super-admin-content .partner-table table thead th:last-child {
  text-align: center;
}
.business-save {
  display: flex;
  justify-content: flex-end;
}

.add-pricing .modal-body {
  padding: 30px;
}
.partner-table button#dropdown-basic {
  background-color: transparent;
  border: none;
  color: black;
}
.partner-table .dropdown-toggle::after {
  display: none;
}
.partner-table button#dropdown-basic {
  font-size: 22px;
}
.partner-table button#dropdown-basic:hover,
.partner-table button#dropdown-basic:focus {
  outline: none;
  box-shadow: none;
}
.admin-dashboard .admin-card a {
  text-decoration: none;
}
.partner-table2 table tbody td {
  color: #000;
  padding: 15px 10px;
  font-size: 15px;
  vertical-align: middle;
  border-top: 1px solid #f1f1f1;
  text-transform: capitalize;
}
.appointment-admin .partner-table2 tr th,
.appointment-admin .partner-table2 tr td {
  padding: 15px 10px !important;
}
.appointment-admin .partner-table2 tr th {
  border: none;
}

.business-table.table img {
  height: 80px;
  width: 80px;
  border-radius: 5px;
}
.business-table table tbody td:first-child {
  text-align: left !important;
}
.add-bussiness .profile-img {
  max-width: inherit !important;
  margin: inherit;
  padding-left: 0px;
  background-color: transparent;
}
.add-bussiness .profile-img h6 {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}
.add-bussiness .user-pics img {
  border: 1px solid #cdcdcd;
}
.bussiness-update {
  display: flex;
  justify-content: flex-end;
}
.partner-heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
}

.partner-heading button {
  background-color: #262f3e !important;
  border-color: #262f3e !important;
  border: 1px solid #262f3e !important;
  color: white;
  border-radius: 5px;
  padding: 10px;
}
.custom-switch-setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
}
.custom-switch-setting h6 {
  font-size: 22px;
  font-weight: 600;
}
.setting-btn {
  display: flex;
  justify-content: flex-end;
}
.setting-btn button {
  background-color: #262f3e !important;
  border-color: #262f3e !important;
  border: 1px solid #262f3e !important;
  color: white;
  border-radius: 5px;
  padding: 10px;
}

.add-bussiness .profile-img i {
  margin-top: -10px;
  font-size: 20px;
  color: #007bff;
  background-color: white;
  padding: 6px;
  box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
  border-radius: 50%;
  cursor: pointer;
  margin-left: 0px;
  right: -5px;
  left: auto !important;
  position: absolute;
  top: 50% !important;
}

.add-bussiness .profile-img .delete-icon i {
  margin-left: -6px !important;
  top: 0px !important;
  position: absolute;
  color: #f43f3f !important;
  left: -5px !important;
  right: auto;
  top: 50% !important;
}
.add-bussiness .user-pics {
  height: 100px;
  width: 100px;
  background-color: #eaeaead4;
  border-radius: 50%;
}

.Admin-partners l.pagination-chk li {
  padding: 0px 10px;
  border: 1px solid #8a8a8a !important;
  margin: 0px 5px;
}
.Admin-partners ul.pagination-chk a.pagination__link {
  min-width: 105px;
  border: 1px solid #8a8a8a;
  padding: 9px 15px;
  display: inline;
}
.admin-content-view .menu-logo img {
  height: 35px;
  width: 100%;
  object-fit: contain;
}
