.single-services .form-label {
  color: #000 !important;
}
.single-services .form-control,
.admin-content-view .form-control::placeholder {
  color: #000 !important;
}
.single-services .service-header h2 {
  color: #9552fe !important;
}
.single-services .service-header p {
  color: #333333 !important;
}
.single-services .staff-copmmision h5 {
  color: #000000 !important;
}
.single-services .staff-copmmision p {
  color: #333 !important;
}
.single-services .sales-setting h5 {
  color: #000 !important;
}
.single-services .sales-setting p {
  color: #333 !important;
}
.single-services .form-check-label {
  color: #000000 !important;
}
.single-services .pricing-option h5 {
  color: #000000 !important;
}
.service-list-items .service-name span {
  display: block;
  font-size: 15px !important;
  color: #000 !important;
  font-weight: 500 !important;
}
.add-category > p {
  text-align: center;
  font-size: 20px;
}
.single-services-content .online-booking .switch.disable .slider {
  background-color: #e0dede;
  cursor: not-allowed;
}
.single-services-content .service-body > p {
  color: #ff2613;
  display: inline-block;
  margin-bottom: 0;
}
