.red-color {
  color: red;
}

.staff-content {
  padding: 10px 0px;
  background: #fff;
  border-radius: 4px;
}

.staff-page .nav-tabs .nav-link {
  font-size: 15px;
  font-weight: 600;
  width: 25% !important;
  text-align: center;
  padding: 15px 15px;
  color: #000;
}

.staff-page a {
  font-weight: 500;
  color: #000;
  font-size: 17px;
}

.staff-page a:hover {
  color: #3c3b3b;
}

.staff-page .nav-tabs {
  border-bottom: 2px solid #efefef;
}

.staff-page .tab-content {
  border: none;
  margin-top: 0;
  padding: 20px 20px 30px;
}

.staff-content .btn,
.staff-content .btn:hover {
  /* border: none !important; */
}

/********* Close Staff ****************/

.close-date button {
  background-color: #262f3e;
  color: #fff;
  border-color: #262f3e;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.close-date {
  text-align: right;
  padding-bottom: 20px;
}

.close-date button:hover {
  background-color: #8147dd !important;
  border-color: #8147dd !important;
}

.staff-page .nav-tabs .nav-link.active {
  color: #000 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  border-bottom: 2px solid #262f3e !important;
}

/* .staff-page .nav-tabs .nav-link.active:hover,
.staff-page .nav-tabs .nav-link.active:focus {
  border-bottom: 2px solid #262f3e !important;
} */

.staff-page .nav-tabs .nav-link:focus,
.staff-page .nav-tabs .nav-link:hover {
  border-color: transparent !important;
}

.closedatemodal .btn.btn-primary {
  background-color: #8147dd;
}

.closedatemodal .modal-content {
  width: 560px;
}

.closedatemodal .modal-header {
  border-bottom: transparent !important;
}

/*************** User Permission *************/

span.input-group-text {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}

thead th.text-heading {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.user-permission button {
  background-color: #8147dd !important;
  border-color: #8147dd !important;
}

.user-12 p.text-muted {
  margin-bottom: 30px;
}

.user-permission p {
  max-width: 780px;
}

/********* Add Staff ****************/
.add-staff input[type="checkbox"] {
  margin-top: -8px !important;
  margin-right: 10px;
}

.add-staff-content {
  background: #fff;
  padding: 40px 30px;
}

.add-staff-details .form-control {
  height: auto;
  padding: 12px 20px;
  background: transparent;
  border: 1px solid #dadada;
  border-radius: 8px;
}

.add-staff .s-btn.btn.btn-primary {
  width: 20%;
  margin-right: 10px;
  box-shadow: 0px 3px 10px #00000038;
}

.add-staff-content .nav-tabs .nav-link {
  padding: 15px 50px;
}

.add-staff-details textarea#employee_notes {
  height: 137px;
}

.c-tn.btn.btn-primary {
  background-color: #9aa09e;
}

.mt {
  margin-top: 20px;
}

.add-staff-content .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.close-date a {
  background-color: #262f3e;
  color: #fff;
  border-color: #262f3e;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
  display: inline-block;
  box-shadow: 0px 3px 10px #00000038;
}

.close-date a:hover,
.close-date a:focus {
  color: #fff;
  text-decoration: none;
  background: #262f3e;
}

.add-services input[type="checkbox"] {
  margin-top: -8px !important;
  margin-right: 10px;
}

.add-services .sel-1.form-label {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.add-services .form-label {
  font-weight: 500;
  color: #333;
}

/********* Add Commision ****************/

.add-commission .input-group-prepend {
  margin-right: -1px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  padding: 3px 15px;
  align-items: center;
  display: flex;
  border-radius: 8px 0 0 8px;
  /* padding-top: 10px; */
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.add-cms .input-group-prepend {
  margin-right: -1px;
  background-color: #ffffff;
  border: none;
  padding: 4px 15px;
  align-items: center;
  display: flex;
  padding-top: 10px;
}

/********* StaffWorking Hours ****************/

.schedule-filters .form-control {
  max-width: 135px;
}

.pull-right {
  float: right !important;
}

.schedule-date-toolbar
  .btn-group
  > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.schedule-wrapper {
  position: relative;
  display: flex;
  min-height: 0;
}

.schedule-scrollable {
  overflow-y: auto;
  width: 100%;
  margin-top: 25px;
}

.schedule-tables {
  overflow-y: auto;
}

.schedule-employees {
  float: left;
  width: 20%;
}

.employee-table {
  border-left: 1px solid #d9d9d9;
}

.schedule-table,
.employee-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.staff-working table {
  background-color: transparent;
}

.schedule-employee-hours {
  float: left;
  width: 80%;
}

.schedule-table,
.employee-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.schedule-table th,
.employee-table th {
  padding: 10px;
}

.schedule-table th,
.schedule-table td,
.employee-table th,
.employee-table td {
  text-align: center;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  cursor: default;
  min-width: 152px;
}

.employee-table td.employee-name {
  font-weight: bold;
  vertical-align: top;
  text-align: left;
  padding: 5px 10px 0;
}

.employee-table td {
  height: 51px;
}

.schedule-hours {
  border-radius: 2px;
  color: #24334a;
  padding: 3px;
  text-align: left;
  background: #d5edf5;
  font-size: 14px;
  cursor: pointer;
}

.schedule-date-toolbar .btn-default.active,
.schedule-date-toolbar .btn-default:active,
.schedule-date-toolbar .btn-default.active:focus,
.schedule-date-toolbar .btn-default:active:focus,
.schedule-date-toolbar .btn-default:active:hover {
  background-color: #f7f7f8;
  border-color: #c2c2c2;
  color: #2c2c2c;
}

.pull-right.schedule-date-toolbar {
  border: 1px solid #ced4da;
}

input#date_from {
  display: none;
}

.closedatemodal.edithour .modal-header {
  flex-wrap: wrap;
}

.closedatemodal.edithour .modal-header span {
  width: 100%;
}

.closedatemodal.edithour .modal-header .close {
  position: absolute;
  right: 10px;
  font-weight: 500;
  font-size: 30px;
}

.cross {
  position: absolute;
  right: 0;
  padding: 0px 20px;
  color: black;
}

/*********** Calendar **********/

.schedule-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 40px;
}

.schedule-date-toolbar {
  border: 1px solid #f1f1f1;
  padding: 1px;
  margin-right: 20px;
  border-radius: 8px;
}

.partner-table table.table-bordered thead th {
  border: 1px solid #f1f1f1;
  text-align: center;
}

.partner-table table.table-bordered tbody td {
  border: 1px solid #f1f1f1;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.partner-table table.table-bordered tbody tr td:first-child {
  text-align: left;
}

.schedule-filters .form-inline .form-control {
  min-width: 120px;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
}

.partner-table table.table-bordered thead th:first-child {
  width: 180px;
  text-align: left;
}

.partner-table table.table-bordered thead th:nth-child(2) {
  width: 113px;
}

.add-shift {
  background: #262f3e;
}

.add-shift i {
  color: #fff;
}

.user-permission .online-booking {
  padding: 0;
}

.add-staff-details .online-booking {
  display: flex;
}

.add-staff-details .online-booking .switch {
  margin-right: 5px;
}

.user-permission .partner-table table tbody td {
  vertical-align: middle;
}

.user-permission .btn-primary {
  background-color: #8147dd;
  color: #fff;
  border-color: #8147dd;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

.close-staff .partner-table table tbody td .btn-danger {
  margin-left: 5px;
}

.close-staff .partner-table table tbody td {
  cursor: pointer;
}

.closedatemodal .btn.btn-primary {
  border: 1px solid #8147dd;
}

.closedatemodal {
  /* font-family: "Nunito", sans-serif !important; */
  font-family: "AvenirBook" !important;
}

.closedatemodal .btn.btn-primary {
  background-color: #8147dd !important;
  color: #fff;
  border-color: #8147dd;
  font-family: "AvenirHeavy";
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
  border-radius: 8px;
}
.closedatemodal.edithour .btn.btn-primary {
  font-size: 22px;
  padding: 5px 10px;
}

.closedatemodal .btn.btn-secondary {
  background-color: #8147dd;
  color: #fff;
  border-color: #8147dd;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: 0px 3px 10px #00000038;
}

/* .form-group .number-fields .dropdown .btn {
  border: 1px solid #dadada !important;
} */
.add-staff .number-fields .dropdown .dropdown-menu.show {
  display: block;
  height: 130px;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}

.add-staff-content .tab-content {
  padding: 25px;
  border: 1px solid #ddd;
  margin-top: -1px;
}

.disabled-permmission {
  cursor: not-allowed;
  opacity: 0.3;
}

.disabled-permmission input[type="checkbox"] {
  pointer-events: none;
}

.disabled-permmission .slider {
  cursor: not-allowed;
}

.number-fields .show > .btn-primary.dropdown-toggle {
  color: #000 !important;
}

.select-date .form-control {
  position: absolute;
  top: 0;
  border: transparent;
  background: transparent;
  color: transparent;
  left: -25px;
  cursor: pointer;
}

.select-date {
  position: relative;
  cursor: pointer;
}

.select-date .form-control:focus {
  outline: none;
  box-shadow: none;
  color: transparent;
  background: transparent;
}

.select-date input[type="date"] {
  cursor: pointer;
}

.select-date input[type="date"]:after {
  content: "\25BC";
  color: transparent;
  padding: 0 5px;
  cursor: pointer;
}

.select-date input[type="date"]:hover:after {
  color: transparent;
}

.select-date input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  outline: none;
  height: auto;
  color: transparent;
  background: transparent;
}

.select-date input[type="date"]::-webkit-inner-spin-button {
  z-index: 1;
}

.select-date input[type="date"]::-webkit-clear-button {
  z-index: 1;
}

.discount-12 .service-header h2 {
  margin-top: 5px;
}

.discount-12 .servicebody {
  padding: 13px 0px !important;
}

.discount-12 .servicebody .online-booking {
  text-align: right;
  display: block !important;
}

.discount-12 .service-header {
  padding: 25px 0px;
}

label.sel-1.form-label {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

select#employee_id {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.partner-table .login-button.service-save {
  align-items: center;
  justify-content: center;
}

.close-staff .partner-table tbody td:last-child {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.noservice-found h3 {
  font-size: 18px;
}

.noservice-found {
  width: 100%;
  padding: 15px;
  text-align: center;
}

/* .close-staff .partner-table tbody td.placeholder-text {
  display: table-cell;
}
.user-permission .partner-table .table tr:first-child td {
  cursor: not-allowed;
  opacity: 0.3;
}
.user-permission .partner-table .table tr:first-child td span.slider.round {
  cursor: not-allowed;
}
.user-permission .partner-table .table tr:first-child td:first-child{
  color: #000;
  cursor: default !important;
  opacity: 1;
} */

.closedatemodal .modal-title.h4 {
  font-size: 18px;
  font-weight: 600;
}

.closedatemodal.edithour .modal-content p {
  text-align: center;
  font-size: 20px;
}

.close-staff .partner-table tbody td.placeholder-text {
  display: table-cell;
}

.close-date.user-12 .btn {
  background-color: #8147dd;
  border-color: #8147dd;
}
